import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import {
  getAllProfileReportsList,
  getFiatWallet,
  getUserCryptoWalletList,
  getZeroBalanceFiatWallet,
  updateCryptoWalletStatus,
  updateFiatWalletStatus,
} from "../../../../redux/slices/adminService";
import { debounce } from "lodash";
import numeral from "numeral";
import FileExcel from "../../../../component/common/Exportexcel";

const ZeroBalanceUserCryptoWalletList = (Curtype) => {
  const typeList = [
    { id: 1, value: "today", key: "Today" },
    { id: 2, value: "overview", key: "Overview" },
  ];
  let navigate = PageIndex.useNavigate();
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const params = PageIndex.useLocation();
  const [loader, setLoader] = useState(true);
  const [cryptoWalletList, setCryptoWalletList] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const abortController = new AbortController();
  const queryParams = new URLSearchParams(window.location.search);
  const stateParam = queryParams.get("state");
  const state = JSON.parse(decodeURIComponent(stateParam));
  const [excel, setExcel] = useState([]);
  const [type, setType] = useState(state?.today ? state?.today : "overview");

  const handleType = (e) => {
    setType(e.target.value);
    navigate({ state: "" });
    setCurrentPage(1);
  };

  //Fetch Post Report List

  const fetchCryptoWalletList = (page) => {
    setLoader(true);
    const Currencytype = Curtype?.Curtype;
    getZeroBalanceFiatWallet(
      { currentPage, search, rowsPerPage, Currencytype, type },
      { signal: abortController.signal }
    ).then((data) => {
      if (data?.status == 200) {
        setCryptoWalletList(data?.data);
        if (data?.currentPage) {
          setCurrentPage(data?.currentPage);
          setPageCount(data?.totalCount ? data?.totalCount : 0);
        }
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    setLoader(true);
    const debouncedFetch = debounce(fetchCryptoWalletList, 300);
    if (search || currentPage || rowsPerPage || type) {
      debouncedFetch();
    } else {
      fetchCryptoWalletList();
    }

    return () => {
      debouncedFetch.cancel();
      abortController.abort();
    };
  }, [search, currentPage, rowsPerPage, type]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const fileName = "List of Zero Balance Users Crypto Wallet";
  useEffect(() => {
    if (cryptoWalletList?.length > 0) {
      const customHeadings = cryptoWalletList?.map((item, index) => ({
        "S.No": index + 1,
        Username: item?.userId?.userName,
        Email: item?.userId?.email,
        Balance: item?.cryptoBalance,
        Status: item?.isActive ? "Active" : "Deactive",
      }));

      setExcel(customHeadings);
    }
  }, [cryptoWalletList]);

  return (
    <Index.Box className="barge-common-box">
      <Index.Box className="common-box">
        <Index.Box className="user-list-flex">
          <Index.Box className="admin-page-title-main">
            <Index.Typography
              className="admin-page-title tittle-like-costam"
              component="h2"
              variant="h2"
            >
              List of Zero Balance Users Crypto Wallet
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.FormControl className="request-dropdown">
              <Index.Select
                className="form-control select-drop-list request-drop-list"
                name="type"
                value={type}
                onChange={handleType}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
              >
                {typeList &&
                  typeList?.map((val) => (
                    <Index.MenuItem
                      value={val?.value}
                      key={val?.id}
                      className="menuitem"
                    >
                      {val?.key}
                    </Index.MenuItem>
                  ))}
              </Index.Select>
            </Index.FormControl>
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    name="search"
                    autoComplete="search"
                    value={search}
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => {
                      const newValue = e.target.value
                        .replace(/^\s+/, "")
                        .replace(/\s\s+/g, " ");
                      setCurrentPage(1);
                      setCryptoWalletList();
                      setSearch(newValue);
                      setLoader(true);
                    }}
                  />
                  <span className="search-icon-box">
                    <img src={Index.Svg.search} className="search-icon" />
                  </span>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box>
              <FileExcel apiData={excel} fileName={fileName} />
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dash-box">
          <Index.Box className="page-table-main action-column conversion-table-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="simple table"
                className="table"
              >
                <Index.TableHead className="table-head cus-table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      S.No.
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Username
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Email
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Balance
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Status
                    </Index.TableCell>
                    {(rolePermission &&
                      rolePermission?.roleType?.rolePermission?.includes(
                        "CryptoWalletManagement_view"
                      )) ||
                    rolePermission?.roleType?.rolePermission?.includes(
                      "CryptoWalletManagement_edit"
                    ) ||
                    (rolePermission && rolePermission?.isAdmin === true) ? (
                      <>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Action
                        </Index.TableCell>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.TableRow>
                </Index.TableHead>

                <Index.TableBody className="table-body">
                  {!loader ? (
                    cryptoWalletList?.length > 0 ? (
                      cryptoWalletList?.map((row, index) => (
                        <Index.TableRow key={row?._id}>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {(currentPage - 1) * 10 + index + 1}
                            {/* {(1 - 1) * 10 + index + 1} */}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td "
                          >
                            <span
                              className="cus-user-name-navigate"
                              onClick={() => {
                                navigate("/dashboard/user-view", {
                                  state: {
                                    data: row?.userId?._id,
                                    pathName: params.pathname,
                                  },
                                });
                              }}
                            >
                              {row?.userId?.userName
                                ? row?.userId?.userName
                                : "-"}
                            </span>
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {row?.userId?.email ? row?.userId?.email : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {row?.cryptoBalance
                              ? Number.isInteger(row.cryptoBalance)
                                ? numeral(row.cryptoBalance).format("0")
                                : numeral(row.cryptoBalance).format("0.000a")
                              : 0}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {row?.isActive ? "Active" : "Deactive"}
                          </Index.TableCell>
                          {(rolePermission &&
                            rolePermission?.roleType?.rolePermission?.includes(
                              "CryptoWalletManagement_view"
                            )) ||
                          rolePermission?.roleType?.rolePermission?.includes(
                            "CryptoWalletManagement_edit"
                          ) ||
                          (rolePermission &&
                            rolePermission?.isAdmin === true) ? (
                            <>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                align="center"
                              >
                                <Index.Box className="userdata-btn-flex">
                                  {(rolePermission &&
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "CryptoWalletManagement_view"
                                    )) ||
                                  (rolePermission &&
                                    rolePermission?.isAdmin === true) ? (
                                    <>
                                      <PageIndex.LightTooltip title="View">
                                        <Index.IconButton
                                          color="primary"
                                          aria-label="upload picture"
                                          component="label"
                                          onClick={() =>
                                            navigate(
                                              "/dashboard/view-zero-crypto-wallet",
                                              {
                                                state: {
                                                  data: row?.userId?._id,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          <Index.RemoveRedEyeIcon className="view-eye-Icon" />
                                        </Index.IconButton>
                                      </PageIndex.LightTooltip>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {/* {(rolePermission &&
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "CryptoWalletManagement_edit"
                                    )) ||
                                    (rolePermission &&
                                      rolePermission?.isAdmin === true) ? (
                                    <>
                                      <Index.Box className="table-view-toggle-btn">
                                        <Index.IconButton>
                                          <Index.FormControlLabel
                                            control={
                                              <Index.IOSSwitch
                                                checked={row?.isActive === true}
                                                sx={{ m: 1 }}
                                                className="MuiSwitch-track-active"
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  handleCryptoWalletStatus(
                                                    row?.userId?._id
                                                  );
                                                  //  0
                                                }}
                                              />
                                            }
                                            className="switch-lable"
                                          />
                                        </Index.IconButton>
                                      </Index.Box>
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                </Index.Box>
                              </Index.TableCell>
                            </>
                          ) : (
                            ""
                          )}
                        </Index.TableRow>
                      ))
                    ) : (
                      <PageIndex.RecordNotFound colSpan={6} />
                    )
                  ) : (
                    <PageIndex.TableLoader colSpan={6} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>

        {cryptoWalletList?.length ? (
          <Index.Box className="pagination-main">
            <Index.TablePagination
              rowsPerPageOptions={[
                { label: "All", value: -1 },
                10,
                25,
                50,
                75,
                100,
              ]}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={currentPage - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
              variant="outlined"
              shape="rounded"
              className="pagination"
            />
          </Index.Box>
        ) : (
          ""
        )}
      </Index.Box>
    </Index.Box>
  );
};
export default ZeroBalanceUserCryptoWalletList;
