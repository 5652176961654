import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {
  deleteNotificationByAdmin,
  getAllNotifications,
} from "../../../../redux/slices/adminService";
import moment from "moment";
import { debounce } from "lodash";

const NotificationsList = () => {
  const navigate = PageIndex.useNavigate();
  const [notificationList, setNotificationList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  //api of users feedback list
  const handleOpenDelete = (row) => {
    setOpenDelete(true);
    setDeleteId(row?._id);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const listOfUsersNotification = () => {
    setLoader(true);
    const page = currentPage;
    getAllNotifications(page, search, rowsPerPage).then((res) => {
      if (res?.status === 200) {
        setLoader(false);
        setNotificationList(res?.data);
        if (res?.currentPage) {
          setCurrentPage(res?.currentPage);
        }

        setPageCount(res?.totalCount ? res?.totalCount : 0);
      } else {
        setLoader(false);
        setNotificationList([]);
      }
    });
  };

  const deleteNotificationDataByAdmin = () => {
    deleteNotificationByAdmin(
      deleteId ? { notificationId: deleteId } : ""
    ).then((data) => {
      setOpenDelete(false);
      if (data == 200) {
        listOfUsersNotification();
      } else {
        setTimeout(() => {}, 1000);
      }
    });
  };

  useEffect(() => {
    const debouncedFetch = debounce(listOfUsersNotification, 300);
    if (search || currentPage || rowsPerPage) {
      debouncedFetch();
    } else {
      listOfUsersNotification();
    }
    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage, rowsPerPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Notification List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search"
                        // name="search"
                        value={search}
                        autoComplete="search"
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setCurrentPage(1);
                          setSearch(newValue);
                        }}
                      />
                      <span className="search-icon-box">
                        <img src={Index.Svg.search} className="search-icon" />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="add-role-permission">
                  <Index.Box className="adduser-btn-main btn-main-primary">
                    {(rolePermission &&
                      rolePermission?.roleType?.rolePermission?.includes(
                        "NotificationList_add"
                      )) ||
                    (rolePermission && rolePermission?.isAdmin === true) ? (
                      <>
                        <Index.Button
                          className="adduser-btn btn-primary"
                          onClick={() =>
                            navigate("/dashboard/add-notification")
                          }
                        >
                          <img
                            src={Index.Svg.plus}
                            className="plus-icon"
                            alt="plus icon"
                          />
                          Add Notification
                        </Index.Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="notifiation-table-main action-column page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head cus-table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  S.No
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Title
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Type
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Date
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Time
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Status
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Actions
                                  {(rolePermission &&
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "NotificationList_delete"
                                    )) ||
                                  (rolePermission &&
                                    rolePermission?.isAdmin === true)
                                  ? (
                                    <>
                                    { notificationList?.length > 0 && 
                                      <PageIndex.LightTooltip title="Delete All">
                                        <Index.IconButton
                                          color="secondary"
                                          aria-label="upload picture"
                                          className="delete-all-button"
                                          component="label"
                                          onClick={() => {
                                            handleOpenDelete();
                                          }}
                                        >
                                          <Index.DeleteIcon />
                                        </Index.IconButton>
                                      </PageIndex.LightTooltip>}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {!loader ? (
                                notificationList?.length > 0 ? (
                                  notificationList?.map((row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {(currentPage - 1) * 10 + index + 1}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.title ? row?.title : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                        sx={{ textTransform: "capitalize" }}
                                      >
                                        {row?.notificationType
                                          ? row?.notificationType
                                          : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.timeDate
                                          ? moment(row?.timeDate).format(
                                              "DD/MM/YYYY "
                                            )
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.timeDate
                                          ? moment(row?.timeDate).format(
                                              "hh:mm A"
                                            )
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.isSend ? "Sent" : "Draft"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        <Index.Box className="userdata-btn-flex">
                                          {(rolePermission &&
                                            rolePermission?.roleType?.rolePermission?.includes(
                                              "NotificationList_view"
                                            )) ||
                                          (rolePermission &&
                                            rolePermission?.isAdmin ===
                                              true) ? (
                                            <>
                                              <PageIndex.LightTooltip title="View">
                                                <Index.IconButton
                                                  color="primary"
                                                  // title="View"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() =>
                                                    navigate(
                                                      `/dashboard/view-notification`,
                                                      {
                                                        state: {
                                                          row,
                                                          type: "view",
                                                        },
                                                      }
                                                    )
                                                  }
                                                >
                                                  <Index.RemoveRedEyeIcon />
                                                </Index.IconButton>
                                              </PageIndex.LightTooltip>
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {(rolePermission &&
                                            rolePermission?.roleType?.rolePermission?.includes(
                                              "NotificationList_edit"
                                            )) ||
                                          (rolePermission &&
                                            rolePermission?.isAdmin ===
                                              true) ? (
                                            <>
                                              {row?.isSend === false && (
                                                <PageIndex.LightTooltip title="Edit">
                                                  <Index.IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    disabled={row?.isSend}
                                                    onClick={() => {
                                                      navigate(
                                                        `/dashboard/edit-notification`,
                                                        {
                                                          state: {
                                                            row,
                                                            type: "edit",
                                                          },
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <Index.EditIcon />
                                                  </Index.IconButton>
                                                </PageIndex.LightTooltip>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {(rolePermission &&
                                            rolePermission?.roleType?.rolePermission?.includes(
                                              "NotificationList_edit"
                                            )) ||
                                          (rolePermission &&
                                            rolePermission?.isAdmin ===
                                              true) ? (
                                            <>
                                              <PageIndex.LightTooltip title="Delete">
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() => {
                                                    handleOpenDelete(row);
                                                  }}
                                                >
                                                  <Index.DeleteIcon />
                                                </Index.IconButton>
                                              </PageIndex.LightTooltip>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Index.Box>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  ))
                                ) : (
                                  <PageIndex.RecordNotFound colSpan={7} />
                                )
                              ) : (
                                <PageIndex.TableLoader colSpan={7} />
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {notificationList?.length ? (
              <Index.Box className="pagination-main">
                <Index.TablePagination
                  rowsPerPageOptions={[
                    { label: "All", value: -1 },
                    10,
                    25,
                    50,
                    75,
                    100,
                  ]}
                  count={pageCount}
                  rowsPerPage={rowsPerPage}
                  page={currentPage - 1}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  variant="outlined"
                  shape="rounded"
                  className="pagination"
                />
              </Index.Box>
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.DeleteModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteRecord={deleteNotificationDataByAdmin}
      />
    </>
  );
};

export default NotificationsList;
