import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { getOverViewReportAction } from "../../../../redux/slices/adminService";
import dayjs from "dayjs";
import numeral from "numeral";

export const OverviewReport = () => {
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(true);

  const currentDate = dayjs().format("YYYY-MM-DD");
  const firstDayOfMonth = dayjs().startOf("month").format("YYYY-MM-DD");

  const getOverviewReport = () => {
    getOverViewReportAction().then((res) => {
      if (res?.status == 200) {
        setData(res?.data);
        setLoader(false);
      } else {
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      }
    });
  };
  useEffect(() => {
    getOverviewReport();
  }, []);
  return (
    <>
      {!loader ? (
        <Index.Box className="common-box">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main cus-dashboard-heading">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Overview Report Dashboard
              </Index.Typography>
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-dashboad-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-dashboard"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      // onClick={() => navigate("/dashboard/user-list")}
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.fiatMoney}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total fiat currency
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {`${
                            data?.totalFiatCount
                              ? numeral(data?.totalFiatCount)?.format("0.00a")
                              : 0
                          } (${data?.totalFiatCount})`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.crypto}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total crypto currency
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {`${
                            data?.totalCryptoCount
                              ? numeral(data?.totalCryptoCount)?.format("0.00a")
                              : 0
                          } (${data?.totalCryptoCount})`}
                          {/* {data?.totalCryptoCount
                            ? numeral(data?.totalCryptoCount)?.format('0.00a')
                            : 0} */}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() =>
                        window.open(`/dashboard/user-list`, "_blank")
                      }
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.totalUsers}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total users
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {/* {data?.userCount
                            ? data.userCount < 1000
                              ? numeral(data.userCount).format("0,0")
                              : numeral(data.userCount).format("0.00a")
                            : 0} */}

                          {`${
                            data?.userCount
                              ? data.userCount < 1000
                                ? numeral(data.userCount).format("0,0")
                                : numeral(data.userCount).format("0.00a")
                              : 0
                          } (${data?.userCount})`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() =>
                        window.open(`/dashboard/user-post`, "_blank")
                      }
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.userPost}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total no. of posts
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {/* {data?.postCount
                            ? data.postCount < 1000
                              ? numeral(data.postCount).format("0,0")
                              : numeral(data.postCount).format("0.00a")
                            : 0} */}
                          {`${
                            data?.postCount
                              ? data.postCount < 1000
                                ? numeral(data.postCount).format("0,0")
                                : numeral(data.postCount).format("0.00a")
                              : 0
                          } (${data?.postCount})`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() =>
                        window.open(`/dashboard/user-reels`, "_blank")
                      }
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.reels}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total no. of cryp
                          {/* Total no. of reels */}
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {/* {data?.reelCount
                            ? data.reelCount < 1000
                              ? numeral(data.reelCount).format("0,0")
                              : numeral(data.reelCount).format("0.00a")
                            : 0} */}

                          {`${
                            data?.reelCount
                              ? data.reelCount < 1000
                                ? numeral(data.reelCount).format("0,0")
                                : numeral(data.reelCount).format("0.00a")
                              : 0
                          } (${data?.reelCount})`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() =>
                        window.open(`/dashboard/user-report`, "_blank")
                      }
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.reportedUser}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total reports
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {/* {data?.reportedUserCount
                            ? data.reportedUserCount < 1000
                              ? numeral(data.reportedUserCount).format("0,0")
                              : numeral(data.reportedUserCount).format("0.00a")
                            : 0} */}
                          {`${
                            data?.reportedUserCount
                              ? data.reportedUserCount < 1000
                                ? numeral(data.reportedUserCount).format("0,0")
                                : numeral(data.reportedUserCount).format(
                                    "0.00a"
                                  )
                              : 0
                          } (${data?.reportedUserCount})`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() =>
                        window.open(`/dashboard/contest-list`, "_blank")
                      }
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.trophy}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total created contest
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {/* {data?.contestCount
                            ? data.contestCount < 1000
                              ? numeral(data.contestCount).format("0,0")
                              : numeral(data.contestCount).format("0.00a")
                            : 0} */}

                          {`${
                            data?.contestCount
                              ? data.contestCount < 1000
                                ? numeral(data.contestCount).format("0,0")
                                : numeral(data.contestCount).format("0.00a")
                              : 0
                          } (${data?.contestCount})`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.withdraw}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total withdrawal amount
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {/* {data?.totalWithdrawalAmount
                            ? numeral(data.totalWithdrawalAmount).format(
                                "0.00a"
                              )
                            : 0} */}

                          {`${
                            data?.totalWithdrawalAmount
                              ? data.totalWithdrawalAmount < 1000
                                ? numeral(data.totalWithdrawalAmount).format(
                                    "0,0"
                                  )
                                : numeral(data.totalWithdrawalAmount).format(
                                    "0.00a"
                                  )
                              : 0
                          } (${data?.totalWithdrawalAmount})`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() =>
                        window.open(
                          `/dashboard/diamondtick-user-list`,
                          "_blank"
                        )
                      }
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.diamond}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total diamond tick users
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {/* {data?.diamondTickUserCount
                            ? data.diamondTickUserCount < 1000
                              ? numeral(data.diamondTickUserCount).format("0,0")
                              : numeral(data.diamondTickUserCount).format(
                                  "0.00a"
                                )
                            : 0} */}

                          {`${
                            data?.diamondTickUserCount
                              ? data.diamondTickUserCount < 1000
                                ? numeral(data.diamondTickUserCount).format(
                                    "0,0"
                                  )
                                : numeral(data.diamondTickUserCount).format(
                                    "0.00a"
                                  )
                              : 0
                          } (${data?.diamondTickUserCount})`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() =>
                        window.open(
                          `/dashboard/user-list?state=${encodeURIComponent(
                            JSON.stringify({ status: "suspended" })
                          )}`,
                          "_blank"
                        )
                      }
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.blockUser}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total suspended users
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {/* {data?.suspendedUsers
                            ? data.suspendedUsers < 1000
                              ? numeral(data.suspendedUsers).format("0,0")
                              : numeral(data.suspendedUsers).format("0.00a")
                            : 0} */}

                          {`${
                            data?.suspendedUsers
                              ? data.suspendedUsers < 1000
                                ? numeral(data.suspendedUsers).format("0,0")
                                : numeral(data.suspendedUsers).format("0.00a")
                              : 0
                          } (${data?.suspendedUsers})`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() =>
                        window.open(
                          `/dashboard/user-post?state=${encodeURIComponent(
                            JSON.stringify({
                              currentDate: currentDate,
                              firstDayOfMonth: firstDayOfMonth,
                            })
                          )}`,
                          "_blank"
                        )
                      }
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.userPost}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Post of the month
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {/* {data?.postOfMonthCount
                            ? data.postOfMonthCount < 1000
                              ? numeral(data.postOfMonthCount).format("0,0")
                              : numeral(data.postOfMonthCount).format("0.00a")
                            : 0} */}
                          {`${
                            data?.postOfMonthCount
                              ? data.postOfMonthCount < 1000
                                ? numeral(data.postOfMonthCount).format("0,0")
                                : numeral(data.postOfMonthCount).format("0.00a")
                              : 0
                          } (${data?.postOfMonthCount})`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      // onClick={() => navigate("/dashboard/user-reels",{ state: {
                      //   currentDate: currentDate,
                      //   firstDayOfMonth: firstDayOfMonth
                      // }})}
                      onClick={() =>
                        window.open(
                          `/dashboard/user-reels?state=${encodeURIComponent(
                            JSON.stringify({
                              currentDate: currentDate,
                              firstDayOfMonth: firstDayOfMonth,
                            })
                          )}`,
                          "_blank"
                        )
                      }
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.reels}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Cryp of the month
                          {/* Reel of the month */}
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {/* {data?.reelOfMonthCount
                            ? data.reelOfMonthCount < 1000
                              ? numeral(data.reelOfMonthCount).format("0,0")
                              : numeral(data.reelOfMonthCount).format("0.00a")
                            : 0} */}

                          {`${
                            data?.reelOfMonthCount
                              ? data.reelOfMonthCount < 1000
                                ? numeral(data.reelOfMonthCount).format("0,0")
                                : numeral(data.reelOfMonthCount).format("0.00a")
                              : 0
                          } (${data?.reelOfMonthCount})`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() =>
                        window.open(
                          `/dashboard/zero-wallet-management`,
                          "_blank"
                        )
                      }
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.accountBalance}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Zero balance accounts
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {/* {data?.totalZeroBalanceAccount
                            ? data.totalZeroBalanceAccount < 1000
                              ? numeral(data.totalZeroBalanceAccount).format(
                                  "0,0"
                                )
                              : numeral(data.totalZeroBalanceAccount).format(
                                  "0.00a"
                                )
                            : 0} */}

                          {`${
                            data?.totalZeroBalanceAccount
                              ? data.totalZeroBalanceAccount < 1000
                                ? numeral(data.totalZeroBalanceAccount).format(
                                    "0,0"
                                  )
                                : numeral(data.totalZeroBalanceAccount).format(
                                    "0.00a"
                                  )
                              : 0
                          } (${data?.totalZeroBalanceAccount})`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box
                      className="admin-dashboard-inner-box"
                      onClick={() =>
                        window.open(`/dashboard/transactions-list`, "_blank")
                      }
                    >
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Png.transactionHistory}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>

                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total no. of transactions
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {/* {data?.transactionCount
                            ? data.transactionCount < 1000
                              ? numeral(data.transactionCount).format("0,0")
                              : numeral(data.transactionCount).format("0.00a")
                            : 0} */}

                          {`${
                            data?.transactionCount
                              ? data.transactionCount < 1000
                                ? numeral(data.transactionCount).format("0,0")
                                : numeral(data.transactionCount).format("0.00a")
                              : 0
                          } (${data?.transactionCount})`}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        <PageIndex.Loading />
      )}
    </>
  );
};
