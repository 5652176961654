import { useEffect, useState } from "react";
import { loginAdmin } from "../../../redux/slices/adminService";
import Index from "../../../component/Index";
import PageIndex from "../../PageIndex";
import { isResetAll } from "../../../redux/slices/AdminSlice";

export default function Login() {
    const dispatch = PageIndex.useDispatch();
    const navigate = PageIndex.useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false)
    const handleClick = () => {
      dispatch(isResetAll(false))
      navigate("/forgot-password")
    }
    const handleClickShowPassword = (e) => {
      e.preventDefault();
      setShowPassword((show) => !show);
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    let initialValues = {
      email: "",
      password: "",
    }

    const handleSubmit = (values) => {

      setLoading(true);
      dispatch(loginAdmin(values)).then((data) => {
        if (data?.payload?.status == 200) {
          navigate("/dashboard");
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        }
      });
    };
    useEffect(() => {
      dispatch(isResetAll(true))
    }, [])
  return (

    <div>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <Index.Box className="login-left-bg">
            <Index.Box className="auth-logo-box">
              <img
                alt="logo_img"
                src={Index.Jpg.authlogo}
                className="auth-logo"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <PageIndex.Formik
                validationSchema={PageIndex.adminLoginSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  touched,
                  setFieldValue
                }) => (
                  <PageIndex.Form >
                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Login!
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        Please enter your credentials to Login!
                      </Index.Typography>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Email
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            onBlur={handleBlur}
                            value={values.email}
                            onChange={(e) => {
                              const newValue = e.target.value.replace(
                                /\s/g,
                                ""
                              );
                              setFieldValue("email", newValue)
                            }}
                            fullWidth
                            placeholder="Email"
                            name="email"
                            id="fullWidth"
                            className="form-control email-input"
                            type="text"
                            autocomplete="off"
                            error={Boolean(
                              errors?.email ? touched?.email : undefined
                            )}
                            helperText={
                              touched?.email ? errors?.email : undefined
                            }
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            size="small"
                            name="password"
                            placeholder="Password"
                            className="form-control input-with-radius password-form-control"
                            type={showPassword ? "text" : "password"}
                            // label="Password"
                            variant="outlined"
                            autoComplete="off"
                            inputProps={{
                              className: "input_props",
                            }}
                            InputLabelProps={{ className: "add-formlabel" }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                            onBlur={handleBlur}
                            value={values.password}
                            onChange={(e) => {
                              const newValue = e.target.value.replace(
                                /\s/g,
                                ""
                              );
                              setFieldValue("password", newValue)
                            }}

                            InputProps={{
                              endAdornment: (
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    className="passwrd-eye"
                                    aria-label="toggle password visibility"
                                    onClick={(e) =>
                                      handleClickShowPassword(e)
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Index.Visibility />
                                    ) : (
                                      <Index.VisibilityOff />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              ),
                            }}
                            error={Boolean(
                              errors?.password ? touched?.password : undefined
                            )}
                            helperText={
                              touched?.password ? errors?.password : undefined
                            }
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="flex-all forgot-row">
                        <Index.Box className="checkbox-main">
                          <Index.Typography className="checkbox-lable">
                          </Index.Typography>
                        </Index.Box>
                        <Index.Link
                          to="/forgot-password"
                          onClick={handleClick}
                          className="text-decoration-none"
                        >
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="admin-forgot-para common-para"
                          >
                            Forgot Password?
                          </Index.Typography>
                        </Index.Link>
                      </Index.Box>
                      <Index.Box className="btn-main-primary login-btn-main">

                        <Index.Button
                          className="btn-primary login-btn forgot-button"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <PageIndex.ButtonLoader color="white" loading={loading} />
                          ) : (
                            "Login"
                          )}
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </PageIndex.Form>
                )}
              </PageIndex.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>

  );
}
