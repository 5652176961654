import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import dayjs from "dayjs";
import {
  cancelContestAction,
  contestDisable,
  deleteContestAction,
  getAllContest,
  searchContenstparticipant,
} from "../../../../redux/slices/adminService";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { debounce } from "lodash";
const ContestList = () => {
  const typeList = [
    { id: 1, value: "today", key: "Today" },
    { id: 2, value: "overview", key: "Overview" },
  ];

  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const stateParam = queryParams.get("state");
  const state = JSON.parse(decodeURIComponent(stateParam));

  const [type, setType] = useState(state?.today ? state?.today : "overview");
  const [contestList, setContestList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [editData, setEditData] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [openWarningDelete, setOpenWarningDelete] = useState(false);
  const [openCancelModel, setOpenCancelModel] = useState(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const ITEM_HEIGHT = 48;

  const open = Boolean(anchorEl);
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setEditData(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleType = (e) => {
    setType(e.target.value);
    navigate({ state: "" });
    setCurrentPage(1);
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  // To convert Time from "17:00:00" To "Sat Feb 24 2024 17:00:00 GMT+0530 (India Standard Time)" t
  const timeFormate = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":");
    return { hours, minutes, seconds };
  };
  function convertTo12HourFormat(timeString) {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(":");

    // Create a new Date object with the current date
    const dateObject = new Date();
    dateObject.setHours(hours);
    dateObject.setMinutes(minutes);
    dateObject.setSeconds(seconds);

    // Format the time in 12-hour format with AM/PM
    const formattedTime = dateObject.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      // second: 'numeric',
      hour12: true,
    });

    return formattedTime;
  }

  // Sorting functions for different columns
  const sortFunctions = {
    contestName: (a, b) => {
      return sortOrder === "asc"
        ? a.contestName.localeCompare(b.contestName)
        : b.contestName.localeCompare(a.contestName);
    },
    category: (a, b) => {
      return sortOrder === "asc"
        ? a.category.localeCompare(b.category)
        : b.category.localeCompare(a.category);
    },
    startDate: (a, b) => {
      return sortOrder === "asc"
        ? moment(a.startDate).diff(moment(b.startDate))
        : moment(b.startDate).diff(moment(a.startDate));
    },
    endDate: (a, b) => {
      return sortOrder === "asc"
        ? moment(a.endDate).diff(moment(b.endDate))
        : moment(b.endDate).diff(moment(a.endDate));
    },
    startTime: (a, b) => {
      const timeA = moment(a.startTime, "hh:mm A");
      const timeB = moment(b.startTime, "hh:mm A");
      return sortOrder === "asc" ? timeA.diff(timeB) : timeB.diff(timeA);
    },
    endTime: (a, b) => {
      const timeA = moment(a.endTime, "hh:mm A");
      const timeB = moment(b.endTime, "hh:mm A");
      return sortOrder === "asc" ? timeA.diff(timeB) : timeB.diff(timeA);
    },
  };

  //api of users contest list
  const listOfContest = () => {
    setLoader(true);
    getAllContest(currentPage, type, rowsPerPage).then((res) => {
      if (res?.status === 200) {
        let sortedContestList = res?.data;
        if (sortBy && sortFunctions[sortBy]) {
          sortedContestList = sortedContestList.sort(sortFunctions[sortBy]);
        }
        setContestList(sortedContestList);
        setLoader(false);
        if (res?.currentPage) {
          setCurrentPage(res?.currentPage);
        }

        setPageCount(res?.totalCount ? res?.totalCount : 0);
      } else {
        setContestList([]);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    });
  };

  const contestSearch = () => {
    const obj = {
      startDate: selectedFromDate
        ? dayjs(selectedFromDate).format("DD/MM/YYYY")
        : "",
      endDate: selectedToDate ? dayjs(selectedToDate).format("DD/MM/YYYY") : "",
      search: search,
      page: currentPage,
      rowsPerPage: rowsPerPage,
      today: type,
    };
    searchContenstparticipant(obj).then((res) => {
      if (res?.status == 200) {
        setLoader(false);
        if (res?.currentPage) {
          setCurrentPage(res?.currentPage);
        }
        setPageCount(res?.totalCount ? res?.totalCount : 0);
        setContestList(res?.data);
      } else {
        setContestList([]);
      }
    });
  };
  useEffect(() => {
    const debouncedFetch = debounce(() => {
      if (search || selectedFromDate || selectedToDate || rowsPerPage) {
        contestSearch();
      } else {
        listOfContest();
      }
    }, 300);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [
    search,
    selectedFromDate,
    selectedToDate,
    currentPage,
    sortBy,
    sortOrder,
    type,
    rowsPerPage,
  ]);

  const handleOpenDelete = (row) => {
    row?.isClosed === true ? setOpenDelete(true) : setOpenWarningDelete(true);
    // setDeleteId(id);
  };

  const handleCloseDelete = () => setOpenDelete(false);
  const handleCloseWarningDelete = () => setOpenWarningDelete(false);

  //delete contest
  const deleteContest = () => {
    setIsDeleteButtonDisabled(true);
    deleteContestAction({ id: editData._id }).then((data) => {
      setOpenDelete(false);
      if (data?.status == 200) {
        setLoader(true);
        setIsDeleteButtonDisabled(false);
        listOfContest();
      }
    });
  };

  // disable contest
  const disableContest = (contestId) => {
    contestDisable(contestId).then((data) => {
      if (data?.status == 200) {
        listOfContest();
      }
    });
  };

  // cancel model
  const handleOpenCancelModel = (id) => {
    setOpenCancelModel(true);
    // setDeleteId(id);
  };
  const handleCloseCancelModel = () => setOpenCancelModel(false);

  //cancel contest
  const handleCancel = () => {
    setIsDeleteButtonDisabled(true);
    cancelContestAction({ id: editData._id }).then((data) => {
      setOpenCancelModel(false);
      if (data?.status == 200) {
        setLoader(true);
        setIsDeleteButtonDisabled(false);
        listOfContest();
      }
    });
  };

  const handleClearFromDate = () => {
    setSelectedFromDate("");
  };
  const handleClearToDate = () => {
    setSelectedToDate("");
  };

  const handleRemoveToday = () => {
    navigate({ state: null });
    listOfContest();
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Contest List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="input-box add-user-input request-input-box ">
                  <Index.Box className="form-group">
                    <Index.FormControl className="request-dropdown">
                      <Index.Select
                        className="form-control select-drop-list request-drop-list cus-request-drop-list"
                        name="type"
                        value={type}
                        onChange={handleType}
                        displayEmpty
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                      >
                        {typeList &&
                          typeList?.map((val) => (
                            <Index.MenuItem
                              value={val?.value}
                              key={val?.id}
                              className="menuitem"
                            >
                              {val?.key}
                            </Index.MenuItem>
                          ))}
                      </Index.Select>
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search"
                        name="search"
                        value={search}
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setCurrentPage(1);
                          setSearch(newValue);
                        }}
                      />
                      <span className="search-icon-box">
                        <img src={Index.Svg.search} className="search-icon" />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="add-role-permission">
                  <Index.Box className="adduser-btn-main btn-main-primary">
                    {(rolePermission &&
                      rolePermission?.roleType?.rolePermission?.includes(
                        "ContestList_add"
                      )) ||
                    (rolePermission && rolePermission?.isAdmin === true) ? (
                      <>
                        <Index.Button
                          className="adduser-btn btn-primary"
                          onClick={() => navigate("/dashboard/add-contest")}
                        >
                          <img
                            src={Index.Svg.plus}
                            className="plus-icon"
                            alt="plus icon"
                          />
                          Add
                        </Index.Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {/* <Index.Box className="contest-search-list user-list-flex">

              <Index.Box className="contest-search-list-1">
                {type != "today" && (
                  <>
                    <Index.Box className="admin-text-field-main from-input mb-0">
                      <Index.Box className="input-box input-box-admin input-box-admin2">
                        <Index.Box className="form-group transaction-fieldset">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.InputLabel
                              className="form-lable"
                              id="demo-simple-label"
                            >
                              From
                            </Index.InputLabel>
                            <Index.Stack className="date-picker-mui cus-datepicker">
                            
                              <Index.MobileDatePicker
                                className="form-control datepicker"
                                format="DD-MM-YYYY"
                                value={
                                  selectedFromDate
                                  // ? selectedFromDate
                                  // : dayjs(new Date())
                                }
                                // disableFuture
                                maxDate={selectedToDate}
                                onChange={(newValue) => {
                                  setSelectedFromDate(newValue);
                                }}
                              />
                              {selectedFromDate ? (
                                <Index.IconButton
                                  className="clear-icon"
                                  onClick={handleClearFromDate}
                                >
                                  <Index.ClearIcon className="icon-clear" />
                                </Index.IconButton>
                              ) : (
                                ""
                              )}
                            </Index.Stack>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="admin-text-field-main from-input mb-0">
                      <Index.Box className="input-box input-box-admin input-box-admin2">
                        <Index.Box className="form-group transaction-fieldset">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.InputLabel
                              className="form-lable"
                              id="demo-simple-label"
                            >
                              To
                            </Index.InputLabel>
                            <Index.Stack className="sale-field date-picker-mui cus-datepicker">
              
                              <Index.MobileDatePicker
                                className=" form-control datepicker"
                                format="DD-MM-YYYY"
                                value={
                                  selectedToDate
                                  // ? selectedToDate
                                  // : dayjs(new Date())
                                }
                                // disableFuture
                                minDate={selectedFromDate}
                                onChange={(newValue) =>
                                  setSelectedToDate(newValue)
                                }
                              />
                              {selectedToDate ? (
                                <Index.IconButton
                                  className="clear-icon"
                                  onClick={handleClearToDate}
                                >
                                  <Index.ClearIcon className="icon-clear" />
                                </Index.IconButton>
                              ) : (
                                ""
                              )}
                            </Index.Stack>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </>
                )}
              </Index.Box>
              <Index.Box className="contest-search-list-2">
                <Index.Box className="input-box add-user-input request-input-box reel-type-dropdown">
                  <Index.Box className="form-group">
                    <Index.FormControl className="request-dropdown">
                      <Index.Select
                        className="form-control select-drop-list request-drop-list cus-request-drop-list"
                        name="type"
                        value={type}
                        onChange={handleType}
                        displayEmpty
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                      >
                        {typeList &&
                          typeList?.map((val) => (
                            <Index.MenuItem
                              value={val?.value}
                              key={val?.id}
                              className="menuitem"
                            >
                              {val?.key}
                            </Index.MenuItem>
                          ))}
                      </Index.Select>
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search"
                        name="search"
                        value={search}
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setCurrentPage(1);
                          setSearch(newValue);
                        }}
                      />
                      <span className="search-icon-box">
                        <img src={Index.Svg.search} className="search-icon" />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="add-role-permission">
                  <Index.Box className="adduser-btn-main btn-main-primary">
                    {(rolePermission &&
                      rolePermission?.roleType?.rolePermission?.includes(
                        "ContestList_add"
                      )) ||
                      (rolePermission && rolePermission?.isAdmin === true) ? (
                      <>
                        <Index.Button
                          className="adduser-btn btn-primary"
                          onClick={() => navigate("/dashboard/add-contest")}
                        >
                          <img
                            src={Index.Svg.plus}
                            className="plus-icon"
                            alt="plus icon"
                          />
                          Add
                        </Index.Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>


            </Index.Box> */}

            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="contest-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head cus-table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  S.No
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                  // onClick={() => handleSort("contestName")}
                                >
                                  <Index.Box className="drop-downup-arrow-main">
                                    Contest Name{" "}
                                    {/* {sortBy === "contestName" &&
                                      (sortOrder === "asc" ? (
                                        <img
                                          className="drop-down-up-arrow"
                                          src={PageIndex?.Png?.upArrow}
                                        />
                                      ) : (
                                        <img
                                          className="drop-down-up-arrow"
                                          src={PageIndex?.Png.downArrow}
                                        />
                                      ))} */}
                                  </Index.Box>
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                  // onClick={() => handleSort("category")}
                                >
                                  <Index.Box className="drop-downup-arrow-main">
                                    Category{" "}
                                    {/* {sortBy === "category" &&
                                      (sortOrder === "asc" ? (
                                        <img
                                          className="drop-down-up-arrow"
                                          src={PageIndex?.Png?.upArrow}
                                        />
                                      ) : (
                                        <img
                                          className="drop-down-up-arrow"
                                          src={PageIndex?.Png.downArrow}
                                        />
                                      ))} */}
                                  </Index.Box>
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                  // onClick={() => handleSort("category")}
                                >
                                  <Index.Box className="drop-downup-arrow-main">
                                    Contest Type{" "}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                  // onClick={() => handleSort("startDate")}
                                >
                                  <Index.Box className="drop-downup-arrow-main">
                                    Start Date{" "}
                                    {/* {sortBy === "startDate" &&
                                      (sortOrder === "asc" ? (
                                        <img
                                          className="drop-down-up-arrow"
                                          src={PageIndex?.Png?.upArrow}
                                        />
                                      ) : (
                                        <img
                                          className="drop-down-up-arrow"
                                          src={PageIndex?.Png.downArrow}
                                        />
                                      ))} */}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                  // onClick={() => handleSort("startDate")}
                                >
                                  <Index.Box className="drop-downup-arrow-main">
                                    Start Time{" "}
                                    {/* {sortBy === "startDate" &&
                                      (sortOrder === "asc" ? (
                                        <img
                                          className="drop-down-up-arrow"
                                          src={PageIndex?.Png?.upArrow}
                                        />
                                      ) : (
                                        <img
                                          className="drop-down-up-arrow"
                                          src={PageIndex?.Png.downArrow}
                                        />
                                      ))} */}
                                  </Index.Box>
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                  // onClick={() => handleSort("startTime")}
                                >
                                  Entry Time{" "}
                                  {/* {sortBy === "startTime" &&
                                    (sortOrder === "asc" ? "▲" : "▼")} */}
                                </Index.TableCell>
                                {/* <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                  onClick={() => handleSort("endDate")}
                                >
                                  <Index.Box className="drop-downup-arrow-main">
                                    End Date{" "}
                                    {sortBy === "endDate" &&
                                      (sortOrder === "asc" ? (
                                        <img
                                          className="drop-down-up-arrow"
                                          src={PageIndex?.Png?.upArrow}
                                        />
                                      ) : (
                                        <img
                                          className="drop-down-up-arrow"
                                          src={PageIndex?.Png.downArrow}
                                        />
                                      ))}
                                  </Index.Box>
                                </Index.TableCell> */}
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                  // onClick={() => handleSort("endTime")}
                                >
                                  Result Time{" "}
                                  {/* {sortBy === "endTime" &&
                                    (sortOrder === "asc" ? "▲" : "▼")} */}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Status
                                </Index.TableCell>
                                {(rolePermission &&
                                  rolePermission?.roleType?.rolePermission?.includes(
                                    "ContestList_view"
                                  )) ||
                                rolePermission?.roleType?.rolePermission?.includes(
                                  "ContestList_edit"
                                ) ||
                                rolePermission?.roleType?.rolePermission?.includes(
                                  "ContestList_delete"
                                ) ||
                                (rolePermission &&
                                  rolePermission?.isAdmin === true) ? (
                                  <>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Actions
                                    </Index.TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {!loader ? (
                                contestList?.length > 0 ? (
                                  contestList?.map((row, index) => (
                                    <>
                                      <Index.TableRow
                                        key={row._id}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {(currentPage - 1) * 10 + index + 1}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {row?.contestName
                                            ? row?.contestName
                                            : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                          // title= {row?.feedBackData && row?.feedBackData?.length>0 ? row?.feedBackData[row?.feedBackData.length-1].message:"-"}
                                        >
                                          {row?.category ? row?.category : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                          // title= {row?.feedBackData && row?.feedBackData?.length>0 ? row?.feedBackData[row?.feedBackData.length-1].message:"-"}
                                        >
                                          {row?.contestType
                                            ? row?.contestType == "quickPlay"
                                              ? "Quick play"
                                              : "Mega play"
                                            : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {/* {row?.createdAt
                                            ? moment(row?.createdAt).format(
                                              "DD/MM/YYYY"
                                            ) + ' ' + moment(row?.createdAt).format(
                                              "HH:mm:ss "
                                            )
                                            : "-"} */}
                                          {row?.startDate
                                            ? moment(row?.startDate).format(
                                                "DD/MM/YYYY"
                                              )
                                            : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {row?.startTime
                                            ? row?.startTime
                                            : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {row?.entryTime
                                            ? timeFormate(row?.entryTime)
                                                ?.hours +
                                              ":" +
                                              timeFormate(row?.entryTime)
                                                ?.minutes +
                                              ":" +
                                              timeFormate(row?.entryTime)
                                                ?.seconds
                                            : "-"}
                                          {/* {row?.startTime ? convertTo12HourFormat(row?.startTime) : "-"} */}
                                          {/* {row?.startTime
                                            ? moment(row?.startTime).format(
                                                "hh:mm A"
                                              )
                                            : "-"} */}
                                        </Index.TableCell>
                                        {/* <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {row?.endDate
                                            ? moment(row?.endDate).format(
                                                "DD/MM/YYYY"
                                              )
                                            : "-"}
                                        </Index.TableCell> */}
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {row?.resultTime
                                            ? timeFormate(row?.resultTime)
                                                ?.hours +
                                              ":" +
                                              timeFormate(row?.resultTime)
                                                ?.minutes +
                                              ":" +
                                              timeFormate(row?.resultTime)
                                                ?.seconds
                                            : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          {!row?.isClosed
                                            ? "Started"
                                            : row?.isClosed
                                            ? "Stopped"
                                            : "Not Started"}
                                        </Index.TableCell>

                                        {(rolePermission &&
                                          rolePermission?.roleType?.rolePermission?.includes(
                                            "ContestList_view"
                                          )) ||
                                        (rolePermission &&
                                          rolePermission?.roleType?.rolePermission?.includes(
                                            "ContestList_edit"
                                          )) ||
                                        rolePermission?.roleType?.rolePermission?.includes(
                                          "ContestList_delete"
                                        ) ||
                                        (rolePermission &&
                                          rolePermission?.isAdmin === true) ? (
                                          <>
                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              className="table-td"
                                              align="center"
                                            >
                                              <Index.Box className="userdata-btn-flex user-icon-btn">
                                                <Index.IconButton
                                                  aria-label="more"
                                                  id="long-button"
                                                  aria-controls={
                                                    open
                                                      ? "long-menu"
                                                      : undefined
                                                  }
                                                  aria-expanded={
                                                    open ? "true" : undefined
                                                  }
                                                  aria-haspopup="true"
                                                  onClick={(e) =>
                                                    handleClick(e, row)
                                                  }
                                                >
                                                  <MoreVertIcon />
                                                </Index.IconButton>
                                                <Index.Menu
                                                  id="long-menu"
                                                  MenuListProps={{
                                                    "aria-labelledby":
                                                      "long-button",
                                                  }}
                                                  anchorEl={anchorEl}
                                                  open={open}
                                                  onClose={handleClose}
                                                  PaperProps={{
                                                    style: {
                                                      maxHeight:
                                                        ITEM_HEIGHT * 3,
                                                      width: "11ch",
                                                    },
                                                  }}
                                                  className="contest-menu"
                                                >
                                                  {(rolePermission &&
                                                    rolePermission?.roleType?.rolePermission?.includes(
                                                      "ContestList_view"
                                                    )) ||
                                                  (rolePermission &&
                                                    rolePermission?.isAdmin ===
                                                      true) ? (
                                                    <>
                                                      <Index.MenuItem
                                                        key={row?._id}
                                                        onClick={() =>
                                                          navigate(
                                                            `/dashboard/view-contest-details`,
                                                            {
                                                              state: {
                                                                data: editData,
                                                                pathName:
                                                                  location.pathname,
                                                              },
                                                            }
                                                          )
                                                        }
                                                        className="contest-menuitem"
                                                      >
                                                        View
                                                      </Index.MenuItem>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}

                                                  {(rolePermission &&
                                                    rolePermission?.roleType?.rolePermission?.includes(
                                                      "ContestList_delete"
                                                    )) ||
                                                  (rolePermission &&
                                                    rolePermission?.isAdmin ===
                                                      true) ? (
                                                    <>
                                                      <Index.MenuItem
                                                        key={row?._id}
                                                        className="contest-menuitem"
                                                        onClick={() => {
                                                          handleOpenDelete(
                                                            editData
                                                          ); // Pass the selected row data to handleOpenDelete
                                                        }}
                                                      >
                                                        Delete
                                                      </Index.MenuItem>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {(rolePermission &&
                                                    rolePermission?.roleType?.rolePermission?.includes(
                                                      "ContestList_view"
                                                    )) ||
                                                  (rolePermission &&
                                                    rolePermission?.isAdmin ===
                                                      true) ? (
                                                    <>
                                                      <Index.MenuItem
                                                        key={row?._id}
                                                        className="contest-menuitem"
                                                        // disabled={
                                                        //   editData?.isClosed ||
                                                        //   editData?.isOpen
                                                        // }
                                                        onClick={() =>
                                                          navigate(
                                                            "/dashboard/sub-contest-list",
                                                            {
                                                              state: editData,
                                                            }
                                                          )
                                                        }
                                                      >
                                                        Sub-contest
                                                      </Index.MenuItem>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Index.Menu>
                                                {(rolePermission &&
                                                  rolePermission?.roleType?.rolePermission?.includes(
                                                    "ContestList_delete"
                                                  )) ||
                                                (rolePermission &&
                                                  rolePermission?.isAdmin ===
                                                    true) ? (
                                                  <Index.Box
                                                    sx={{ marginLeft: "5px" }}
                                                    className="table-view-toggle-btn"
                                                  >
                                                    {/* <Index.IconButton> */}
                                                    <Index.FormControlLabel
                                                      control={
                                                        <Index.IOSSwitch
                                                          checked={
                                                            !row?.isClosed
                                                          }
                                                          sx={{ m: 1 }}
                                                          className="MuiSwitch-track-active"
                                                          onClick={(event) => {
                                                            event.stopPropagation();
                                                            disableContest({
                                                              id: row?._id,
                                                              stop: event.target
                                                                .checked,
                                                            });
                                                          }}
                                                        />
                                                      }
                                                      className="switch-lable"
                                                    />
                                                    {/* </Index.IconButton> */}
                                                  </Index.Box>
                                                ) : (
                                                  ""
                                                )}
                                              </Index.Box>
                                            </Index.TableCell>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Index.TableRow>
                                    </>
                                  ))
                                ) : (
                                  <PageIndex.RecordNotFound colSpan={9} />
                                )
                              ) : (
                                <PageIndex.TableLoader colSpan={9} />
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {/* <PageIndex.Pagination
              fetchData={filterData}
              setPageData={setPageData}
              pageData={pageData}
              search={search}
            /> */}
            <Index.Box className="pagination-main">
              <Index.TablePagination
                rowsPerPageOptions={[
                  { label: "All", value: -1 },
                  10,
                  25,
                  50,
                  75,
                  100,
                ]}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={currentPage - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.CancelModel
        openDelete={openCancelModel}
        handleCloseDelete={handleCloseCancelModel}
        handleDeleteRecord={handleCancel}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
        //  text={}
      />
      <PageIndex.DeleteModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteRecord={deleteContest}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
      <PageIndex.WarningModel
        openDelete={openWarningDelete}
        handleCloseDelete={handleCloseWarningDelete}
      />
    </>
  );
};

export default ContestList;
