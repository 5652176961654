import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {
  deleteContactUsAdmin,
  deleteSubAdmin,
  getAllContactUsList,
} from "../../../../redux/slices/adminService";
import moment from "moment";
import { debounce } from "lodash";

const ContactUsList = () => {
  const [contactUsList, setContactList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [isDeleteButton, setIsDeleteButton] = useState(false);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);

  //Delete Modal
  const handleOpenDelete = (row) => {
    setOpenDelete(true);
    setDeleteId(row?._id);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const fetchContactUsList = () => {
    const page = currentPage;
    setLoader(true);
    getAllContactUsList(page, search, rowsPerPage).then((data) => {
      if (data?.status == 200) {
        setLoader(false);
        setContactList(data?.data);
        setCurrentPage(data?.currentPage);
        setPageCount(data?.totalCount ? data?.totalCount : 0);
      } else {
        setContactList([]);
      }
    });
  };

  const deleteContactUsList = () => {
    setIsDeleteButton(true);
    deleteContactUsAdmin({ id: deleteId }).then((data) => {
      setOpenDelete(false);
      if (data == 200) {
        setIsDeleteButton(false);
        fetchContactUsList();
      } else {
        setTimeout(() => {
          setIsDeleteButton(false);
        }, 1000);
      }
    });
  };

  useEffect(() => {
    const debouncedFetch = debounce(fetchContactUsList, 300);
    if (search || currentPage || rowsPerPage) {
      debouncedFetch();
    } else {
      fetchContactUsList();
    }
    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage, rowsPerPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Contact Us List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search"
                        // name="search"
                        autoComplete="search"
                        value={search}
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setSearch(newValue);
                          setCurrentPage(1);
                        }}
                      />
                      <span className="search-icon-box">
                        <img src={Index.Svg.search} className="search-icon" />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="contact-us .transaction-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head cus-table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  S.No
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Username
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Email
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Phone Number
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Message
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Created Date
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Created Time
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Action

                                  {(rolePermission &&
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "NotificationList_delete"
                                    )) ||
                                  (rolePermission &&
                                    rolePermission?.isAdmin === true)
                                  ? (
                                    <>
                                    { contactUsList?.length > 0 && 
                                      <PageIndex.LightTooltip title="Delete All">
                                        <Index.IconButton
                                          color="secondary"
                                          aria-label="upload picture"
                                          className="delete-all-button"
                                          component="label"
                                          onClick={() => {
                                            handleOpenDelete();
                                          }}
                                        >
                                          <Index.DeleteIcon />
                                        </Index.IconButton>
                                      </PageIndex.LightTooltip>}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {!loader ? (
                                contactUsList?.length > 0 ? (
                                  contactUsList?.map((row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {(currentPage - 1) * 10 + index + 1}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.name ? row?.name : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        <span
                                          className="cus-user-name-navigate"
                                          onClick={() => {
                                            navigate("/dashboard/user-view", {
                                              state: {
                                                data: row?.userId?._id,
                                                pathName: location.pathname,
                                              },
                                            });
                                          }}
                                        >
                                          {row?.userId?.userName
                                            ? row?.userId?.userName
                                            : "-"}
                                        </span>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.email ? row?.email : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.phoneNumber
                                          ? row?.phoneNumber
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.message &&
                                        row?.message?.length > 30 ? (
                                          <PageIndex.LightTooltip
                                            title={row?.message}
                                            arrow
                                          >
                                            <span>
                                              {row?.message.substring(0, 25)}...
                                            </span>
                                          </PageIndex.LightTooltip>
                                        ) : (
                                          row?.message || "-"
                                        )}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {moment(row.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {moment(row.createdAt).format(
                                          "hh:mm A"
                                        )}
                                      </Index.TableCell>
                                      {(rolePermission &&
                                        rolePermission?.roleType?.rolePermission?.includes(
                                          "AdminMaster_view"
                                        )) ||
                                      rolePermission?.roleType?.rolePermission?.includes(
                                        "AdminMaster_edit"
                                      ) ||
                                      rolePermission?.roleType?.rolePermission?.includes(
                                        "AdminMaster_delete"
                                      ) ||
                                      (rolePermission &&
                                        rolePermission?.isAdmin === true) ? (
                                        <>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                            // align="center"
                                          >
                                            <Index.Box className="userdata-btn-flex admin-master-btn">
                                              {/* {(rolePermission &&
                                              rolePermission?.roleType?.rolePermission?.includes(
                                                "AdminMaster_delete"
                                              )) ||
                                            (rolePermission &&
                                              rolePermission?.isAdmin ===
                                                true) ? ( */}
                                              <>
                                                <PageIndex.LightTooltip title="Delete">
                                                  <Index.IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    onClick={() => {
                                                      handleOpenDelete(row);
                                                    }}
                                                  >
                                                    <Index.DeleteIcon />
                                                  </Index.IconButton>
                                                </PageIndex.LightTooltip>
                                              </>
                                              {/* ) : (
                                              ""
                                            )} */}
                                            </Index.Box>
                                          </Index.TableCell>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Index.TableRow>
                                  ))
                                ) : (
                                  <PageIndex.RecordNotFound colSpan={8} />
                                )
                              ) : (
                                <PageIndex.TableLoader colSpan={8} />
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {contactUsList?.length ? (
              <Index.Box className="pagination-main">
                <Index.TablePagination
                  rowsPerPageOptions={[
                    { label: "All", value: -1 },
                    10,
                    25,
                    50,
                    75,
                    100,
                  ]}
                  count={pageCount}
                  rowsPerPage={rowsPerPage}
                  page={currentPage - 1}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  variant="outlined"
                  shape="rounded"
                  className="pagination"
                />
              </Index.Box>
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.DeleteModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteRecord={deleteContactUsList}
        isDeleteButton={isDeleteButton}
      />
    </>
  );
};

export default ContactUsList;
