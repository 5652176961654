import React from "react";
import PageIndex from "../../container/PageIndex";
import Index from "../../container/Index";
import { imageUrl } from "../../config/dataService";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const AddStickerModel = ({
  open,
  handleClose,
  initialValues,
  schema,
  isDeleteButtonDisabled,

  handleSubmit,
  title,
  isView,
}) => {
  return (
    <>
      <Index.Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-add modal"
      >
        <Index.Box sx={style} className="add-modal-inner-main modal-inner ">
          <Index.Box className="modal-circle-main cus-modal-cicle-main">
            <Index.IconButton onClick={handleClose}>
              <img src={Index.Png.close} className="user-circle-img" />
            </Index.IconButton>
          </Index.Box>
          <PageIndex.Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            {({
              values,
              handleBlur,
              handleChange,
              errors,
              touched,
              setFieldValue,
            }) => (
              <PageIndex.Form>
                <Index.Typography
                  className="add-modal-title"
                  component="h2"
                  variant="h2"
                >
                  {isView ? "View" : title} Sticker
                  {/* {values?.cryptoName ? "Edit" : "Add"} */}
                </Index.Typography>
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row-add-user"
                      gridTemplateColumns="repeat(12, 1fr)"
                      // gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Title <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Title"
                              name="title"
                              disabled={isView}
                              value={values.title}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  .replace(/\s\s+/g, " ");

                                if (newValue.replace(/\s/g, "").length <= 30) {
                                  setFieldValue("title", newValue);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.title ? touched?.title : undefined
                              )}
                              helperText={
                                touched?.title ? errors?.title : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Description{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>

                          <Index.Box className="form-group textarea-container">
                            <Index.TextareaAutosizeed
                              fullWidth
                              id="fullWidth"
                              className="form-control text-autosize"
                              placeholder="Message"
                              value={values.description}
                              disabled={isView}
                              maxRows={5}
                              name="description"
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  .replace(/\s\s+/g, " ");
                                setFieldValue("description", newValue);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors?.description && touched?.description && (
                              <p className="custom-error-tag">
                                {errors?.description}
                              </p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Media <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="profile-header crypto-icon-box">
                            <Index.Box className="profile-input-box">
                              <label htmlFor="btn-upload">
                                <img
                                  src={
                                    values?.image
                                      ? values?.image instanceof Blob
                                        ? URL.createObjectURL(values?.image)
                                        : `${imageUrl}${values?.image}`
                                      : PageIndex.Png.cameraImage
                                  }
                                  alt="tokenLogo img"
                                  className={
                                    values?.image
                                      ? "crypto-img"
                                      : "defaultcrypto-img"
                                  }
                                />

                                <input
                                  id="btn-upload"
                                  name="image"
                                  disabled={isDeleteButtonDisabled || isView}
                                  className="profile-upload-btn"
                                  type="file"
                                  accept="image/*"
                                  // hidden
                                  onChange={(e) => {
                                    setFieldValue("image", e.target.files[0]);
                                  }}
                                />
                                <Index.Box className="profile-edit-main">
                                  <Index.Box className="edit-div" title="Edit">
                                    {" "}
                                    <img
                                      className="profile-edit-img"
                                      src={PageIndex.Png.editLogo}
                                      alt=""
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </label>
                            </Index.Box>
                          </Index.Box>
                          {errors.image && touched.image && (
                            <p className="error-text">{errors.image}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {!isView ? (
                  <Index.Box className="delete-modal-btn-flex">
                    <Index.Button
                      className="modal-cancel-btn modal-btn"
                      onClick={handleClose}
                    >
                      Cancel
                    </Index.Button>
                    <Index.Button
                      type="submit"
                      className="modal-delete-btn modal-btn"
                      disabled={isDeleteButtonDisabled}
                    >
                      {isDeleteButtonDisabled ? (
                        <PageIndex.ButtonLoader
                          color="white"
                          size={14}
                          loading={isDeleteButtonDisabled}
                        />
                      ) : (
                        "Submit"
                      )}
                    </Index.Button>
                  </Index.Box>
                ) : (
                  ""
                )}
              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default AddStickerModel;
