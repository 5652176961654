import React, { useEffect, useState } from "react";
import PageIndex from "../../../PageIndex";
import {
  getAllRoleMaster,
  roleMasterDelete,
} from "../../../../redux/slices/adminService";
import { debounce } from "lodash";
import Index from "../../../Index";

const RoleMaster = () => {
  const navigate = PageIndex.useNavigate();
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const [openDelete, setOpenDelete] = useState(false);
  const [roleMasterList, setRoleMasterList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [deleteId, setDeleteId] = useState();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [isDeleteButton, setIsDeleteButton] = useState(false);
  //delete modal
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  //api of role list
  const listOfRoleMaster = () => {
    setLoader(true);
    getAllRoleMaster({ currentPage, search, rowsPerPage }).then((res) => {
      if (res?.status === 200) {
        setLoader(false);
        setRoleMasterList(res?.data);
        setCurrentPage(res?.currentPage);
        setPageCount(res?.totalCount ? res?.totalCount : 0);
      } else {
        setRoleMasterList([]);
      }
    });
  };
  //delete role
  const deleteUserRole = () => {
    setIsDeleteButton(true);
    setLoader(true);
    roleMasterDelete({ id: deleteId }).then((data) => {
      setOpenDelete(false);
      if (data?.status == 200) {
        listOfRoleMaster();
        setIsDeleteButton(false);
      } else {
        listOfRoleMaster();
        setTimeout(() => {
          setIsDeleteButton(false);
        }, 1000);
      }
    });
  };
  useEffect(() => {
    const debouncedFetch = debounce(listOfRoleMaster, 300);
    if (search || currentPage || rowsPerPage) {
      debouncedFetch();
    } else {
      listOfRoleMaster();
    }
    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage,rowsPerPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Role Master
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search"
                        // name="search"
                        autoComplete="search"
                        value={search}
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setCurrentPage(1);
                          // debouncedSetSearch(newValue);
                          setSearch(newValue);
                        }}
                      />
                      <span className="search-icon-box">
                        <img src={Index.Svg.search} className="search-icon" />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="add-role-permission">
                  <Index.Box className="adduser-btn-main btn-main-primary">
                    {(rolePermission &&
                      rolePermission?.roleType?.rolePermission?.includes(
                        "RoleMaster_add"
                      )) ||
                    (rolePermission && rolePermission?.isAdmin === true) ? (
                      <>
                        <Index.Button
                          className="adduser-btn btn-primary"
                          onClick={() => navigate("/dashboard/role-master-add")}
                        >
                          <img
                            src={Index.Svg.plus}
                            className="plus-icon"
                            alt="plus icon"
                          />
                          Add Roll
                        </Index.Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="role-master-table-main action-column page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head cus-table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  S.No
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Role Name
                                </Index.TableCell>
                                {(rolePermission &&
                                  rolePermission?.roleType?.rolePermission?.includes(
                                    "RoleMaster_view"
                                  )) ||
                                rolePermission?.roleType?.rolePermission?.includes(
                                  "RoleMaster_edit"
                                ) ||
                                rolePermission?.roleType?.rolePermission?.includes(
                                  "RoleMaster_delete"
                                ) ||
                                (rolePermission &&
                                  rolePermission?.isAdmin === true) ? (
                                  <>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Actions
                                    </Index.TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {!loader ? (
                                roleMasterList?.length > 0 ? (
                                  roleMasterList?.map((row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {(currentPage - 1) * 10 + index + 1}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.roleName}
                                      </Index.TableCell>
                                      {(rolePermission &&
                                        rolePermission?.roleType?.rolePermission?.includes(
                                          "RoleMaster_view"
                                        )) ||
                                      rolePermission?.roleType?.rolePermission?.includes(
                                        "RoleMaster_edit"
                                      ) ||
                                      rolePermission?.roleType?.rolePermission?.includes(
                                        "RoleMaster_delete"
                                      ) ||
                                      (rolePermission &&
                                        rolePermission?.isAdmin === true) ? (
                                        <>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                            align="center"
                                          >
                                            <Index.Box className="userdata-btn-flex">
                                              {(rolePermission &&
                                                rolePermission?.roleType?.rolePermission?.includes(
                                                  "RoleMaster_view"
                                                )) ||
                                              (rolePermission &&
                                                rolePermission?.isAdmin ===
                                                  true) ? (
                                                <>
                                                  <PageIndex.LightTooltip title="View">
                                                    <Index.IconButton
                                                      color="primary"
                                                      aria-label="upload picture"
                                                      component="label"
                                                      onClick={() =>
                                                        navigate(
                                                          "/dashboard/role-master-view",
                                                          {
                                                            state: row,
                                                          }
                                                        )
                                                      }
                                                    >
                                                      <Index.RemoveRedEyeIcon className="view-eye-Icon" />
                                                    </Index.IconButton>
                                                  </PageIndex.LightTooltip>
                                                </>
                                              ) : (
                                                ""
                                              )}

                                              {(rolePermission &&
                                                rolePermission?.roleType?.rolePermission?.includes(
                                                  "RoleMaster_edit"
                                                )) ||
                                              (rolePermission &&
                                                rolePermission?.isAdmin ===
                                                  true) ? (
                                                <>
                                                  <PageIndex.LightTooltip title="Edit">
                                                    <Index.IconButton
                                                      color="primary"
                                                      aria-label="upload picture"
                                                      component="label"
                                                      onClick={() =>
                                                        navigate(
                                                          "/dashboard/role-master-add",
                                                          {
                                                            state: row,
                                                          }
                                                        )
                                                      }
                                                    >
                                                      <Index.EditIcon className="edit-pen-Icon" />
                                                    </Index.IconButton>
                                                  </PageIndex.LightTooltip>
                                                </>
                                              ) : (
                                                ""
                                              )}

                                              {(rolePermission &&
                                                rolePermission?.roleType?.rolePermission?.includes(
                                                  "RoleMaster_delete"
                                                )) ||
                                              (rolePermission &&
                                                rolePermission?.isAdmin ===
                                                  true) ? (
                                                <>
                                                  <PageIndex.LightTooltip title="Delete">
                                                    <Index.IconButton
                                                      color="primary"
                                                      aria-label="upload picture"
                                                      component="label"
                                                      onClick={() => {
                                                        handleOpenDelete(
                                                          row._id
                                                        );
                                                      }}
                                                    >
                                                      <Index.DeleteIcon className="delete-bin-Icon" />
                                                    </Index.IconButton>
                                                  </PageIndex.LightTooltip>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </Index.Box>
                                          </Index.TableCell>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Index.TableRow>
                                  ))
                                ) : (
                                  <PageIndex.RecordNotFound colSpan={7} />
                                )
                              ) : (
                                <PageIndex.TableLoader colSpan={7} />
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {roleMasterList?.length ? (
              <Index.Box className="pagination-main">
                <Index.TablePagination
                rowsPerPageOptions={[
                  { label: "All", value: -1 },
                  10,
                  25,
                  50,
                  75,
                  100,
                ]}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={currentPage - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
              </Index.Box>
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.DeleteModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteRecord={deleteUserRole}
        isDeleteButtonDisabled={isDeleteButton}
      />
    </>
  );
};

export default RoleMaster;
