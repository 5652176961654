import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { editUserMaster } from "../../../../redux/slices/adminService";
import { imageUrl } from "../../../../config/dataService";

const EditUser = () => {
  const params = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate();
  const editData = params?.state?.data;
  const tabValue = params?.state?.btnValue;
  const [image, setImage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [links, setLinks] = useState(
    editData ? editData?.links?.split(",") : [""]
  );

  const addLinkField = () => {
    setLinks([...links, ""]);
  };

  const removeLinkField = (index) => {
    const newLinks = links?.filter((_, i) => i !== index);
    setLinks(newLinks);
  };

  const initialValues = {
    name: editData ? editData?.name : "",
    userName: editData ? editData?.userName : "",
    Bio: editData ? editData?.Bio : "",
    links: editData ? editData?.links : "",
    profile: editData ? editData?.profile : "",
  };

  const handleSubmit = async (values) => {
    setLoader(true);
    const urlencoded = new FormData();
    urlencoded.append("id", editData._id);
    urlencoded.append("name", values.name);
    urlencoded.append("userName", values.userName);
    urlencoded.append("Bio", values.Bio || "");
    urlencoded.append("links", links?.join(","));
    urlencoded.append("profile", image ? image : values.profile);

    editUserMaster(urlencoded).then((data) => {
      setLoader(true);
      if (data.status === 200) {
        setLoader(false);
        navigate("/dashboard/user-list", {
          state: { btnValue: tabValue },
        });
      } else {
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      }
    });
  };

  // const handleSubmit = async (values) => {
  //   setLoader(true);
  //   const urlencoded = new FormData();
  //   urlencoded.append("id", editData._id);
  //   urlencoded.append("name", values?.name);
  //   urlencoded.append("userName", values?.userName);
  //   urlencoded.append("Bio", values?.Bio == undefined ? "" : values.Bio);
  //   urlencoded.append("links", values?.links == undefined ? "" : values.links);
  //   urlencoded.append("profile", image ? image : values?.profile);

  //   editUserMaster(urlencoded).then((data) => {
  //     setLoader(true);
  //     if (data?.status == 200) {
  //       setLoader(false);
  //       navigate("/dashboard/user-list",{
  //         state: {
  //           btnValue: tabValue
  //         }
  //       });
  //     } else {
  //       setTimeout(() => {
  //         setLoader(false);
  //       }, 3000);
  //     }
  //   });
  // };

  return (
    <>
      <Index.Box className="dashboard-content ">
        <PageIndex.BackButton
          onClick={() =>
            navigate("/dashboard/user-list", {
              state: {
                btnValue: tabValue,
              },
            })
          }
        />
        <Index.Box className="barge-common-box">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            Edit User
          </Index.Typography>

          <PageIndex.Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={PageIndex.editUserMasterSchema}
          >
            {({
              values,
              handleBlur,
              handleChange,
              errors,
              setFieldValue,
              touched,
            }) => (
              <PageIndex.Form>
                <Index.Box className="edit-profile-flex">
                  <Index.Box className="file-upload-btn-main">
                    <img
                      src={
                        image
                          ? URL.createObjectURL(image)
                          : values?.profile
                          ? `${imageUrl}${editData?.profile}`
                          : PageIndex.Png.userDefaultIcon
                      }
                      // onChange={(e) => e.target.files[0]}
                      className="upload-profile-img"
                      alt="upload img"
                    ></img>
                    <Index.Button
                      variant="contained"
                      component="label"
                      className="file-upload-btn"
                    >
                      <img
                        src={Index.Svg.edit}
                        className="upload-icon-img"
                        alt="upload img"
                      ></img>
                      <input
                        hidden
                        accept="image/*"
                        name="profile"
                        type="file"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                          setFieldValue("profile", e.target.files[0]);
                        }}
                      />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                {/* {errors?.profile && touched?.profile && (
                  <p className="image-error">{errors?.profile}</p>
                )} */}
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row-add-user"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Name
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Name"
                              name="name"
                              value={values.name}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  .replace(/\s\s+/g, " ");
                                setFieldValue("name", newValue);
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.name ? touched?.name : undefined
                              )}
                              helperText={
                                touched?.name ? errors?.name : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Username
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Username"
                              name="userName"
                              value={values.userName}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                );

                                setFieldValue("userName", newValue);
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.userName ? touched?.userName : undefined
                              )}
                              helperText={
                                touched?.userName ? errors?.userName : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Bio
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              type="text"
                              className="form-control"
                              placeholder="Bio"
                              name="Bio"
                              value={values.Bio}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  .replace(/\s\s+/g, " ");
                                setFieldValue("Bio", newValue);
                              }}
                              error={Boolean(
                                errors?.Bio ? touched?.Bio : undefined
                              )}
                              helperText={
                                touched?.Bio ? errors?.Bio : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      {/* <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Links
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Links"
                              name="links"
                              value={values.links}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setFieldValue("links", newValue);
                              }}
                              // onChange={(e) => {
                              //   const newValue = e.target.value.replace(
                              //     /\s/g,
                              //     ""
                              //   );
                              //   setFieldValue("links", newValue);
                              // }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.links ? touched?.links : undefined
                              )}
                              helperText={
                                touched?.links ? errors?.links : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box> */}

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Links
                          </Index.FormHelperText>
                          {links && links?.map((link, index) => (
                            <Index.Box
                              key={index}
                              className="form-group form-group-editlink"
                            >
                              <Index.TextField
                                fullwidth
                                id={`link-${index}`}
                                className="form-control form-control-link"
                                placeholder="Link"
                                name={`link-${index}`}
                                value={link}
                                onChange={(e) => {
                                  const newLinks = [...links];
                                  newLinks[index] = e.target.value;
                                  setLinks(newLinks);
                                }}
                                onBlur={handleBlur}
                                error={Boolean(
                                  errors?.links ? touched?.links : undefined
                                )}
                                helperText={
                                  touched?.links ? errors?.links : undefined
                                }
                              />
                              <Index.Box>
                                <Index.IconButton
                                  className="Link-button-negative"
                                  onClick={() => removeLinkField(index)}
                                  disabled={links.length === 1}
                                >
                                  -
                                </Index.IconButton>
                                {index === links.length - 1 && (
                                  <Index.IconButton
                                    className="Link-button-positive"
                                    onClick={addLinkField}
                                  >
                                    +
                                  </Index.IconButton>
                                )}
                              </Index.Box>
                            </Index.Box>
                          ))}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="user-btn-flex">
                    <Index.Box className="save-btn-main border-btn-main">
                      <Index.Button
                        className="save-user-btn border-btn"
                        type="submit"
                        disabled={loader}
                      >
                        {loader ? (
                          <PageIndex.ButtonLoader
                            color="white"
                            size={14}
                            loading={loader}
                          />
                        ) : (
                          <>
                            <img
                              src={Index.Svg.save}
                              className="user-save-icon"
                            ></img>
                            Update
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default EditUser;
