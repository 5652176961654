import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {
  WinnerDeclare,
  WinnerRemove,
  getSingleContest,
  winnerListWithCheckBox,
} from "../../../../redux/slices/adminService";
import { imageUrl } from "../../../../config/dataService";
import { Card, CardMedia } from "@mui/material";
import ReactPlayer from "react-player";

const WinnerList = ({ editData, winnerPage }) => {
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [contestParticipantList, setContestParticipantList] = useState();
  const [loader, setLoader] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [filterData, setFilterData] = useState([]);
  const [CheckBox, setCheckBox] = useState(false);
  const [ContestParticipantWinnerList, setContestParticipantWinnerList] =
    useState([]);

  const handleChangeCheckBox = (row) => {
    setLoader(true);
    WinnerRemove(row?._id).then((res) => {
      if (res?.status == 200) {
        getWinnerlistWithCheck();
      }
      setLoader(false);
    });
  };
  const getContest = () => {
    setLoader(true);
    getSingleContest(editData?._id).then((res) => {
      setLoader(false);
      setContestParticipantList(res?.data[0]?.contest);
    });
  };

  const winnerAnnounce = () => {
    setLoader(true);
    WinnerDeclare(editData?._id).then((res) => {
      getWinnerlistWithCheck();
    });
  };

  const getWinnerlistWithCheck = () => {
    setLoader(true);
    winnerListWithCheckBox(editData?._id).then((res) => {
      setLoader(false);
      setContestParticipantWinnerList(res?.data);
      setFilterData(res?.data);
    });
  };

  useEffect(() => {
    // getContest();
    getWinnerlistWithCheck();
  }, [editData]);

  const handleRestore = () => {};
  useEffect(() => {
    const filterData = ContestParticipantWinnerList?.filter((item) => {
      return search
        ? item?.userId?.userName.toLowerCase().includes(search.toLowerCase())
        : item;
    });
    setFilterData(filterData);
  }, [search, ContestParticipantWinnerList]);

  const handleVideoClick = (row) => {
    
    if (row === currentVideoId) {
      setCurrentVideoId(null);
    } else {
      if (currentVideoId !== null) {
        setCurrentVideoId(null);
      }
      setCurrentVideoId(row);
    }
  };
  return (
    <Index.Box className="table-wrape">
      <Index.Box className="external-tab-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title tittle-like-costam"
                  component="h2"
                  variant="h2"
                >
                  Winner List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setCurrentPage(1);
                          setSearch(newValue);
                        }}
                      />
                      <span className="search-icon-box">
                        <img src={Index.Svg.search} className="search-icon" />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {ContestParticipantWinnerList?.length && !loader
                  ? ContestParticipantWinnerList[0]?.isAnnounceWinner ||
                    (winnerPage == "winnerPage" && (
                      <Index.Box className="adduser-btn-main btn-main-primary">
                        <Index.Button
                          className="adduser-btn btn-primary"
                          onClick={winnerAnnounce}
                        >
                          Announce Win
                        </Index.Button>
                      </Index.Box>
                    ))
                  : ""}
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dash-box">
              <Index.Box className="page-table-main contest-winner-view-table">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table
                    stickyHeader
                    aria-label="simple table"
                    className="table"
                  >
                    <Index.TableHead className="table-head cus-table-head">
                      <Index.TableRow className="table-row">
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        ></Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          S. No.
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Media File
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Name
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Rank
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Winning Price
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Final Price
                        </Index.TableCell>
                        {/* <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Action
                        </Index.TableCell> */}
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="table-body">
                      {!loader ? (
                        pageData?.length ? (
                          pageData?.map((row, index) => (
                            <Index.TableRow key={index}>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                <Index.Checkbox
                                  onChange={() => handleChangeCheckBox(row)}
                                  // disabled={
                                  //   contestDetails?.winningPrice?.length ==
                                  //     contestDetails?.priceDistribution
                                  //       ?.length ||
                                  //   contestDetails?.priceDistribution?.some(
                                  //     (data) => data?.userId == row?.userId
                                  //   )
                                  // }
                                  checked={!row?.isDeleted}
                                  disabled={row?.isAnnounceWinner}
                                  //   checked={ContestParticipantWinnerList?.some(
                                  //     (data) => data?.userId?._id == row?.userId?._id
                                  //   )}
                                />
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row.sNo}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td participant_table_vedio"
                                align="center"
                                // onClick={() => handleVideoClick(row?.media)}
                              >
                                
                                {/* <ReactPlayer
                                  component="video"
                                  controls
                                  width={130}
                                  height={70}
                            
                                  url={`${imageUrl}${row?.media}`}
                                  playing={row.userId === currentVideoId?true:false}
                                  onPlay={() => handleVideoClick(row)}
                                /> */}
                                   <Index.Box className='video-swiper' >
                                      <ReactPlayer
                                        component="video"
                                        controls
                                        width={150}
                                        height={65}
                                        className="react-player"
                                        url={`${imageUrl}${row?.reelId?.media}`}
                                        playing={row._id == currentVideoId ? true : false}
                                        onPlay={() => handleVideoClick(row?._id)}
                                      />
                              </Index.Box>
                               
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.userId?.userName}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.Rank}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.prize}%
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.coin ? row?.coin : "-"}
                              </Index.TableCell>
                              {/* <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                align="center"
                              >
                                <Index.Box className="userdata-btn-flex">
                                  <PageIndex.LightTooltip placement="right">
                                    <Index.IconButton
                                      color="primary"
                                      aria-label="upload picture"
                                      component="label"
                                      onClick={() => {
                                        handleRestore(row?.userId?._id);
                                      }}
                                    >
                                      <Index.SettingsBackupRestoreIcon className="view-eye-Icon" />
                                    </Index.IconButton>
                                  </PageIndex.LightTooltip>
                                </Index.Box>
                              </Index.TableCell> */}
                            </Index.TableRow>
                          ))
                        ) : (
                          <PageIndex.RecordNotFound colSpan={8} />
                        )
                      ) : (
                        <PageIndex.TableLoader colSpan={8} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
            <PageIndex.Pagination
              fetchData={filterData}
              setPageData={setPageData}
              pageData={pageData}
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default WinnerList;
