import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import PageIndex from "../../../PageIndex";
import { createContactUs, getCmsDetails, } from "../../../../redux/slices/adminService";


const ContactUs = () => {
  const [addEditData, setAddEditData] = useState();
  const [loader, setLoader] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const initialValues = {
    website: addEditData?.website ? addEditData?.website : "",
    email: addEditData?.email ? addEditData?.email : "",
    mobile: addEditData?.mobile ? addEditData?.mobile : "",
  };

  const getContactUsdata = () => {
    getCmsDetails().then((data) => {
      if (data?.status == 200) {
        setAddEditData(data?.data?.contactUs)
        setLoader(false);
      }
      else {
        setLoader(false);
      }
    });
  };

  const handleSubmitContactUs = (values) => {
    setIsDisabled(true)
    createContactUs(values).then((res) => {
      if (res?.status === 201) {
        setTimeout(() => {
          setIsDisabled(false);
        }, 3000);
      } else {
        setTimeout(() => {
          setIsDisabled(false);
        }, 3000);
      }
    });
  };

  useEffect(() => {
    getContactUsdata();
  }, []);


  return (
    <>
      {!loader &&
        <Index.Box>
          <Index.Box className="dashboard-content">
            <Index.Box className="barge-common-box">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Contact Us
                </Index.Typography>
              </Index.Box>
              <PageIndex.Formik
                onSubmit={handleSubmitContactUs}
                initialValues={initialValues}
                validationSchema={PageIndex.cmsContactUsSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <PageIndex.Form
                    spacing={2}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <Index.Box className="add-user-data-main flex-contactus">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          className="display-row-edit-profile"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Website  <span className="mandatory-asterisk">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  disabled={isDisabled ? true : false}
                                  name="website"
                                  placeholder="Website"
                                  value={values.website}
                                  onChange={(e) => {
                                    const newValue = e.target.value
                                      .replace(/\s+/, "")

                                    setFieldValue("website", newValue);
                                  }}
                                  id="fullWidth"
                                  className="form-control"
                                  onBlur={handleBlur}
                                  error={Boolean(
                                    errors?.website
                                      ? touched?.website
                                      : undefined
                                  )}
                                  helperText={
                                    touched?.website
                                      ? errors?.website
                                      : undefined
                                  }
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Email  <span className="mandatory-asterisk">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  name="email"
                                  disabled={isDisabled ? true : false}
                                  value={values.email}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(
                                      /\s/g,
                                      ""
                                    );
                                    setFieldValue("email", newValue);
                                  }}
                                  onBlur={handleBlur}
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control email-input "
                                  placeholder="Email"
                                  error={Boolean(
                                    errors?.email ? touched?.email : undefined
                                  )}
                                  helperText={
                                    touched?.email ? errors?.email : undefined
                                  }
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Mobile number  <span className="mandatory-asterisk">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  disabled={isDisabled ? true : false}
                                  id="fullWidth"
                                  className="form-control"
                                  type="text"
                                  placeholder="Mobile number"
                                  name="mobile"
                                  value={values.mobile}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(
                                      /\D+/g,
                                      ""
                                    );
                                    if (newValue.length <= 10) {
                                      setFieldValue("mobile", newValue);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  error={Boolean(
                                    errors?.mobile
                                      ? touched?.mobile
                                      : undefined
                                  )}
                                  helperText={
                                    touched?.mobile
                                      ? errors?.mobile
                                      : undefined
                                  }
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="save-btn-main border-btn-main update-btn cms-btn-main">
                          <Index.Button
                            className={`save-user-btn border-btn ${isDisabled ? "btn-disabled" : ""}`}
                            type="submit"
                            disabled={isDisabled}
                          >
                        {isDisabled ? (
                          <PageIndex.ButtonLoader color="white" size={14} loading={isDisabled} />
                        ) : (
                          <>
                            <img
                              src={Index.Svg.save}
                              className="user-save-icon"
                              alt="Save Icon"
                            />
                            Update
                          </>
                        )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </PageIndex.Form>
                )}
              </PageIndex.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      }
    </>

  );
};

export default ContactUs;
