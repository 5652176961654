import React, { useEffect, useState } from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import ConversionRequestList from "./ConversionList";
import WithdrawalRequestList from "./WithdrawalList";
import {
  autoLogout,
  getWithdrawalRequestList,
  updateCryptoRequest,
  updateFiatRequest,
  updateWithdrawRequest,
} from "../../../../redux/slices/adminService";
import { debounce } from "lodash";
import {
  logout,
  updateRolePermission,
} from "../../../../redux/slices/AdminSlice";

const typeList = [
  { id: 1, value: "All" },
  { id: 2, value: "Approved" },
  { id: 3, value: "Pending" },
  { id: 4, value: "Rejected" },
];

const WithdrawalAndConversionRequest = () => {
  const dayTypeList = [
    { id: 1, value: "today", key: "Today" },
    { id: 2, value: "overview", key: "Overview" },
  ];
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const abortController = new AbortController();
  const queryParams = new URLSearchParams(window.location.search);
  const stateParam = queryParams.get("state");
  const state = JSON.parse(decodeURIComponent(stateParam));
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);

  const [dayType, setDayType] = useState(
    state?.today ? state?.today : "overview"
  );
  const [type, setType] = useState("All");
  const [defaultTabIndex, setDefaultTabIndex] = useState(0);
  const [value, setValue] = useState(0);
  const [loader, setLoader] = useState(true);
  const [singleWithdrawalData, setSingleWithdrawalRequest] = useState("");
  const [withdrawalList, setwithdrawalList] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [reason, setReason] = useState("");

  const [open, setOpen] = useState(false);
  const [openDeclineModel, setOpenDeclineModel] = useState(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const [isViewData, setIsViewData] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [actualTransactionId, setActualTransactionId] = useState(
    singleWithdrawalData?.actualTransactionId || ""
  );
  const [actualTransactionIdError, setActualTransactionIdError] = useState("");
  const { token } = PageIndex.useSelector((state) => state.admin);

  const handleDayType = (e) => {
    setDayType(e.target.value);
    navigate({ state: "" });
    setCurrentPage(1);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    autoLogout(token).then((res) => {
      if (res?.data?.status == 200) {
        dispatch(updateRolePermission(res?.data?.data));
      } else if (res?.response?.data?.status == 401) {
        dispatch(logout());
        navigate("/");
      }
    });
  };

  const handleOpenDeclineModel = (data) => {
    setOpenDeclineModel(true);
    setSingleWithdrawalRequest(data);
  };
  const handleCloseDeclineModel = () => {
    setActualTransactionId("");
    setActualTransactionIdError("");
    setOpenDeclineModel(false);
  };

  const handleOpen = (data, isView = false) => {
    setOpen(true);
    setIsViewData(isView);
    setSingleWithdrawalRequest(data);
  };
  const handleClose = () => {
    setOpen(false);
    setActualTransactionId("");
    setActualTransactionIdError("");
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const fetchWithrawalCrytoList = (page) => {
    setLoader(true);
    getWithdrawalRequestList(
      {
        type: type,
        today: dayType,
        page: page ? page : currentPage,
        rowsPerPage : rowsPerPage,
        search: search,
        ...(value === 0
          ? { withdrawRequest: true }
          : { cryptoRequest: true, fiatRequest: true }),
      },
      { signal: abortController.signal }
    ).then((data) => {
      if (data?.status == 200) {
        setLoader(false);
        const filteredData = data?.data?.filter((item) => {
          if (type === "All") {
            return true;
          }
          return item.requestStatus === type;
        });

        setwithdrawalList(filteredData);
        setFilterData(filteredData);
        if (data?.currentPage) {
          setCurrentPage(data?.currentPage);
        }
        setPageCount(data?.totalCount ? data?.totalCount : 0);
      }
    });
  };
  useEffect(() => {
    setLoader(true);
    const debouncedFetch = debounce(fetchWithrawalCrytoList, 300);
    if (search || currentPage || value || type || rowsPerPage) {
      debouncedFetch();
    } else {
      fetchWithrawalCrytoList();
    }

    return () => {
      debouncedFetch.cancel();
      abortController.abort();
    };
  }, [search, currentPage, value, dayType, type, rowsPerPage]);

  useEffect(() => {
    setType("All");
    setSearch("");
  }, [value]);

  const handleReason = async (values, status, isConversion) => {
    setLoader(true);
    setIsDeleteButtonDisabled(true);
    const urlencoded = new URLSearchParams();
    if (values?.reason) {
      urlencoded.append("rejectReason", values?.reason);
      urlencoded.append("actualTransactionId", values?.actualTransactionId);
    }
    urlencoded.append("requestId", singleWithdrawalData?._id);
    if (status === "Approved") {
      urlencoded.append("status", "Approved");
    } else {
      urlencoded.append("status", "Rejected");
    }
    if (actualTransactionId) {
      urlencoded.append("actualTransactionId", actualTransactionId);
    }
    if (value == 1) {
      if (singleWithdrawalData?.cryptoRequest === true) {
        updateCryptoRequest(urlencoded).then((data) => {
          if (data?.status == 200) {
            handleClose();
            handleCloseDeclineModel();
            fetchWithrawalCrytoList();
            setIsDeleteButtonDisabled(false);
            setLoader(false);
          } else {
            handleClose();
            setIsDeleteButtonDisabled(false);
            fetchWithrawalCrytoList();
          }
        });
      } else {
        updateFiatRequest(urlencoded).then((data) => {
          if (data?.status == 200) {
            handleClose();
            handleCloseDeclineModel();
            fetchWithrawalCrytoList();
            setIsDeleteButtonDisabled(false);
            setLoader(false);
          } else {
            handleClose();
            setIsDeleteButtonDisabled(false);
            fetchWithrawalCrytoList();
          }
        });
      }
    } else {
      updateWithdrawRequest(urlencoded).then((data) => {
        if (data?.status == 200) {
          handleClose();
          handleCloseDeclineModel();
          fetchWithrawalCrytoList();
          setIsDeleteButtonDisabled(false);
          setLoader(false);
        } else {
          handleClose();
          setIsDeleteButtonDisabled(false);
          fetchWithrawalCrytoList();
        }
      });
    }
  };

  const handleType = (e) => {
    setType(e.target.value);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (
      rolePermission &&
      rolePermission?.roleType?.rolePermission?.includes(
        "WithdrawalList_view"
      ) &&
      rolePermission?.roleType?.rolePermission?.includes("ConversionList_view")
    ) {
      setDefaultTabIndex(0);
    } else if (
      rolePermission &&
      rolePermission?.roleType?.rolePermission?.includes("WithdrawalList_view")
    ) {
      setDefaultTabIndex(0);
      setValue(0);
    } else if (
      rolePermission &&
      rolePermission?.roleType?.rolePermission?.includes("ConversionList_view")
    ) {
      setDefaultTabIndex(1);
      setValue(1);
    }
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  // rolePermission, value
  return (
    <>
      <Index.Box className="table-wrape">
        <Index.Box className="external-tab-box">
          <Index.Box className="admin-page-title-main">
            <Index.Typography
              className="admin-page-title tittle-like-costam"
              component="h2"
              variant="h2"
            >
              Requests List
            </Index.Typography>
          </Index.Box>
          <Index.Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="admin-tabs-main"
          >
            {(rolePermission &&
              rolePermission?.roleType?.rolePermission?.includes(
                "WithdrawalList_view"
              )) ||
            (rolePermission && rolePermission?.isAdmin === true) ? (
              <Index.Tab
                label="Withdrawal Request"
                {...a11yProps(0)}
                className="admin-tab"
              />
            ) : (
              ""
            )}
            {(rolePermission &&
              rolePermission?.roleType?.rolePermission?.includes(
                "ConversionList_view"
              )) ||
            (rolePermission && rolePermission?.isAdmin === true) ? (
              <Index.Tab
                label="Conversion Request"
                {...a11yProps(1)}
                className="admin-tab"
              />
            ) : (
              ""
            )}
          </Index.Tabs>
        </Index.Box>

        {defaultTabIndex === 0 && value === 0 ? (
          <WithdrawalRequestList
            setSearch={setSearch}
            dayTypeList={dayTypeList}
            dayType={dayType}
            handleDayType={handleDayType}
            typeList={typeList}
            fetchWithrawalCrytoList={fetchWithrawalCrytoList}
            loader={loader}
            setLoader={setLoader}
            singleWithdrawalData={singleWithdrawalData}
            withdrawalList={withdrawalList}
            filterData={filterData}
            setwithdrawalList={setwithdrawalList}
            search={search}
            reason={reason}
            setReason={setReason}
            currentPage={currentPage}
            pageCount={pageCount}
            rowsPerPage = {rowsPerPage}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage = {handleChangeRowsPerPage}
            open={open}
            openDeclineModel={openDeclineModel}
            isDeleteButtonDisabled={isDeleteButtonDisabled}
            isViewData={isViewData}
            handleClose={handleClose}
            handleCloseDeclineModel={handleCloseDeclineModel}
            handleOpen={handleOpen}
            handleOpenDeclineModel={handleOpenDeclineModel}
            rolePermission={rolePermission}
            handleReason={handleReason}
            handleType={handleType}
            type={type}
            setCurrentPage={setCurrentPage}
            actualTransactionId={actualTransactionId}
            setActualTransactionId={setActualTransactionId}
            actualTransactionIdError={actualTransactionIdError}
            setActualTransactionIdError={setActualTransactionIdError}
          />
        ) : (
          <ConversionRequestList
            handleReason={handleReason}
            dayTypeList={dayTypeList}
            dayType={dayType}
            handleDayType={handleDayType}
            setSearch={setSearch}
            typeList={typeList}
            loader={loader}
            setLoader={setLoader}
            singleWithdrawalData={singleWithdrawalData}
            withdrawalList={withdrawalList}
            setwithdrawalList={setwithdrawalList}
            filterData={filterData}
            search={search}
            reason={reason}
            setReason={setReason}
            currentPage={currentPage}
            pageCount={pageCount}
            rowsPerPage = {rowsPerPage}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage = {handleChangeRowsPerPage}
            open={open}
            openDeclineModel={openDeclineModel}
            isDeleteButtonDisabled={isDeleteButtonDisabled}
            isViewData={isViewData}
            handleClose={handleClose}
            handleCloseDeclineModel={handleCloseDeclineModel}
            handleOpen={handleOpen}
            handleOpenDeclineModel={handleOpenDeclineModel}
            rolePermission={rolePermission}
            handleType={handleType}
            type={type}
            fetchWithrawalCrytoList={fetchWithrawalCrytoList}
            setCurrentPage={setCurrentPage}
            actualTransactionId={actualTransactionId}
            setActualTransactionId={setActualTransactionId}
            actualTransactionIdError={actualTransactionIdError}
            setActualTransactionIdError={setActualTransactionIdError}
          />
        )}
      </Index.Box>
    </>
  );
};

export default WithdrawalAndConversionRequest;
