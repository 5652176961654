import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PageIndex";
import {
  autoLogout,
  getAllContestForGraph,
  getUserActivity,
  getUserContestActivityLog,
  getUserFollowersList,
} from "../../../../../redux/slices/adminService";
import ContestGrowth from "../UserActivity/ContestGrowth";
import FollowersGrowth from "../UserActivity/FollowersGrowth";
import UserEngagement from "../UserActivity/Engagement";
import DeviceHistory from "../UserActivity/DeviceHistory";
import dayjs from "dayjs";
import { debounce } from "lodash";
import {
  logout,
  updateRolePermission,
} from "../../../../../redux/slices/AdminSlice";
import { useNavigate } from "react-router-dom";
import FollowersList from "../UserActivity/FollowersList";
import UserFollowingList from "../UserActivity/FollowingUserList";

const UserActivity = ({ userId }) => {
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const { token } = PageIndex.useSelector((state) => state.admin);
  // State variables
  const [loader, setLoader] = useState(true);
  const [followerLoader, setFollowerLoader] = useState(true);
  const [contestLoader, setContestLoader] = useState(true);
  const [engagementLoader, setEngagementLoader] = useState(true);
  const [followerData, setFollowerData] = useState([]);
  const [contestData, setContestData] = useState([]);
  const [engagementData, setEngagementData] = useState([]);
  const [contestList, setContestList] = useState([]);
  const [value, setValue] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [range, setRange] = useState(7);
  const [contestName, setContestName] = useState("");

  // Handlers
  const handleFromDateChange = (event) => {
    setRange("");
    setFromDate(event);
  };

  const handleToDateChange = (event) => {
    setToDate(event);
    setRange("");
  };

  const handleRangeChange = (newRange) => {
    setRange(newRange);
    setFromDate("");
    setToDate("");
  };

  const handleClearFromDate = () => setFromDate("");
  const handleClearToDate = () => setToDate("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    autoLogout(token).then((res) => {
      if (res?.data?.status == 200) {
        dispatch(updateRolePermission(res?.data?.data));
      } else if (res?.response?.data?.status == 401) {
        dispatch(logout());
        navigate("/");
      }
    });
  };

  const fetchUserFollowerActivity = () => {
    const fetchData = async () => {
      if (value === 0) {
        const obj = {
          userId: userId,
          startDate: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : "",
          endDate: toDate ? dayjs(toDate).format("YYYY-MM-DD") : "",
          days: range || "",
        };

        if (range || (fromDate && toDate)) {
          try {
            const data = await getUserFollowersList(obj);
            if (data?.status === 200) {
              setFollowerData(data?.data);
            }
          } finally {
            setFollowerLoader(false);
          }
        }
      } else if (value === 1) {
        try {
          const res = await getAllContestForGraph({ userId });
          if (res?.status === 200) {
            const sortedContestList = res?.data;
            setContestList(sortedContestList);
            if (sortedContestList?.length > 0) {
              const contestId = contestName || sortedContestList[0]?._id;
              const data = await getUserContestActivityLog({
                contestId,
                userId,
              });
              if (data?.status === 200) {
                setContestData(data?.data);
              }
            }
          } else {
            setContestList([]);
          }
        } finally {
          setContestLoader(false);
        }
      } else if (value === 2) {
        try {
          const data = await getUserActivity({ userId });
          if (data?.status === 200) {
            setEngagementData(data?.data.reverse());
          }
        } finally {
          setEngagementLoader(false);
        }
      }
    };

    fetchData();
  };

  useEffect(() => {
    fetchUserFollowerActivity();
  }, [range, value, contestName]);

  useEffect(() => {
    const debouncedFetch = debounce(fetchUserFollowerActivity, 300);

    if (fromDate || toDate) {
      debouncedFetch();
    } else if (!fromDate && !toDate) {
      setRange(7);
      fetchUserFollowerActivity();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [fromDate, toDate]);

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Box className="admin-page-title-main">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              User Activity
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="external-tab-box">
          <Index.Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="admin-tabs-main"
          >
            <Index.Tab
              label="Followers"
              id="simple-tab-0"
              aria-controls="simple-tabpanel-0"
              className="admin-tab"
            />
            <Index.Tab
              label="Contest"
              id="simple-tab-1"
              aria-controls="simple-tabpanel-1"
              className="admin-tab"
            />
            <Index.Tab
              label="Engagement"
              id="simple-tab-2"
              aria-controls="simple-tabpanel-2"
              className="admin-tab"
            />
            <Index.Tab
              label="Device History"
              id="simple-tab-3"
              aria-controls="simple-tabpanel-3"
              className="admin-tab"
            />
            <Index.Tab
              label="Followers List"
              id="simple-tab-4"
              aria-controls="simple-tabpanel-4"
              className="admin-tab"
            />
            <Index.Tab
              label="Following List"
              id="simple-tab-5"
              aria-controls="simple-tabpanel-5"
              className="admin-tab"
            />
          </Index.Tabs>
        </Index.Box>
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            {value === 0 ? (
              !followerLoader ? (
                <FollowersGrowth
                  userId={userId}
                  range={range}
                  loader={loader}
                  followerLoader={followerLoader}
                  followerData={followerData}
                  setFollowerData={setFollowerData}
                  fromDate={fromDate}
                  toDate={toDate}
                  setRange={setRange}
                  handleRangeChange={handleRangeChange}
                  handleFromDateChange={handleFromDateChange}
                  handleToDate={handleToDateChange}
                  handleClearFromDate={handleClearFromDate}
                  handleClearToDate={handleClearToDate}
                />
              ) : (
                <PageIndex.Loading />
              )
            ) : value === 1 ? (
              !contestLoader ? (
                <ContestGrowth
                  contestData={contestData}
                  setFollowerData={setFollowerData}
                  contestLoader={contestLoader}
                  userId={userId}
                  contestList={contestList}
                  contestName={contestName}
                  setContestName={setContestName}
                />
              ) : (
                <PageIndex.Loading />
              )
            ) : value === 2 ? (
              <UserEngagement
                engagementData={engagementData}
                engagementLoader={engagementLoader}
                userId={userId}
              />
            ) : value === 3 ? (
              <DeviceHistory userId={userId} />
            ) : value === 4 ? (
              <FollowersList userId={userId} />
            ) : value === 5 ? (
              <UserFollowingList userId={userId} />
            ) : (
              <PageIndex.Loading />
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default UserActivity;
