import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { streakHistoryAction } from "../../../../redux/slices/adminService";
import moment from "moment";
import numeral from "numeral";


const StreakHistory = () => {
    const navigate = PageIndex.useNavigate();
    const [streakList, setStreakList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState("");
    const [filterData, setFilterData] = useState([]);
    const [pageData, setPageData] = useState([]);
    const { rolePermission } = PageIndex.useSelector((state) => state.admin);
    const { state } = PageIndex.useLocation();

    const fromId = state?.row?.from?._id;
    const toId = state?.row?.to?._id;
    //api of users feedback list

    const streakHistory = () => {
        setLoader(true);
        streakHistoryAction({ to: toId, from: fromId }).then((res) => {
            if (res?.status === 200) {
                setLoader(false);
                setStreakList(res?.data);
            } else {
                setLoader(false);
                setStreakList([]);
            }
        });
    };


    useEffect(() => {
        streakHistory()
    }, []);


    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        const filterData = streakList[0]?.StreakCount?.filter((item) => {
            return search
                ? item?.StreakCount?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
                new Date(item?.startDate)
                    ?.toLocaleDateString("en-GB")
                    ?.toString()
                    .includes(search?.toLowerCase()) ||
                new Date(item?.endDate)
                    ?.toLocaleDateString("en-GB")
                    ?.toString()
                    .includes(search?.toLowerCase())

                :
                item;
        });
        setFilterData(filterData);
    }, [search, streakList]);

    return (
        <>
            <Index.Box className="dashboard-content">
                <PageIndex.BackButton onClick={() => navigate("/dashboard/streak-details", {
                    state: {
                        data: toId
                    },
                })} />
                <Index.Box className="admin-dashboard-list-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                            gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 9",
                            }}
                            className="grid-column"
                        >
                            <Index.Box className="right-wrape">
                                <Index.Box className="table-wrape">
                                    <Index.Box className="external-tab-box">
                                        <Index.Box className="barge-common-box">
                                            <Index.Box className="common-box">
                                                <Index.Box className="user-list-flex">
                                                    <Index.Box className="admin-page-title-main">
                                                        <Index.Typography
                                                            className="admin-page-title tittle-like-costam"
                                                            component="h2"
                                                            variant="h2"
                                                        >
                                                            {state?.row?.to?.name} and {state?.row?.from?.name} Streak History
                                                        </Index.Typography>
                                                    </Index.Box>
                                                    <Index.Box className="userlist-btn-flex">
                                                        <Index.Box className="user-search-main">
                                                            <Index.Box className="user-search-box">
                                                                <Index.Box className="form-group">
                                                                    <Index.TextField
                                                                        fullWidth
                                                                        id="fullWidth"
                                                                        className="form-control"
                                                                        placeholder="Search user"
                                                                        onChange={handleSearch}
                                                                    />
                                                                    <span className="search-icon-box">
                                                                        <img
                                                                            src={Index.Svg.search}
                                                                            className="search-icon"
                                                                        />
                                                                    </span>
                                                                </Index.Box>
                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="admin-dash-box">
                                                    <Index.Box className="page-table-main streak-detail-table ">
                                                        <Index.TableContainer
                                                            component={Index.Paper}
                                                            className="table-container"
                                                        >
                                                            <Index.Table
                                                                stickyHeader
                                                                aria-label="simple table"
                                                                className="table"
                                                            >
                                                                <Index.TableHead className="table-head cus-table-head">
                                                                    <Index.TableRow className="table-row">
                                                                        <Index.TableCell
                                                                            component="th"
                                                                            variant="th"
                                                                            className="table-th"
                                                                            align="center"
                                                                        >
                                                                            S.No.
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="th"
                                                                            variant="th"
                                                                            className="table-th"
                                                                            align="center"
                                                                        >
                                                                            Streak Count
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="th"
                                                                            variant="th"
                                                                            className="table-th"
                                                                            align="center"
                                                                        >
                                                                            Start Date
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="th"
                                                                            variant="th"
                                                                            className="table-th"
                                                                            align="center"
                                                                        >
                                                                            End Date
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="th"
                                                                            variant="th"
                                                                            className="table-th"
                                                                            align="center"
                                                                        >
                                                                            Status
                                                                        </Index.TableCell>


                                                                    </Index.TableRow>
                                                                </Index.TableHead>
                                                                <Index.TableBody className="table-body">
                                                                    {!loader ? (
                                                                        pageData?.length > 0 ? (
                                                                            pageData?.map((row, index) => (
                                                                                <Index.TableRow key={row?._id}>
                                                                                    <Index.TableCell
                                                                                        component="td"
                                                                                        variant="td"
                                                                                        scope="row"
                                                                                        className="table-td"
                                                                                        align="center"
                                                                                    >
                                                                                        {row?.sNo}
                                                                                    </Index.TableCell>

                                                                                    <Index.TableCell
                                                                                        component="td"
                                                                                        variant="td"
                                                                                        scope="row"
                                                                                        className="table-td"
                                                                                        align="center"
                                                                                    >
                                                                                        {/* {row?.StreakCount} */}
                                                                                        {row?.StreakCount
                                                                                            ? Number.isInteger(row?.StreakCount)
                                                                                                ? numeral(row?.StreakCount).format('0')
                                                                                                : numeral(row?.StreakCount).format('0.000a')
                                                                                            : 0}
                                                                                    </Index.TableCell>
                                                                                    <Index.TableCell
                                                                                        component="td"
                                                                                        variant="td"
                                                                                        scope="row"
                                                                                        className="table-td"
                                                                                        align="center"
                                                                                    >
                                                                                        {row?.startDate
                                                                                            ? moment(row?.startDate).format(
                                                                                                "DD/MM/YYYY"
                                                                                            )
                                                                                            : "-"}
                                                                                    </Index.TableCell>
                                                                                    <Index.TableCell
                                                                                        component="td"
                                                                                        variant="td"
                                                                                        scope="row"
                                                                                        className="table-td"
                                                                                        align="center"
                                                                                    >
                                                                                        {row?.endDate
                                                                                            ? moment(row?.endDate).format(
                                                                                                "DD/MM/YYYY"
                                                                                            )
                                                                                            : "-"}
                                                                                    </Index.TableCell>
                                                                                    <Index.TableCell
                                                                                        component="td"
                                                                                        variant="td"
                                                                                        scope="row"
                                                                                        className="table-td"
                                                                                        align="center"
                                                                                    >
                                                                                        {row?.isStreakBreak ? 'Break' : "Active"}
                                                                                    </Index.TableCell>

                                                                                </Index.TableRow>
                                                                            ))
                                                                        ) : (
                                                                            <PageIndex.RecordNotFound colSpan={5} />
                                                                        )
                                                                    ) : (
                                                                        <PageIndex.TableLoader colSpan={5} />
                                                                    )}
                                                                </Index.TableBody>
                                                            </Index.Table>
                                                        </Index.TableContainer>
                                                    </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="pagination-costom">
                                                    <PageIndex.Pagination
                                                        fetchData={filterData}
                                                        setPageData={setPageData}
                                                        pageData={pageData}
                                                        search={search}
                                                    />
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    );
};

export default StreakHistory;
