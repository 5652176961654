import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import {
  singleUserDetails,
  suspendUser,
} from "../../../../redux/slices/adminService";
import moment from "moment/moment";
import dayjs from "dayjs";
import numeral from "numeral";

const StreakDetails = () => {
  const location = PageIndex.useLocation();
  let navigate = PageIndex.useNavigate();
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [loader, setLoader] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [streakList, setStreakList] = useState([]);
  const [search, setSearch] = useState("");
  const [userDetail, setUserDetail] = useState();
  const [openSuspend, setOpenSuspend] = useState(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const [dateFilterData, setDateFilterData] = useState([]);
  //Fetch Post Report List
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const userId = location?.state?.data;

  const getStreakDetail = () => {
    setLoader(true);
    singleUserDetails({ to: userId }).then((data) => {
      if (data?.status == 200) {
        setLoader(false);
        setStreakList(data?.data);
        setUserDetail(data?.data?.streaks[0]?.to);
        // setFilterData(data?.data);
      } else {
        setStreakList([]);
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    getStreakDetail();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const filteredData = streakList?.streaks?.filter((item) => {
      const startDate = item?.lastStreakCount?.startDate;
      const endDate = item?.lastStreakCount?.endDate;
      const selectedStart = moment(selectedFromDate?.$d).format("YYYY/MM/DD");
      const selectedEnd = moment(selectedToDate?.$d).format("YYYY/MM/DD");
      return selectedFromDate && selectedToDate
        ? startDate >= selectedStart && endDate <= selectedEnd
        : item;
    });
    setFilterData(filteredData);
  }, [selectedFromDate, selectedToDate, streakList]);

  useEffect(() => {
    const filterData = streakList?.streaks?.filter((item) => {
      return search
        ? item?.lastStreakCount?.StreakCount?.toString()
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        item?.from?.name
          ?.toString()
          ?.toLowerCase()
          ?.includes(search?.toLowerCase()) ||
        new Date(item?.lastStreakCount?.startDate)
          ?.toLocaleDateString("en-GB")
          ?.toString()
          .includes(search?.toLowerCase()) ||
        new Date(item?.lastStreakCount?.endDate)
          ?.toLocaleDateString("en-GB")
          ?.toString()
          .includes(search?.toLowerCase())
        : item;
    });
    setFilterData(filterData);
  }, [search, streakList]);

  // suspend user
  const handleOpenSuspend = (type) => {
    setOpenSuspend(true);
  };
  const handleCloseSuspend = () => setOpenSuspend(false);
  const suspendUserByAdmin = () => {
    setIsDeleteButtonDisabled(true);
    suspendUser(streakList?._id).then((res) => {
      setIsDeleteButtonDisabled(false);
      getStreakDetail();
      if (res?.status === 200) {
        handleCloseSuspend();
      } else {
        setTimeout(() => {
          setIsDeleteButtonDisabled(false);
        }, 3000);
      }
    });
  };

  const handleClearFromDate = () => {
    setSelectedFromDate(null);
  };
  const handleClearToDate = () => {
    setSelectedToDate(null);
  };

  ////////////////////////////////////////////////

  return (
    <>
      <Index.Box className="user-details-back-btn">
        <PageIndex.BackButton
          onClick={() => navigate("/dashboard/streak-list")}
        />
      </Index.Box>
      <Index.Box className="user-detail-section">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row-add-user"
            gridTemplateColumns="repeat(12, 1fr)"
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 3",
              }}
              className="grid-column"
            >
              <Index.Box className="left-wrape">
                <Index.Box className="profile-wrape">
                  <Index.Box className="image-wrape">
                    <img
                      src={
                        userDetail?.profile
                          ? userDetail?.profile instanceof Blob
                            ? URL.createObjectURL(userDetail?.profile)
                            : `${imageUrl}${userDetail?.profile}`
                          : PageIndex?.Jpg?.dummyImage
                      }
                      className="user-post-post-img"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="detail">
                  <label>Name</label>
                  <p> {userDetail?.name ? userDetail?.name : "-"}</p>
                  <label> Email</label>
                  <p> {userDetail?.email ? userDetail?.email : "-"}</p>
                  <label>Username</label>
                  <p>
                    <span
                      className="cus-user-name-navigate"
                      onClick={() => {
                        navigate("/dashboard/user-view", {
                          state: {
                            data: userId,
                            pathName: location.pathname,
                          },
                        });
                      }}
                    >
                      {userDetail?.userName ? userDetail?.userName : "-"}
                    </span>
                  </p>
                  <label>Phone Number</label>
                  <p>
                    {userDetail?.phoneNumber
                      ? `${userDetail?.countryCode} ${userDetail?.phoneNumber}`
                      : "-"}
                  </p>
                  <label>Country</label>
                  <p> {userDetail?.country ? userDetail?.country : "-"}</p>
                  <label>Bio</label>
                  <p>
                    {userDetail?.Bio && userDetail?.Bio?.length > 25 ? (
                      <PageIndex.LightTooltip
                        placement="right"
                        title={userDetail?.Bio}
                      >
                        {userDetail?.Bio.slice(0, 25) + "..."}
                      </PageIndex.LightTooltip>
                    ) : (
                      userDetail?.Bio || "-"
                    )}
                  </p>
                  <label>Suspend</label>
                  <p>{userDetail?.isSuspended === true ? "Yes" : "No"}</p>
                  <label>Links</label>
                  <p>{userDetail?.links ? userDetail?.links : "-"}</p>
                </Index.Box>
                <PageIndex.LightTooltip title="Suspend">
                  <Index.IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    disabled={userDetail?.isSuspended === true}
                    onClick={() => {
                      handleOpenSuspend();
                    }}
                  >
                    <img
                      src={Index.Png.blockUser}
                      className="dash-icons"
                      alt="dashboard icon"
                    />
                  </Index.IconButton>
                </PageIndex.LightTooltip>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 9",
              }}
              className="grid-column"
            >
              <Index.Box className="right-wrape">
                <Index.Box className="table-wrape">
                  <Index.Box className="external-tab-box">
                    <Index.Box className="barge-common-box">
                      <Index.Box className="common-box">
                        <Index.Box className="user-list-flex">
                          <Index.Box className="admin-page-title-main">
                            <Index.Typography
                              className="admin-page-title tittle-like-costam"
                              component="h2"
                              variant="h2"
                            >
                              Streak Details
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="userlist-btn-flex">
                          <>
                            <Index.Box className="admin-text-field-main from-input mb-0">
                              <Index.Box className="input-box input-box-admin input-box-admin2">
                                <Index.Box className="form-group transaction-fieldset">
                                  <Index.LocalizationProvider
                                    dateAdapter={Index.AdapterDayjs}
                                  >
                                    <Index.InputLabel
                                      className="form-lable"
                                      id="demo-simple-label"
                                    >
                                      From
                                    </Index.InputLabel>
                                    <Index.Stack className="date-picker-mui cus-datepicker">
                                      <Index.MobileDatePicker
                                        className="form-control datepicker"
                                        format="DD-MM-YYYY"
                                        value={selectedFromDate}
                                        disableFuture
                                        maxDate={selectedToDate}
                                        onChange={(newValue) => {
                                          setSelectedFromDate(newValue);
                                        }}
                                        renderInput={(params) => (
                                          <Index.TextField {...params} />
                                        )}
                                      />
                                      {selectedFromDate ? (
                                        <Index.IconButton
                                          className="clear-icon"
                                          onClick={handleClearFromDate}
                                        >
                                          <Index.ClearIcon className="icon-clear" />
                                        </Index.IconButton>
                                      ) : (
                                        ""
                                      )}
                                    </Index.Stack>
                                  </Index.LocalizationProvider>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="admin-text-field-main from-input mb-0">
                              <Index.Box className="input-box input-box-admin input-box-admin2">
                                <Index.Box className="form-group transaction-fieldset">
                                  <Index.LocalizationProvider
                                    dateAdapter={Index.AdapterDayjs}
                                  >
                                    <Index.InputLabel
                                      className="form-lable"
                                      id="demo-simple-label"
                                    >
                                      To
                                    </Index.InputLabel>
                                    <Index.Stack className="sale-field date-picker-mui cus-datepicker">
                                      <Index.MobileDatePicker
                                        className=" form-control datepicker"
                                        format="DD-MM-YYYY"
                                        value={selectedToDate}
                                        disableFuture
                                        minDate={selectedFromDate}
                                        onChange={(newValue) => {
                                          setSelectedToDate(newValue);
                                        }}
                                        renderInput={(params) => (
                                          <Index.TextField {...params} />
                                        )}
                                      />
                                      {selectedToDate ? (
                                        <Index.IconButton
                                          className="clear-icon"
                                          onClick={handleClearToDate}
                                        >
                                          <Index.ClearIcon className="icon-clear" />
                                        </Index.IconButton>
                                      ) : (
                                        ""
                                      )}
                                    </Index.Stack>
                                  </Index.LocalizationProvider>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </>

                          <Index.Box className="user-search-main contest-winner-list-search">
                            <Index.Box className="user-search-box">
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder="Search user"
                                  onChange={handleSearch}
                                />
                                <span className="search-icon-box">
                                  <img
                                    src={Index.Svg.search}
                                    className="search-icon"
                                  />
                                </span>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="admin-dash-box">
                          <Index.Box className="page-table-main streak-detail-table action-column">
                            <Index.TableContainer
                              component={Index.Paper}
                              className="table-container"
                            >
                              <Index.Table
                                stickyHeader
                                aria-label="simple table" 
                                className="table"
                              >
                                <Index.TableHead className="table-head cus-table-head">
                                  <Index.TableRow className="table-row">
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      S.No.
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Username
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Streak Count
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Start Date
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      End Date
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Status
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Actions
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </Index.TableHead>
                                <Index.TableBody className="table-body">
                                  {!loader ? (
                                    pageData?.length > 0 ? (
                                      pageData?.map((row, index) => (
                                        <Index.TableRow key={row?._id}>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {row?.sNo}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {row?.from?.name}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {/* {row?.lastStreakCount?.StreakCount
                                              ? row?.lastStreakCount
                                                  ?.StreakCount
                                              : 0} */}
                                            {row?.lastStreakCount?.StreakCount
                                              ? Number.isInteger(row?.lastStreakCount?.StreakCount)
                                                ? numeral(row?.lastStreakCount?.StreakCount).format('0')
                                                : numeral(row?.lastStreakCount?.StreakCount).format('0.000a')
                                              : 0}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {row?.lastStreakCount?.startDate
                                              ? moment(
                                                row?.lastStreakCount
                                                  ?.startDate
                                              ).format("DD/MM/YYYY")
                                              : "-"}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {row?.lastStreakCount?.endDate
                                              ? moment(
                                                row?.lastStreakCount?.endDate
                                              ).format("DD/MM/YYYY")
                                              : "-"}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {!row?.lastStreakCount?.StreakCount
                                              ? "Ongoing"
                                              : row?.lastStreakCount
                                                ?.isStreakBreak == true
                                                ? "Break"
                                                : "Active"}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            <Index.Box className="userdata-btn-flex">
                                              {(rolePermission &&
                                                rolePermission?.roleType?.rolePermission?.includes(
                                                  "NotificationList_view"
                                                )) ||
                                                (rolePermission &&
                                                  rolePermission?.isAdmin ===
                                                  true) ? (
                                                <>
                                                  <PageIndex.LightTooltip title="View">
                                                    <Index.IconButton
                                                      color="primary"
                                                      // title="View"
                                                      aria-label="upload picture"
                                                      component="label"
                                                      onClick={() =>
                                                        navigate(
                                                          `/dashboard/streak-history`,
                                                          {
                                                            state: {
                                                              row,
                                                              type: "view",
                                                            },
                                                          }
                                                        )
                                                      }
                                                    >
                                                      <Index.RemoveRedEyeIcon />
                                                    </Index.IconButton>
                                                  </PageIndex.LightTooltip>
                                                  <PageIndex.LightTooltip title="View Chat">
                                                    <Index.IconButton
                                                      className="show-chat-button"
                                                      onClick={() =>
                                                        navigate(
                                                          `/dashboard/streak-chat`,
                                                          {
                                                            state: {
                                                              row,
                                                              type: "view",
                                                            },
                                                          }
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        src={
                                                          PageIndex.Png.showChat
                                                        }
                                                      />
                                                    </Index.IconButton>
                                                  </PageIndex.LightTooltip>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </Index.Box>
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      ))
                                    ) : (
                                      <PageIndex.RecordNotFound colSpan={7} />
                                    )
                                  ) : (
                                    <PageIndex.TableLoader colSpan={7} />
                                  )}
                                </Index.TableBody>
                              </Index.Table>
                            </Index.TableContainer>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="pagination-costom">
                          <PageIndex.Pagination
                            fetchData={filterData}
                            setPageData={setPageData}
                            pageData={pageData}
                            search={search}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.SuspendModal
        openSuspend={openSuspend}
        handleCloseSuspend={handleCloseSuspend}
        handleSuspendRecord={suspendUserByAdmin}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
    </>
  );
};

export default StreakDetails;
