import React, { useEffect, useState } from "react";

import Index from "../../container/Index";
import PageIndex from "../../container/PageIndex";
import {
  addEditEmailContent,
  getEmailContent,
} from "../../redux/slices/adminService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export const SendMailModel = ({
  openSendMailModel,
  handleCloseSendMail,
  setIsDeleteButtonDisabled,
  isDeleteButtonDisabled,
  mailModelHeading,
  mailModelType,
  modelType,
  suspendUserByAdmin,
  deletePost,
  handleSendMail,
}) => {

  const [emailContent, setEmailContent] = useState({});
  const initialValues = {
    content: emailContent?.content || "",
  };

  const handleSubmit = (values) => {
    setIsDeleteButtonDisabled(true);
    const data = {
      type: `${mailModelType}_${modelType}`,
      content: values.content,
      ...(emailContent && { id: emailContent._id }),
    };
    addEditEmailContent(data)
      .then((res) => {
        setIsDeleteButtonDisabled(false);
        setEmailContent({})
        handleCloseSendMail();
        if (modelType == "Warning") {
          handleSendMail();
        } else if (modelType == "Delete") {
          deletePost();
        } else {
          suspendUserByAdmin();
        }
      })
      .catch((err) => {
        setIsDeleteButtonDisabled(false);
        console.log(err);
      });
  };

  const getEmailContentApi = () => {
    const type = `${mailModelType}_${modelType}`;
    getEmailContent(type)
      .then((res) => {
        setEmailContent(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (modelType)
      getEmailContentApi();
  }, [modelType, openSendMailModel]);

  return (
    <>
    
      <Index.Modal
        open={openSendMailModel}
        // onClose={handleCloseSendMail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner ">
          <Index.Box
            onClick={handleCloseSendMail}
            className="modal-circle-main cus-modal-margin"
          >
            <Index.IconButton>
              <img src={Index.Png.close} className="user-circle-img" />
            </Index.IconButton>
          </Index.Box>
          <PageIndex.Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={PageIndex.emailContentSchema}
          >
            {({ values, handleBlur, errors, touched, setFieldValue }) => (
              <PageIndex.Form>
                {/* <Index.Typography
                  className="delete-modal-title  setting-modal email-content-heading"
                  component="h2"
                  variant="h3"
                >
                  {mailModelHeading} Content
                </Index.Typography> */}
                <Index.Typography
                  className="delete-modal-title"
                  component="h2"
                  variant="h2"
                >
                  Are you sure?
                </Index.Typography>
                <Index.Typography
                  className="delete-modal-para common-para"
                  component="p"
                  variant="p"
                >
                  {mailModelHeading}
                </Index.Typography>

                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable form-lable-mail">
                    Mail Content <span className="mandatory-asterisk">*</span>
                  </Index.FormHelperText>

                  <Index.Textarea
                    name="content"
                    value={values?.content}
                    onChange={(e) => setFieldValue("content", e.target.value)}
                    minRows={3}
                    onBlur={handleBlur}
                    placeholder="Write Content here..."
                  />
                  {errors?.content && touched?.content && (
                    <p className="custom-error-tag">{errors?.content}</p>
                  )}
                </Index.Box>

                <Index.Box className="delete-modal-btn-flex">
                  <Index.Button
                    className="modal-cancel-btn modal-btn"
                    onClick={handleCloseSendMail}
                  >
                    Cancel
                  </Index.Button>

                  <Index.Button
                    type="submit"
                    className="modal-delete-btn modal-btn"
                    disabled={isDeleteButtonDisabled}
                  >
                    {isDeleteButtonDisabled ? (
                      <PageIndex.ButtonLoader
                        color="white"
                        size={14}
                        loading={isDeleteButtonDisabled}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Index.Button>
                </Index.Box>
              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      </Index.Modal>
    </>
  );
};
