import React from "react";
import PageIndex from "../../container/PageIndex";
import Index from "../../container/Index";
import { tokenLogo } from "../../config/dataService";
import numeral from "numeral";
import { getAssestsList } from "../../redux/slices/adminService";
const CurrencyTypeList = [
  { id: "Fiat", value: "Fiat" },
  { id: "Crypto", value: "Crypto" },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const DepositCurrencyModel = ({
  assestsList,
  open,
  handleClose,
  initialValues,
  schema,
  isDeleteButtonDisabled,
  singleCrypto,
  handleSubmit,
  placeholder,
  title,
  fieldName,
  isView,
  liveCryptoList,
  currencyList,
}) => {
  return (
    <>
      <Index.Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-add modal"
      >
        <Index.Box sx={style} className="add-modal-inner-main modal-inner ">
          <Index.Box className="modal-circle-main cus-modal-cicle-main">
            <Index.IconButton onClick={handleClose}>
              <img src={Index.Png.close} className="user-circle-img" />
            </Index.IconButton>
          </Index.Box>
          <PageIndex.Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            {({
              values,
              handleBlur,
              handleChange,
              errors,
              touched,
              setFieldValue,
            }) => (
              <PageIndex.Form>
                {console.log("Valuessssss", values)}

                <Index.Typography
                  className="add-modal-title"
                  component="h2"
                  variant="h2"
                >
                  Deposit Currency To User
                  {/* {values?.cryptoName ? "Edit" : "Add"} */}
                </Index.Typography>
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row-add-user"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Currency Type
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.FormControl className="radio-main">
                              <Index.Select
                                className="form-control select-drop-list"
                                name="currencyType"
                                value={values?.currencyType}
                                disabled
                                defaultValue={
                                  values ? values?.currencyType : ""
                                }
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue("currencyType", e.target.value);
                                }}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                              >
                                <Index.MenuItem
                                  value=""
                                  // disabled
                                  className="menuitem"
                                >
                                  Select Currency Type
                                </Index.MenuItem>
                                {CurrencyTypeList &&
                                  CurrencyTypeList?.map((val) => (
                                    <Index.MenuItem
                                      value={val?.value}
                                      key={val?.id}
                                      className="menuitem"
                                    >
                                      {val?.value}
                                    </Index.MenuItem>
                                  ))}
                              </Index.Select>
                            </Index.FormControl>
                            {errors.chainType && touched.chainType && (
                              <p className="error-text">{errors.chainType}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      {values?.currency === "Fiat" ||
                      values?.currencyType === "Fiat" ? (
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Currency
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.FormControl className="radio-main">
                                <Index.Select
                                  className="form-control select-drop-list"
                                  name="currency"
                                  value={values?.currency}
                                  defaultValue={values ? values?.currency : ""}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue("currency", e.target.value);
                                  }}
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Without label",
                                  }}
                                >
                                  <Index.MenuItem
                                    value=""
                                    // disabled
                                    className="menuitem"
                                  >
                                    Select Currency
                                  </Index.MenuItem>
                                  {currencyList &&
                                    currencyList?.map((val) => (
                                      <Index.MenuItem
                                        value={val?._id}
                                        key={val?._id}
                                        className="menuitem"
                                      >
                                        {val?.coins}
                                      </Index.MenuItem>
                                    ))}
                                </Index.Select>
                              </Index.FormControl>
                              {errors.chainType && touched.chainType && (
                                <p className="error-text">{errors.chainType}</p>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      ) : (
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Currency
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.FormControl className="radio-main">
                                <Index.Select
                                  className="form-control select-drop-list"
                                  name="cryptoCoinType"
                                  value={values?.cryptoCoinType}
                                  defaultValue={
                                    values ? values?.cryptoCoinType : ""
                                  }
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "cryptoCoinType",
                                      e.target.value
                                    );
                                  }}
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Without label",
                                  }}
                                >
                                  <Index.MenuItem
                                    value=""
                                    // disabled
                                    className="menuitem"
                                  >
                                    Select Currency
                                  </Index.MenuItem>
                                  {currencyList &&
                                    currencyList?.map((val) => (
                                      <Index.MenuItem
                                        value={val?._id}
                                        key={val?._id}
                                        className="menuitem"
                                      >
                                        {val?.tokenName}
                                      </Index.MenuItem>
                                    ))}
                                </Index.Select>
                              </Index.FormControl>
                              {errors.chainType && touched.chainType && (
                                <p className="error-text">{errors.chainType}</p>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      )}
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Amount
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Amount"
                              name="amount"
                              value={values.amount}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  .replace(/[^0-9a-zA-Z@#$]+/g, "")
                                  .replace(/\s\s+/g, " ");
                                if (newValue.replace(/\s/g, "").length <= 20) {
                                  setFieldValue("amount", newValue);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.tokenSymbol
                                  ? touched?.tokenSymbol
                                  : undefined
                              )}
                              helperText={
                                touched?.tokenSymbol
                                  ? errors?.tokenSymbol
                                  : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                {!isView ? (
                  <Index.Box className="delete-modal-btn-flex">
                    <Index.Button
                      className="modal-cancel-btn modal-btn"
                      onClick={handleClose}
                    >
                      Cancel
                    </Index.Button>
                    <Index.Button
                      type="submit"
                      className="modal-delete-btn modal-btn"
                    >
                      Update
                    </Index.Button>
                  </Index.Box>
                ) : (
                  ""
                )}
              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default DepositCurrencyModel;
