import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import DataService, { dataService } from "../config/dataService";
import { useEffect } from "react";
import { autoLogout, resendOTP } from "../redux/slices/adminService";
import { logout, updateRolePermission } from "../redux/slices/AdminSlice";
import PageIndex from "../container/PageIndex";

const PrivateRoutes = () => {
  const { token } = useSelector((state) => state.admin);
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = PageIndex.useDispatch()

  const isAuthenticate = (token) => {
    if (!token) return false;
    DataService.defaults.headers.common.auth = token;
    return true;
  };


  useEffect(() => {
    autoLogout(token).then((res) => {
      if (res?.data?.status == 200) {
        dispatch(updateRolePermission(res?.data?.data));
      }
      else if (res?.response?.data?.status == 401) {
        dispatch(logout());
        navigate("/");
        // PageIndex.toast.error(res?.response?.data?.message, { autoClose: 2000 });
      }
    })
  }, [location])

  return isAuthenticate(token) ? <Outlet /> : <Navigate to="/" replace />;
};

export default PrivateRoutes;
