import React, { useEffect, useState } from 'react'
import { getStreakChat } from '../../../../redux/slices/adminService'
import PageIndex from '../../../PageIndex';
import Index from '../../../Index';
import { imageUrl } from '../../../../config/dataService';
import moment from 'moment';
const StreakChat = () => {
    const location = PageIndex.useLocation();
    const navigate = PageIndex.useNavigate()
    const [loader, setLoader] = useState(false)
    const [chatData, setChatData] = useState([])
    const { row } = location?.state
    const data = {
        userId1: row?.from?._id,
        userId2: row?.to?._id
    }
    let props
    const getChat = (() => {
        setLoader(true)
        getStreakChat(data).then((res) => {

            setChatData(res?.data[0]?.Streak)
            setLoader(false)
        })
    })

    useEffect(() => {
        getChat()
    }, [])

    return (



        <Index.Box className="dashboard-content">
              <PageIndex.BackButton onClick={() => navigate("/dashboard/streak-details" , {
                                                            state: {
                                                              data:row?.to?._id ,
                                                              type: "view",
                                                            },
                                                          })} />
            <Index.Box className="barge-common-box">
                <Index.Box className="common-box">
                    <Index.Box className="user-list-flex">
                        <Index.Box className="admin-page-title-main">
                            <Index.Typography
                                className="admin-page-title"
                                component="h2"
                                variant="h2"
                            >
                                {row?.to?.name} and {row?.from?.name} Streaks 
                            </Index.Typography>
                        </Index.Box>
                        {/* <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                    // name="search"
                    value={search}
                    autoComplete="search"
                    onChange={(e) => {
                      const newValue = e.target.value
                        .replace(/^\s+/, "")
                        .replace(/\s\s+/g, " ");
                      setCurrentPage(1);
                      setSearch(newValue);
                    }}
                  />
                  <span className="search-icon-box">
                    <img src={Index.Svg.search} className="search-icon" />
                  </span>
                </Index.Box>
              </Index.Box>
            </Index.Box>
           
          </Index.Box> */}
                    </Index.Box>
                    <Index.Box className="admin-dashboard-list-row">
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                            <Index.Box
                                display="grid"
                                className="display-row-userlist"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            >
                                <Index.Box
                                    gridColumn={{
                                        xs: "span 12",
                                        sm: "span 12",
                                        md: "span 12",
                                        lg: "span 12",
                                    }}
                                    className="grid-column"
                                >

                                    <Index.Box className="common-box flex-common-box chat-overflow">
                                        {chatData?.length > 0 ? (
                                            chatData?.map((cmt) => (
                                                <Index.Box key={cmt?._id} className="comment-box-wrap">
                                                    <Index.Box className="user-image-box">
                                                        <img
                                                            src={
                                                                cmt?.from?.profile
                                                                    ? cmt?.from?.profile instanceof Blob
                                                                        ? URL.createObjectURL(cmt?.from?.profile)
                                                                        : `${imageUrl}${cmt?.from?.profile}`
                                                                    : PageIndex?.Jpg?.dummyImage
                                                            }
                                                            className="user-post-user-img"
                                                        />
                                                    </Index.Box>
                                                    <Index.Box className="comment-box chat-box-main">
                                                        <Index.Box className="user-name-box">
                                                            <Index.Typography className="chat-user-name "
                                                            >

                                                                {cmt?.from?.name}
                                                            </Index.Typography>
                                                            <Index.Typography className="comment-date-text">
                                                                {/* {moment(cmt.createdAt).format(
                                                                    "DD/MM/YYYY hh:mm A"
                                                                )} */}
                                                                {cmt?.date}&nbsp;{cmt?.time}

                                                            </Index.Typography>
                                                        </Index.Box>

                                                        <Index.Box className='chat-image-box'>
                                                            <img className='chat-image' src={`${imageUrl}${cmt?.media}`} />
                                                        </Index.Box>

                                                    </Index.Box>
                                                </Index.Box>
                                            ))) : <Index.Box className="no-comment-box"> <Index.Box className="table-data-not-found chat-data-no-found">Record not found</Index.Box></Index.Box>}
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>

                </Index.Box>
            </Index.Box>


        </Index.Box>




    )
}

export default StreakChat