import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import { getAllReelReportsList } from "../../../../redux/slices/adminService";
import { debounce } from "lodash";

import ReactPlayer from "react-player";
import numeral from "numeral";

const UserReelReport = ({ today, setBtnValue, btnValue }) => {
  const typeList = [
    { id: 1, value: "today", key: "Today" },
    { id: 2, value: "overview", key: "Overview" },
  ];

  const params = PageIndex.useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const stateParam = queryParams.get("state");
  const state = JSON.parse(decodeURIComponent(stateParam));

  let navigate = PageIndex.useNavigate();
  const [loader, setLoader] = useState(true);
  const [reelReportist, setReelReportist] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [type, setType] = useState(state?.today ? state?.today : "overview");
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  //Fetch Post Report List

  const handleType = (e) => {
    setType(e.target.value);
    navigate({ state: "" });
    setCurrentPage(1);
  };

  const fetchReelReportList = () => {
    const page = currentPage;
    getAllReelReportsList(page, search, type).then((data) => {
      if (data?.status == 200) {
        setLoader(false);
        setReelReportist(data?.data);
        if (data?.currentPage) {
          setCurrentPage(data?.currentPage);
        }
        setPageCount(data?.totalCount ? data?.totalCount : 0);
      } else {
        setReelReportist([]);
        setLoader(false);
      }
    });
  };
  useEffect(() => {
    const debouncedFetch = debounce(fetchReelReportList, 300);

    if (search || currentPage) {
      debouncedFetch();
    } else {
      fetchReelReportList();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage, type]);

  const handleVideoClick = (index, id) => {
    if (index === currentVideoIndex && id === currentVideoId) {
      setCurrentVideoIndex(null);
      setCurrentVideoId(null);
    } else {
      setCurrentVideoIndex(index);
      setCurrentVideoId(id);
    }
  };

  useEffect(() => {
    if (params?.state?.tabNo) {
      setBtnValue(params?.state?.tabNo);
    }
  }, [params?.state?.tabNo]);


  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <Index.Box className="barge-common-box">
      <Index.Box className="common-box">
        <Index.Box className="user-list-flex">
          <Index.Box className="admin-page-title-main">
            <Index.Typography
              className="admin-page-title tittle-like-costam"
              component="h2"
              variant="h2"
            >
              Cryp Reports
              {/* Reel Reports */}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="input-box add-user-input request-input-box">
              <Index.Box className="form-group">
                <Index.FormControl className="request-dropdown">
                  <Index.Select
                    className="form-control select-drop-list request-drop-list"
                    name="type"
                    value={type}
                    onChange={handleType}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    {typeList &&
                      typeList?.map((val) => (
                        <Index.MenuItem
                          value={val?.value}
                          key={val?.id}
                          className="menuitem"
                        >
                          {val?.key}
                        </Index.MenuItem>
                      ))}
                  </Index.Select>
                </Index.FormControl>
              </Index.Box>
            </Index.Box>
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => {
                      const newValue = e.target.value
                        .replace(/^\s+/, "")
                        .replace(/\s\s+/g, " ");
                      setCurrentPage(1);
                      setSearch(newValue);
                      // debouncedSetSearch(newValue);
                    }}
                  />
                  <span className="search-icon-box">
                    <img src={Index.Svg.search} className="search-icon" />
                  </span>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dash-box">
          <Index.Box className="page-table-main like-table-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="simple table"
                className="table"
              >
                <Index.TableHead className="table-head cus-table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      S.No.
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      {/* Reel */}
                      Cryp
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Name
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Username
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Report Count
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {!loader ? (
                    reelReportist?.length > 0 ? (
                      reelReportist?.map((row, index) => (
                        <Index.TableRow key={row?._id}>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {(currentPage - 1) * 10 + index + 1}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            <Index.Box className="cus-slider ">
                              <Index.Box className="video-swiper">
                                <ReactPlayer
                                  component="video"
                                  controls
                                  width={150}
                                  height={65}
                                  className="react-player"
                                  url={`${imageUrl}${row?.reelId?.media}`}
                                  playing={row._id === currentVideoId}
                                  onPlay={() => handleVideoClick(row._id)}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {row?.reelId ? row?.reelId?.createdBy?.name : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            <span
                              className="cus-user-name-navigate"
                              onClick={() => {
                                navigate("/dashboard/user-view", {
                                  state: {
                                    data: row?.reelId?.createdBy?._id,
                                    pathName: params.pathname,
                                  },
                                });
                              }}
                            >
                              {row?.reelId
                                ? row?.reelId?.createdBy?.userName
                                : "-"}
                            </span>
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {/* {row?.reelId?.reportersId?.length > 0
                                                            ? row?.reelId?.reportersId?.length
                                                            : "-"} */}
                            {row?.reelId?.reportersId?.length > 0
                              ? Number.isInteger(
                                  row?.reelId?.reportersId?.length
                                )
                                ? numeral(
                                    row?.reelId?.reportersId?.length
                                  ).format("0")
                                : numeral(
                                    row?.reelId?.reportersId?.length
                                  ).format("0.000a")
                              : 0}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            <Index.Box className="userdata-btn-flex">
                              <PageIndex.LightTooltip title="View">
                                <Index.IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="label"
                                  onClick={() => {
                                    navigate("/dashboard/view-reel-report", {
                                      state: {
                                        data: row,
                                        postId: row?.reelId?._id,
                                        postKey: 0,
                                      },
                                    });
                                  }}
                                >
                                  <Index.RemoveRedEyeIcon />
                                </Index.IconButton>
                              </PageIndex.LightTooltip>
                            </Index.Box>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <PageIndex.RecordNotFound colSpan={6} />
                    )
                  ) : (
                    <PageIndex.TableLoader colSpan={6} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
        {reelReportist?.length ? (
          <Index.Box className="pagination-main">
          <Index.TablePagination
            rowsPerPageOptions={[
              { label: "All", value: -1 },
              10,
              25,
              50,
              75,
              100,
            ]}
            count={pageCount}
            rowsPerPage={rowsPerPage}
            page={currentPage - 1}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
            variant="outlined"
            shape="rounded"
            className="pagination"
          />
        </Index.Box>
        ) : (
          ""
        )}
      </Index.Box>
    </Index.Box>
  );
};
export default UserReelReport;
