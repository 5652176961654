import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { getSingleUserFeedback } from "../../../../redux/slices/adminService";
import moment from "moment";

const SingleUserFeedbackList = () => {
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const userId = location?.state?.data

  const [loader, setLoader] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [search, setSearch] = useState("");
  const [userFeedbackList, setUserFeedbackList] = useState({});
  const listOfUserFeedback = () => {
    getSingleUserFeedback({ userId: userId }).then((res) => {
      if (res?.status === 200) {
        setLoader(false);
        setUserFeedbackList(res?.data);
        setFilterData(res?.data?.feedBackData?.reverse());
      } else {
        setUserFeedbackList([]);
      }
    });
  };
  useEffect(() => {
    const filterData = userFeedbackList?.feedBackData?.filter((item) => {
    
      return search
        ? item?.message?.toLowerCase()?.includes(search.toLowerCase()) ||
        moment(item?.dateTime)
          .format("DD/MM/YYYY hh:mm A")
          .toLowerCase()
          .includes(search.toLowerCase())
        : item;
    });
    setFilterData(filterData);
  }, [search, userFeedbackList]);

  useEffect(() => {
    listOfUserFeedback();
  }, []);

  return (
    <>
      <Index.Box className="dashboard-content">
        <PageIndex.BackButton onClick={() => navigate("/dashboard/users-feedback-list")} />
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Feedbacks
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search"
                        // name="search"
                        autoComplete="search"
                        value={search}
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setSearch(newValue);
                        }}
                      />
                      <span className="search-icon-box">
                        <img src={Index.Svg.search} className="search-icon" />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="single-user-feedback-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head cus-table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  S.No
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Username
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Feedbacks
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Date
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Time
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {!loader ? (
                                pageData?.length > 0 ? (
                                  pageData?.map((row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row.sNo}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {userFeedbackList?.userId?.name
                                          ? userFeedbackList?.userId?.name
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                        >
                                        <span  className="cus-user-name-navigate"
                                        onClick={() => {
                                          navigate('/dashboard/user-view',
                                            {
                                              state: {
                                                data: userFeedbackList?.userId?._id,
                                              
                                                pathName: location.pathname
                                              },
                                            }
                                          )
                                        }}
                                        >{userFeedbackList?.userId?.userName
                                          ? userFeedbackList?.userId?.userName
                                          : "-"}</span>
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      // title={row.message?row?.message:"-"}
                                      >
                                        {row?.message &&
                                          row?.message?.length > 50 ? (
                                          <PageIndex.LightTooltip
                                            placement="top"
                                            title={row?.message}
                                          >
                                            {row?.message.slice(0, 50) + "..."}
                                          </PageIndex.LightTooltip>
                                        ) : (
                                          row?.message || "-"
                                        )}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.dateTime
                                          ? moment(row?.dateTime).format(
                                            "DD/MM/YYYY"
                                          )
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.dateTime
                                          ? moment(row?.dateTime).format(
                                            "hh:mm A"
                                          )
                                          : "-"}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  ))
                                ) : (
                                  <PageIndex.RecordNotFound colSpan={6} />
                                )
                              ) : (
                                <PageIndex.TableLoader colSpan={6} />
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <PageIndex.Pagination
              fetchData={filterData}
              setPageData={setPageData}
              pageData={pageData}
              search={search}
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default SingleUserFeedbackList;
