import React, { useState } from "react";
import Index from "../../../Index";
import {
  addEditNotifiction,
  sendNotifications,
} from "../../../../redux/slices/adminService";
import PageIndex from "../../../PageIndex";

export default function AddNotification() {
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const editData = location?.state?.row;
  const type = location?.state?.type;
  const [loader, setLoader] = useState(false);
  const [saveDraftloader, setSaveDraftLoader] = useState(false);
  const [data, setData] = useState("");

  const typeList = [
    { key: "Streak", value: "streak" },
    { key: "Post", value: "post" },
    { key: "Cryp", value: "reels" },
    { key: "Contest", value: "contest" },
    { key: "Diamond", value: "diamond" },
    { key: "Wallet", value: "wallet" },
  ]
  // const typeList = [
  //   { key: "Streak", value: "streak" },
  //   { key: "Post", value: "post" },
  //   { key: "Reels", value: "reels" },
  //   { key: "Contest", value: "contest" },
  //   { key: "Diamond", value: "diamond" },
  //   { key: "Wallet", value: "wallet" },
  // ]


  const initialValues = {
    title: editData ? editData?.title : "",
    description: editData ? editData?.description : "",
    isEdit: editData ? true : false,
    notificationType: editData ? editData?.notificationType : "",
  };

  const handleSubmit = async (values) => {
    if (data == "save") {
      const urlencoded = new URLSearchParams();
      urlencoded.append("title", values?.title);
      urlencoded.append("description", values?.description);
      urlencoded.append("notificationType", values?.notificationType);
      urlencoded.append("timeDate", new Date());
      if (editData) {
        urlencoded.append("id", editData?._id);
      }

      setSaveDraftLoader(true);
      addEditNotifiction(urlencoded).then((data) => {
        if (data?.status === 200) {
          setSaveDraftLoader(false);
          navigate("/dashboard/notifications-list");
        } else {
          setTimeout(() => {
            setSaveDraftLoader(false);
          }, 3000);
        }
      });
    } else if (data == "send") {
      setLoader(true);
      const urlencoded = new URLSearchParams();
      if (type == "view" || type == "edit") {
        urlencoded.append("title", editData?.title);
        urlencoded.append("description", editData?.description);
        urlencoded.append("notificationType", editData?.notificationType);
        urlencoded.append("timeDate", new Date());
        urlencoded.append("id", editData?._id);
      } else {
        urlencoded.append("title", values?.title);
        urlencoded.append("timeDate", new Date());
        urlencoded.append("description", values?.description);
        urlencoded.append("notificationType", values?.notificationType);
      }
      sendNotifications(urlencoded).then((data) => {
        if (data?.status === 200) {
          setLoader(false);
          navigate("/dashboard/notifications-list");
        } else {
          setTimeout(() => {
            setLoader(false);
          }, 3000);
        }
      });
    }
  };

  return (
    <>
      <Index.Box className="dashboard-content ">
        <PageIndex.BackButton
          onClick={() => navigate("/dashboard/notifications-list")}
        />
        <Index.Box className="barge-common-box">
          {/* <Index.Box className="page-border"> */}

          <Index.Typography
            className="admin-page-title cus-heading"
            component="h2"
            variant="h2"
          >
            {type === "edit" ? "Edit" : type === "view" ? "View" : "Add"}{" "}
            Notification 
          </Index.Typography>
          <PageIndex.Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={PageIndex.addNotificationSchema}
          >
            {({ values, handleBlur, errors, setFieldValue, touched }) => (
              <PageIndex.Form>
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row-add-user"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Title <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Title"
                              name="title"
                              disabled={type === "view"}
                              value={values?.title}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  .replace(/\s\s+/g, " ");
                                setFieldValue("title", newValue);
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.title ? touched?.title : undefined
                              )}
                              helperText={
                                touched?.title ? errors?.title : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                          <Index.FormHelperText className="form-lable">
                            Notification type{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.FormControl className="radio-main">
                              <Index.Select
                                className="form-control select-drop-list cus-diamondtick-main"
                                name="currencyInUsdt"
                                value={values?.notificationType}
                                onChange={(e) => {
                                  setFieldValue(
                                    "notificationType",
                                    e.target.value
                                  );
                                }}
                                disabled={type === "view"}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                              >
                                <Index.MenuItem
                                  value=""
                                  disabled
                                  className="menuitem"
                                >
                                  Select type
                                </Index.MenuItem>
                                {typeList
                                  .sort((a, b) => a.key.localeCompare(b.key))
                                  .map((val) => (
                                    <Index.MenuItem
                                      value={val.value}
                                      key={val.value}
                                      className="menuitem"
                                    >
                                      {val.key}
                                    </Index.MenuItem>
                                  ))}

                              </Index.Select>
                            </Index.FormControl>
                            {errors.notificationType &&
                              touched.notificationType && (
                                <p className="error-text">
                                  {errors.notificationType}
                                </p>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Message{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>

                          <Index.Box className="form-group textarea-container">
                            <Index.TextareaAutosizeed
                              fullWidth
                              id="fullWidth"
                              className="form-control text-autosize"
                              placeholder="Message"
                              value={values.description}
                              disabled={type === "view"}
                              name="description"
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  .replace(/\s\s+/g, " ");
                                setFieldValue("description", newValue);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.description && touched.description && (
                              <p className="error-text">{errors.description}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="user-btn-flex">
                    <Index.Box className="save-btn-main border-btn-main notification-btn">
                      {type !== "view" ? (
                        <Index.Button
                          className="save-user-btn border-btn"
                          type="submit"
                          // onClick={()=>sendNotification("send")}
                          onClick={() => setData("send")}
                          disabled={loader || saveDraftloader}
                        >
                          {loader ? (
                            <PageIndex.ButtonLoader
                              color="white"
                              size={14}
                              loading={loader}
                            />
                          ) : (
                            <>
                              <img
                                src={Index.Svg.save}
                                className="user-save-icon"
                                alt="Save Icon"
                              />
                              Send
                            </>
                          )}
                        </Index.Button>
                      ) : (
                        ""
                      )}

                      {type !== "view" ? (
                        <Index.Button
                          className="save-user-btn border-btn"
                          type="submit"
                          onClick={() => setData("save")}
                          disabled={loader || saveDraftloader}
                        >
                          {saveDraftloader ? (
                            <PageIndex.ButtonLoader
                              color="white"
                              size={14}
                              loading={saveDraftloader}
                            />
                          ) : (
                            <>
                              <img
                                src={Index.Svg.save}
                                className="user-save-icon"
                                alt="Save Icon"
                              />
                              Save As Draft
                            </>
                          )}
                        </Index.Button>
                      ) : (
                        ""
                      )}

                      {!editData?.isSend && (
                        <Index.Button
                          className="save-user-btn border-btn"
                          onClick={() =>
                            navigate("/dashboard/notifications-list")
                          }
                          disabled={loader || saveDraftloader}
                        >
                          <>
                            <img
                              src={Index.Svg.save}
                              className="user-save-icon"
                              alt="Save Icon"
                            />
                            {"Cancel"}
                          </>
                        </Index.Button>
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      </Index.Box>
      {/* </Index.Box> */}
    </>
  );
}
