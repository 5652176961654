import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import DataService, { dataService } from "../config/dataService";

const ForgotPrivateRoutes = () => {
  const { isForgot } = useSelector((state) => state.admin);
 
  const isAuthenticate = (token) => {
    if (!token) return true;
    return false;
  };

  return isAuthenticate(isForgot) ? <Outlet /> : <Navigate to="/" replace />;
};

export default ForgotPrivateRoutes;
