import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import { autoLogout, deletePostReport, getSinglePostByAdmin, suspendUser } from "../../../../redux/slices/adminService";
import moment from "moment/moment";
import ViewUserPostComment from "./ViewUserPostComment";
import { logout, updateRolePermission } from "../../../../redux/slices/AdminSlice";
import numeral from "numeral";


const ViewUserPost = () => {
  const divRef = useRef(null);
  const location = PageIndex.useLocation();
  const singledata = location?.state?.postId;
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [post, setPost] = useState({});
  const [value, setValue] = useState(0);
  const [filterData, setFilterData] = useState();
  const [pageData, setPageData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [suspendUserId, setSuspendUserId] = useState();
  const [openSuspend, setOpenSuspend] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const [imagePath, setImagePath] = useState();
  const [openImage, setOpenImage] = useState(false);
  const { rolePermission, token } = PageIndex.useSelector((state) => state.admin);
  const navigate = PageIndex.useNavigate()
  const dispatch = PageIndex.useDispatch()


  const singleuserdata = () => {
    getSinglePostByAdmin(singledata).then((res) => {
      if (res) {
        setLoader(false);
        setPost(res?.data);
      }
    });
  };

  //three dot functions

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearch("");
    autoLogout(token).then((res) => {
      if (res?.data?.status == 200) {
        dispatch(updateRolePermission(res?.data?.data));
      }
      else if (res?.response?.data?.status == 401) {
        dispatch(logout());
        navigate("/");
      }
    })
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  // suspend user
  const handleOpenSuspend = (type) => {
    setOpenSuspend(true);
    setSuspendUserId(singledata);
  };
  const handleCloseSuspend = () => setOpenSuspend(false);
  const suspendUserByAdmin = () => {
    setIsDeleteButtonDisabled(true);
    suspendUser(post?.createdBy?._id).then((res) => {
      setIsDeleteButtonDisabled(false);
      singleuserdata();
      if (res?.status === 200) {
        handleCloseSuspend();
      } else {
        setTimeout(() => {
          setIsDeleteButtonDisabled(false);
        }, 3000);
      }
    });
  };

  // post delete

  const handleOpenDelete = () => {
    setOpenDelete(true);
    setDeleteId(post?._id);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const deletePost = (id) => {
    setIsDeleteButtonDisabled(true);
    deletePostReport({ id: singledata }).then((res) => {
      handleCloseDelete();
      setIsDeleteButtonDisabled(false)
      if (res?.status === 200) {
        navigate("/dashboard/user-post", {
          state: {
            postKey: 0,
          },
        });
      } else {
        setTimeout(() => {
          setIsDeleteButtonDisabled(false);
        }, 3000);
      }
    });
  };
  const handleOpenImage = (image) => {
    if (image) {
      setOpenImage(true);
      setImagePath(image);
    }
  };

  const handleImageClose = () => {
    setImagePath('')
    setOpenImage(false);
  }

  useEffect(() => {
    singleuserdata();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };


  useEffect(() => {
    let filterData;
    const filterFunction = (item) => {
      // Convert createdAt to a Date object if it's a string
      const createdAtDate = new Date(item?.createdAt);
      const createdAtStr = createdAtDate instanceof Date && !isNaN(createdAtDate)
        ? createdAtDate.toLocaleDateString("en-GB")
        : '';

      return search
        ? (item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
          item?.userName?.toLowerCase()?.includes(search.toLowerCase()) ||
          createdAtStr.includes(search))
        : item;
    };

    if (value === 0) {
      filterData = post?.likes?.filter(filterFunction);
    } else if (value === 1) {
      filterData = post.tagPeople?.filter(filterFunction);
    } else {
      filterData = post?.comments?.filter(filterFunction);
    }

    setFilterData(filterData);

  }, [search, value, post]);




  const handleVideoClick = (row) => {
    if (row === currentVideoId) {
      setCurrentVideoId(null);
    } else {
      if (currentVideoId !== null) {
        setCurrentVideoId(null);
      }

      setCurrentVideoId(row);
    }
  };

  return (
    <>
      <Index.Box className='user-details-back-btn'>

        <PageIndex.BackButton onClick={() => navigate("/dashboard/user-post")} />
      </Index.Box>
      <Index.Box className="user-detail-section">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row-add-user"
            gridTemplateColumns="repeat(12, 1fr)"
          // gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 3",
              }}
              className="grid-column"
            >
              <Index.Box className="left-wrape">
                <Index.Box className="profile-wrape">
                  <Index.Box className="image-wrape"
                    onClick={() => handleOpenImage(post?.createdBy?.profile)}
                  >
                    <img
                      src={
                        post?.createdBy?.profile
                          ?
                          post?.createdBy?.profile instanceof Blob
                            ? URL.createObjectURL(post?.createdBy?.profile)
                            : `${imageUrl}${post?.createdBy?.profile}`
                          : PageIndex?.Jpg?.dummyImage
                      }
                      className="user-post-post-img"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="detail">

                  <label>Full Name</label>
                  <p> {post?.createdBy?.name ? post?.createdBy?.name : "-"}</p>
                  <label> Email</label>
                  <p> {post?.createdBy?.email ? post?.createdBy?.email : "-"}</p>
                  <label>Username</label>
                  <p
                  >
                    <span
                      className="cus-user-name-navigate"
                      onClick={() => {
                        navigate('/dashboard/user-view',
                          {
                            state: {
                              data: location?.state?.userId,
                              postId: singledata,
                              pathName: location.pathname
                            },
                          }
                        )
                      }}
                    >{post?.createdBy?.userName ? post?.createdBy?.userName : '-'}</span>
                  </p>
                  <label>Phone Number</label>
                  <p>
                    {post?.createdBy?.phoneNumber ? `${post?.createdBy?.countryCode} ${post?.createdBy?.phoneNumber}` : '-'}
                  </p>

                  <label>Country</label>
                  <p>  {post?.createdBy?.country ? post?.createdBy?.country : "-"}</p>
                  <label>Engagement Ratio</label>
                  <p> {post?.allLikeComment ? `${post?.allLikeComment?.toFixed(2)}%` : '-'}</p>
                  <label>Bio</label>
                  <p>
                    {post?.createdBy?.Bio &&
                      post?.createdBy?.Bio?.length > 25 ? (
                      <PageIndex.LightTooltip
                        placement="right"
                        title={post?.createdBy?.Bio}
                      >
                        {post?.createdBy?.Bio.slice(0, 25) + "..."}
                      </PageIndex.LightTooltip>
                    ) : (
                      post?.createdBy?.Bio || "-"
                    )}
                  </p>
                  <label>Suspend</label>
                  <p>
                    {post?.createdBy?.isSuspended === true
                      ? "Yes"
                      : "No"}
                  </p>
                  <label>Links</label>
                  {/* <p>{post?.createdBy?.links ? post?.createdBy?.links : "-"}</p> */}
                  <p>
                    {post?.createdBy?.links &&
                      post?.createdBy?.links?.length > 25 ? (
                      <PageIndex.LightTooltip
                        placement="right"
                        title={post?.createdBy?.links?.split(',').map((item) => <p>{item}</p>)}
                      >
                        {post?.createdBy?.links.slice(0, 25) + "..."}
                      </PageIndex.LightTooltip>
                    ) : (
                      post?.createdBy?.links?.Bio || "-"
                    )}

                  </p>
                  <label>Following Count</label>
                  <p>
                    {/* {post?.followingCount ? post?.followingCount : 0} */}
                    {post?.followingCount
                      ? Number.isInteger(post?.followingCount)
                        ? numeral(post?.followingCount).format('0')
                        : numeral(post?.followingCount).format('0.000a')
                      : 0}
                  </p>
                  <label>Followers Count</label>
                  <p>
                    {/* {post?.followersCount ? post?.followersCount : 0} */}
                    {post?.followersCount
                      ? Number.isInteger(post?.followersCount)
                        ? numeral(post?.followersCount).format('0')
                        : numeral(post?.followersCount).format('0.000a')
                      : 0}
                  </p>
                </Index.Box>

                {(rolePermission && rolePermission?.isAdmin === true) ? (
                  <PageIndex.LightTooltip title={post?.createdBy?.isSuspended != true ? "Suspend" : "Unsuspend"}>
                    <Index.IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      // disabled={userdata?.isSuspended === true}
                      onClick={() => {
                        handleOpenSuspend();
                      }}
                    >

                      <img
                        src={post?.createdBy?.isSuspended != true ? Index.Png.blockUser : Index.Png.unBlockUser}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                      {/* <Index.DeleteIcon className="usermailwarning-img" /> */}
                    </Index.IconButton>
                  </PageIndex.LightTooltip>) : ""}
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 9",
              }}
              className="grid-column"
            >
              <Index.Box className="right-wrape">
                <Index.Box className="owner-detail-wrap">
                  <Index.Box className="mini-card">
                    <Index.Box className="owner-image-wrape ">
                      <Index.Box className="user-post-post-img-box"
                      >
                        <Index.Box className='cus-view-user-slider'>
                          <Index.Swiper
                            navigation={true}
                            modules={[Index.SwiperPagination, Index.Navigation]}
                            className="mySwiper"
                            // onSwiper={(swiper) => console.log(swiper, 'niru')} // Save swiper instance
                            onSlideNextTransitionStart={(swiper) => handleVideoClick(swiper.activeIndex)}
                            onSlidePrevTransitionStart={(swiper) => handleVideoClick(swiper.activeIndex)}
                          >
                            {post &&
                              post?.profile?.map((profile, index) => {
                                return (
                                  <Index.SwiperSlide key={profile} >
                                    {profile?.endsWith(".mp4") || profile?.endsWith(".mov") ? (

                                      <Index.Box className='video-swiper' >
                                        <Index.ReactPlayer
                                          component="video"
                                          controls
                                          width={200}
                                          height={150}
                                          className="react-player"
                                          url={`${imageUrl}${profile}`}
                                          playing={index === currentVideoId ? true : false}
                                          onPlay={() => {
                                            handleVideoClick(index)

                                          }}
                                        />
                                      </Index.Box>
                                    ) : (
                                      <Index.Box className='image-view-user-swiper'
                                        onClick={() => handleOpenImage(profile)}
                                      >

                                        <img
                                          src={`${imageUrl}${profile}`}
                                          className="user-post-post-img"

                                        />
                                      </Index.Box>
                                    )}
                                  </Index.SwiperSlide>
                                );
                              })}
                          </Index.Swiper>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="start-section owener-detail">
                    <Index.Box className="detail profile-detail">
                      <label>Caption</label>
                      <p className="post-description">
                        {post?.caption && post?.caption?.length > 40 ? (
                          <PageIndex.LightTooltip
                            title={
                              <Index.Box
                                ref={divRef}
                                dangerouslySetInnerHTML={{
                                  __html: post?.caption ? post?.caption : "-",
                                }}
                              ></Index.Box>
                            }
                            placement="right"
                          >
                            <Index.Box
                              ref={divRef}
                              dangerouslySetInnerHTML={{
                                __html: post?.caption
                                  ? post?.caption?.slice(0, 45) + "..."
                                  : "-",
                              }}
                            ></Index.Box>
                          </PageIndex.LightTooltip>
                        ) : (
                          <Index.Box
                            ref={divRef}
                            dangerouslySetInnerHTML={{
                              __html: post?.caption ? post?.caption : "-",
                            }}
                          ></Index.Box>
                        )}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Location</label>
                      <p className="post-description">
                        {post?.location && post?.location?.length > 30 ? (
                          <PageIndex.LightTooltip
                            placement="top"
                            title={post?.location}
                          >
                            {post?.location.slice(0, 30) + "..."}
                          </PageIndex.LightTooltip>
                        ) : (
                          post?.location || "-"
                        )}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Created at</label>
                      <p className="post-description">
                        {moment(post.createdAt).format("DD/MM/YYYY hh:mm A")}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Allow Comments</label>
                      <p className="post-description">
                        {post?.allowComments == true ? "Yes" : "No"}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Visible to Everyone</label>
                      <p className="post-description">
                        {post?.allowComments == true ? "Yes" : "No"}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Engagement Ratio</label>
                      <p className="post-description">
                        {post?.engamentRatio ? post?.engamentRatio : 0}
                      </p>
                    </Index.Box>
                    <Index.Box className="userdata-btn-flex">
                      <PageIndex.LightTooltip placement="right" title="Delete post">
                        <Index.IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                          onClick={() => {
                            handleOpenDelete();
                          }}
                        >
                          <Index.DeleteIcon className="usermailwarning-img" />
                        </Index.IconButton>
                      </PageIndex.LightTooltip>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="table-wrape">
                  <Index.Box className="external-tab-box">
                    <Index.Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className="admin-tabs-main"
                    >
                      <Index.Tab
                        label="Likes"
                        {...a11yProps(0)}
                        className="admin-tab"
                      />

                      <Index.Tab
                        label="Tags"
                        {...a11yProps(1)}
                        className="admin-tab"
                      />
                      <Index.Tab
                        label="Comments"
                        {...a11yProps(2)}
                        className="admin-tab"
                      />
                    </Index.Tabs>
                  </Index.Box>
                  <Index.Box className="barge-common-box">
                    <Index.Box className="common-box">
                      <Index.Box className="user-list-flex">
                        <Index.Box className="admin-page-title-main">
                          <Index.Typography
                            className="admin-page-title tittle-like-costam"
                            component="h2"
                            variant="h2"
                          >
                            {value == 0
                              ? "Likes"
                              : value == 1
                                ? "Tags"
                                : "Comments"}
                          </Index.Typography>
                        </Index.Box>
                        {value == 0 || value == 1 ? (
                          <Index.Box className="userlist-btn-flex">
                            <Index.Box className="user-search-main">
                              <Index.Box className="user-search-box">
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder="Search user"
                                    onChange={handleSearch}
                                  />
                                  <span className="search-icon-box">
                                    <img
                                      src={Index.Svg.search}
                                      className="search-icon"
                                    />
                                  </span>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        ) : (
                          ""
                        )}
                      </Index.Box>

                      {value == 0 ? (
                        <Index.Box className="admin-dash-box">
                          <Index.Box className="page-table-main like-table-main">
                            <Index.TableContainer
                              component={Index.Paper}
                              className="table-container"
                            >
                              <Index.Table
                                stickyHeader
                                aria-label="simple table"
                                className="table"
                              >
                                <Index.TableHead className="table-head cus-table-head">
                                  <Index.TableRow className="table-row">
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      S.No.
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Name
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Username
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Profile
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Date
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Bio
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </Index.TableHead>
                                <Index.TableBody className="table-body">

                                  {loader ? (
                                    <PageIndex.TableLoader colSpan={6} />
                                  ) : (

                                    pageData?.length > 0 ? (
                                      pageData?.map((row, index) => (
                                        <Index.TableRow key={row?._id}>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {row?.sNo}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {row?.name || "-"}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            <span
                                              className="cus-user-name-navigate"
                                              onClick={() => {
                                                navigate('/dashboard/user-view', {
                                                  state: {
                                                    data: row?.userId,
                                                    postId: singledata,
                                                    pathName: location.pathname
                                                  },
                                                });
                                              }}
                                            >
                                              {row?.userName || "-"}
                                            </span>
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            <img
                                              src={
                                                row?.profile
                                                  ? row?.profile instanceof Blob
                                                    ? URL.createObjectURL(row?.profile)
                                                    : `${imageUrl}${row?.profile}`
                                                  : PageIndex?.Jpg?.dummyImage
                                              }
                                              className="user-post-user-img"
                                              onClick={() => handleOpenImage(row?.profile)}
                                            />
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {moment(row.createdAt).format("DD/MM/YYYY")}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            <PageIndex.LightTooltip title={row?.Bio || ""}>
                                              {row?.Bio
                                                ? row?.Bio.length > 30
                                                  ? `${row?.Bio.slice(0, 30)}...`
                                                  : row?.Bio
                                                : "-"}
                                            </PageIndex.LightTooltip>
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      ))
                                    ) : (
                                      <PageIndex.RecordNotFound colSpan={6} />
                                    )
                                  )}
                                </Index.TableBody>

                              </Index.Table>
                            </Index.TableContainer>
                          </Index.Box>

                          <Index.Box className="pagination-costom">
                            <PageIndex.Pagination
                              fetchData={filterData}
                              setPageData={setPageData}
                              pageData={pageData?.likes}

                            />
                          </Index.Box>

                        </Index.Box>
                      ) : value == 1 ? (
                        <Index.Box className="admin-dash-box">
                          <Index.Box className="page-table-main like-table-main">
                            <Index.TableContainer
                              component={Index.Paper}
                              className="table-container"
                            >
                              <Index.Table
                                stickyHeader
                                aria-label="simple table"
                                className="table"
                              >
                                <Index.TableHead className="table-head cus-table-head">
                                  <Index.TableRow className="table-row">
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      S.No.
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Name
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Username
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Profile
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Links
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Bio
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </Index.TableHead>
                                <Index.TableBody className="table-body">
                                  {!loader ? (
                                    pageData?.length > 0 ? (
                                      pageData?.map((row, index) => (
                                        <Index.TableRow key={row._id}>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {row?.sNo}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                            align="center"
                                          >
                                            {row?.name ? row?.name : "-"}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                            align="center"

                                          >
                                            <span
                                              onClick={() => {
                                                navigate('/dashboard/user-view',
                                                  {
                                                    state: {
                                                      data: row?._id,
                                                      postId: singledata,
                                                      pathName: location.pathname
                                                    },
                                                  }
                                                )
                                              }}
                                              className="cus-user-name-navigate">{row?.userName
                                                ? row?.userName
                                                : "-"}</span>
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                            align="center"
                                          >
                                            <img
                                              src={
                                                row?.profile
                                                  ? row?.profile instanceof Blob
                                                    ? URL.createObjectURL(
                                                      row?.profile
                                                    )
                                                    : `${imageUrl}${row?.profile}`
                                                  : PageIndex?.Jpg?.dummyImage
                                              }
                                              className="user-post-user-img"
                                              onClick={() => handleOpenImage(row?.profile)}
                                            />
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                            align="center"
                                          >
                                            <PageIndex.LightTooltip
                                              title={
                                                row?.links ? row?.links : ""
                                              }
                                            >
                                              {" "}
                                              {row?.links
                                                ? row?.links?.length > 30
                                                  ? row?.links?.slice(0, 30) +
                                                  "..."
                                                  : row?.links
                                                : "-"}
                                            </PageIndex.LightTooltip>
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                            align="center"
                                          >
                                            <PageIndex.LightTooltip
                                              title={row?.Bio ? row?.Bio : "-"}
                                            >
                                              {" "}
                                              {row?.Bio
                                                ? row?.Bio?.length > 30
                                                  ? row?.Bio?.slice(0, 30) +
                                                  "..."
                                                  : row?.Bio
                                                : "-"}
                                            </PageIndex.LightTooltip>
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      ))
                                    ) : (
                                      <PageIndex.RecordNotFound colSpan={6} />
                                    )
                                  ) : (
                                    <PageIndex.TableLoader colSpan={6} />
                                  )}
                                </Index.TableBody>
                              </Index.Table>
                            </Index.TableContainer>
                          </Index.Box>

                          <Index.Box className="pagination-costom">
                            <PageIndex.Pagination
                              fetchData={filterData}
                              setPageData={setPageData}
                              pageData={pageData?.tagPeople}


                            />
                          </Index.Box>

                        </Index.Box>
                      ) : (
                        <ViewUserPostComment
                          comments={post?.comments}
                          singledataId={singledata}
                          setImageOpen={setOpenImage}
                          setImagePath={setImagePath}
                        />
                      )}
                    </Index.Box>

                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <PageIndex.SuspendModal
          openSuspend={openSuspend}
          handleCloseSuspend={handleCloseSuspend}
          handleSuspendRecord={suspendUserByAdmin}
          isDeleteButtonDisabled={isDeleteButtonDisabled}
        />
        <PageIndex.DeleteModal
          openDelete={openDelete}
          handleCloseDelete={handleCloseDelete}
          handleDeleteRecord={deletePost}
          isDeleteButtonDisabled={isDeleteButtonDisabled}
        />
        <PageIndex.ImageViewModel
          open={openImage}
          handleClose={handleImageClose}
          image={imagePath}
          swiper="swiper"
        />
      </Index.Box>
    </>
  );
};

export default ViewUserPost;
