import {
  TablePagination,
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  Collapse,
  Pagination,
  Stack,
  SwipeableDrawer,
  Card,
  CardContent,
  InputLabel,
  Divider,
  Chip,
  Tooltip,
  ListItemText,
  tooltipClasses,
} from "@mui/material";
import dayjs from "dayjs";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Jpg from "../assets/Jpg";
import Sidebar from "../component/admin/defaulLayout/Sidebar";
import Header from "../component/admin/defaulLayout/Header";
import PaidLable from "../component/common/PaidLable";
import FailedLable from "../component/common/FailedLable";
import PendingLable from "../component/common/PendingLable";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PrimaryButton from "../component/common/PrimaryButton";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Textarea from "@mui/joy/Textarea";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FieldArray } from "formik";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import LinearProgress from "@mui/material/LinearProgress";
import { DateRangePicker } from "react-date-range";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import CampaignIcon from "@mui/icons-material/Campaign";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import { MobileDatePicker } from "@mui/x-date-pickers";
import ClearIcon from "@mui/icons-material/Clear";
import DiamondIcon from "@mui/icons-material/Diamond";
import ReplyIcon from "@mui/icons-material/Reply";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination as SwiperPagination } from "swiper/modules";
import ReactPlayer from "react-player";
import SendIcon from "@mui/icons-material/Send";

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const TextareaAutosizeed = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    height: ${theme.spacing(5)};
    line-height: 18px;
    padding: 9px 15px;
    border-radius: 5px;
    // color:  #4d586a;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid #dfe0e1;
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };
    resize: none;

    &:hover {
      border-color: ${grey[200]};
    }

    &:focus {
      outline: 0;
      border-color: ${grey[200]};
     
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

const IOSSwitch = styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3e54a0",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#5a5959" : "#3e54a0",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Index = {
  SendIcon,
  ReactPlayer,
  ListItemText,
  AdapterDayjs,
  DemoContainer,
  DatePicker,
  Tooltip,
  LocalizationProvider,
  Chip,
  tooltipClasses,
  Divider,
  KeyboardArrowDownIcon,
  MoreVertIcon,
  InputLabel,
  Card,
  CardContent,
  Textarea,
  LinearProgress,
  RemoveRedEyeIcon,
  EditIcon,
  DeleteIcon,
  ThumbDownAltIcon,
  ThumbUpAltIcon,
  DiamondIcon,
  HowToRegIcon,
  Box,
  Link,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  Visibility,
  List,
  ListItem,
  Svg,
  Png,
  Sidebar,
  Header,
  FieldArray,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  PaidLable,
  FailedLable,
  PendingLable,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  ExpandLess,
  ExpandMore,
  Collapse,
  Stack,
  Pagination,
  TablePagination,
  SwipeableDrawer,
  PrimaryButton,
  Grid,
  Jpg,
  CKEditor,
  ClassicEditor,
  AnalyticsIcon,
  DesktopDatePicker,
  dayjs,
  AddBoxIcon,
  IndeterminateCheckBoxIcon,
  TextareaAutosizeed,
  DateRangePicker,
  MobileDatePicker,
  SettingsBackupRestoreIcon,
  CampaignIcon,
  IOSSwitch,
  style,
  CloseIcon,
  CheckIcon,
  Autocomplete,
  ClearIcon,
  moment,
  ReplyIcon,
  Swiper,
  SwiperSlide,
  Navigation,
  SwiperPagination,
};
export default Index;
