import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import { getAllPostReportsList } from "../../../../redux/slices/adminService";
import { debounce } from "lodash";
import numeral from "numeral";

const UserPostReportList = ({ today, setBtnValue, btnValue }) => {
  const typeList = [
    { id: 1, value: "today", key: "Today" },
    { id: 2, value: "overview", key: "Overview" },
  ];

  const params = PageIndex.useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const stateParam = queryParams.get("state");
  const state = JSON.parse(decodeURIComponent(stateParam));

  let navigate = PageIndex.useNavigate();
  const [loader, setLoader] = useState(true);
  const [postReportist, setPostReportist] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [type, setType] = useState(state?.today ? state?.today : "overview");
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [imagePath, setImagePath] = useState();
  const [openImage, setOpenImage] = useState(false);
  //Fetch Post Report List

  const handleType = (e) => {
    setType(e.target.value);
    navigate({ state: "" });
    setCurrentPage(1);
  };
  const handleOpenImage = (image) => {
    if (image) {
      setOpenImage(true);
      setImagePath(image);
    }
  };

  const handleImageClose = () => {
    setImagePath("");
    setOpenImage(false);
  };

  const fetchPostReportList = () => {
    const page = currentPage;
    getAllPostReportsList(page, search, type).then((data) => {
      if (data?.status == 200) {
        setLoader(false);
        setPostReportist(data?.data);
        if (data?.currentPage) {
          setCurrentPage(data?.currentPage);
        }

        setPageCount(data?.totalCount ? data?.totalCount : 0);
      } else {
        setPostReportist([]);
        setLoader(false);
      }
    });
  };
  useEffect(() => {
    const debouncedFetch = debounce(fetchPostReportList, 300);

    if (search || currentPage || rowsPerPage) {
      debouncedFetch();
    } else {
      fetchPostReportList();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage, type, rowsPerPage]);

  const handleVideoClick = (index, id) => {
    if (index === currentVideoIndex && id === currentVideoId) {
      setCurrentVideoIndex(null);
      setCurrentVideoId(null);
    } else {
      setCurrentVideoIndex(index);
      setCurrentVideoId(id);
    }
  };

  useEffect(() => {
    if (params?.state?.tabNo) {
      setBtnValue(params?.state?.tabNo);
    }
  }, [params?.state?.tabNo]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <Index.Box className="barge-common-box">
      <Index.Box className="common-box">
        <Index.Box className="user-list-flex">
          <Index.Box className="admin-page-title-main">
            <Index.Typography
              className="admin-page-title tittle-like-costam"
              component="h2"
              variant="h2"
            >
              Post Reports
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="input-box add-user-input request-input-box">
              <Index.Box className="form-group">
                <Index.FormControl className="request-dropdown">
                  <Index.Select
                    className="form-control select-drop-list request-drop-list"
                    name="type"
                    value={type}
                    onChange={handleType}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    {typeList &&
                      typeList?.map((val) => (
                        <Index.MenuItem
                          value={val?.value}
                          key={val?.id}
                          className="menuitem"
                        >
                          {val?.key}
                        </Index.MenuItem>
                      ))}
                  </Index.Select>
                </Index.FormControl>
              </Index.Box>
            </Index.Box>
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => {
                      const newValue = e.target.value
                        .replace(/^\s+/, "")
                        .replace(/\s\s+/g, " ");
                      setCurrentPage(1);
                      setSearch(newValue);
                    }}
                  />
                  <span className="search-icon-box">
                    <img src={Index.Svg.search} className="search-icon" />
                  </span>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dash-box">
          <Index.Box className="page-table-main like-table-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="simple table"
                className="table"
              >
                <Index.TableHead className="table-head cus-table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      S.No.
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Post
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Name
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Username
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Report Count
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {!loader ? (
                    postReportist?.length > 0 ? (
                      postReportist?.map((row, index) => (
                        <Index.TableRow key={row?._id}>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {(currentPage - 1) * 10 + index + 1}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            <Index.Box className="cus-slider table-swiper">
                              <Index.Swiper
                                navigation={true}
                                modules={[
                                  Index.SwiperPagination,
                                  Index.Navigation,
                                ]}
                                className="mySwiper"
                                onSlideChange={(swiper) =>
                                  handleVideoClick(swiper.activeIndex, row._id)
                                }
                              >
                                {row?.postId?.profile &&
                                  row?.postId?.profile?.map(
                                    (profile, profileIndex) => (
                                      <Index.SwiperSlide
                                        key={`${row._id}_${profileIndex}`}
                                      >
                                        {profile?.endsWith(".mp4") ||
                                        profile?.endsWith(".mov") ? (
                                          <Index.Box className="video-swiper">
                                            <Index.ReactPlayer
                                              component="video"
                                              controls
                                              width={150}
                                              height={65}
                                              className="react-player"
                                              url={`${imageUrl}${profile}`}
                                              playing={
                                                profileIndex ===
                                                  currentVideoIndex &&
                                                row._id === currentVideoId
                                              }
                                              onPlay={() =>
                                                handleVideoClick(
                                                  profileIndex,
                                                  row._id
                                                )
                                              }
                                            />
                                          </Index.Box>
                                        ) : (
                                          <Index.Box
                                            className="image-swiper"
                                            onClick={() =>
                                              handleOpenImage(profile)
                                            }
                                          >
                                            <img
                                              src={`${imageUrl}${profile}`}
                                            />
                                          </Index.Box>
                                        )}
                                      </Index.SwiperSlide>
                                    )
                                  )}
                              </Index.Swiper>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {row?.postId ? row?.postId?.createdBy?.name : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            <span
                              className="cus-user-name-navigate"
                              onClick={() => {
                                navigate("/dashboard/user-view", {
                                  state: {
                                    data: row?.postId?.createdBy?._id,
                                    pathName: params.pathname,
                                  },
                                });
                              }}
                            >
                              {row?.postId
                                ? row?.postId?.createdBy?.userName
                                : "-"}
                            </span>
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            {/* {row?.postId?.reportersId?.length > 0
                              ? row?.postId?.reportersId?.length
                              : "-"} */}

                            {row?.postId?.reportersId?.length > 0
                              ? Number.isInteger(
                                  row?.postId?.reportersId?.length
                                )
                                ? numeral(
                                    row?.postId?.reportersId?.length
                                  ).format("0")
                                : numeral(
                                    row?.postId?.reportersId?.length
                                  ).format("0.000a")
                              : 0}
                          </Index.TableCell>

                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                            align="center"
                          >
                            <Index.Box className="userdata-btn-flex">
                              <PageIndex.LightTooltip title="View">
                                <Index.IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="label"
                                  onClick={() => {
                                    navigate("/dashboard/view-post-report", {
                                      state: {
                                        data: row,
                                        postId: row?.postId?._id,
                                        postKey: 0,
                                      },
                                    });
                                  }}
                                >
                                  <Index.RemoveRedEyeIcon />
                                </Index.IconButton>
                              </PageIndex.LightTooltip>
                            </Index.Box>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <PageIndex.RecordNotFound colSpan={6} />
                    )
                  ) : (
                    <PageIndex.TableLoader colSpan={6} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
        {postReportist?.length ? (
          <Index.Box className="pagination-main">
            <Index.Box className="pagination-main">
              <Index.TablePagination
                rowsPerPageOptions={[
                  { label: "All", value: -1 },
                  10,
                  25,
                  50,
                  75,
                  100,
                ]}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={currentPage - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
            </Index.Box>
          </Index.Box>
        ) : (
          ""
        )}
      </Index.Box>
      <PageIndex.ImageViewModel
        handleClose={handleImageClose}
        open={openImage}
        image={imagePath}
      />
    </Index.Box>
  );
};
export default UserPostReportList;
