import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import {
  getAllProfileReportsList,
  getFiatWallet,
  getZeroBalanceFiatWallet,
  updateFiatWalletStatus,
} from "../../../../redux/slices/adminService";
import { debounce } from "lodash";
import numeral from "numeral";
import FileExcel from "../../../../component/common/Exportexcel";

const ZeroBalanceUserFiatWalletList = (Curtype) => {
  const typeList = [
    { id: 1, value: "today", key: "Today" },
    { id: 2, value: "overview", key: "Overview" },
  ];
  let navigate = PageIndex.useNavigate();
  const params = PageIndex.useLocation();
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fiatWalletList, setFiatWalletist] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const abortController = new AbortController();

  const queryParams = new URLSearchParams(window.location.search);
  const stateParam = queryParams.get("state");
  const state = JSON.parse(decodeURIComponent(stateParam));
  const [excel, setExcel] = useState([]);
  const [type, setType] = useState(state?.today ? state?.today : "overview");

  const handleType = (e) => {
    setType(e.target.value);
    navigate({ state: "" });
    setCurrentPage(1);
  };

  //Fetch user fiat List

  const fetchFiatList = (page) => {
    setLoader(true);
    const Currencytype = Curtype?.Curtype;

    getZeroBalanceFiatWallet(
      { currentPage, search, rowsPerPage, Currencytype,  type },
      { signal: abortController.signal }
    ).then((data) => {
      if (data?.status == 200) {
        setFiatWalletist(data?.data);
        if (data?.currentPage) {
          setCurrentPage(data?.currentPage);
          setPageCount(data?.totalCount ? data?.totalCount : 0);
        }
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    setLoader(true);
    const debouncedFetch = debounce(fetchFiatList, 300);

    if (search || currentPage || rowsPerPage || type) {
      debouncedFetch();
    } else {
      fetchFiatList();
    }

    return () => {
      debouncedFetch.cancel();
      abortController.abort();
    };
  }, [search, currentPage, rowsPerPage, type]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const fileName = "List of Zero Balance Users Fiat Wallet";
  useEffect(() => {
    if (fiatWalletList?.length > 0) {
      const customHeadings = fiatWalletList?.map((item, index) => ({
        "S.No": index + 1,
        Username: item?.userId?.userName,
        Email: item?.userId?.email,
        Currency: item?.currency?.coins,
        Balance: item?.fiatBalance,
        Status: item?.isActive ? "Active" : "Deactive",
      }));

      setExcel(customHeadings);
    }
  }, [fiatWalletList]);

  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div>
          <Index.Box className="loader-main">
            <img src={PageIndex.Png.spinnerLoader} className="loader-img" />
          </Index.Box>
        </div>
      )}
      <div className={loading ? "loader-overlay" : ""}>
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title tittle-like-costam"
                  component="h2"
                  variant="h2"
                >
                  List of Zero Balance Users Fiat Wallet
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.FormControl className="request-dropdown">
                  <Index.Select
                    className="form-control select-drop-list request-drop-list"
                    name="type"
                    value={type}
                    onChange={handleType}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    {typeList &&
                      typeList?.map((val) => (
                        <Index.MenuItem
                          value={val?.value}
                          key={val?.id}
                          className="menuitem"
                        >
                          {val?.key}
                        </Index.MenuItem>
                      ))}
                  </Index.Select>
                </Index.FormControl>
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="search"
                        autoComplete="search"
                        value={search}
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setFiatWalletist();
                          setSearch(newValue);
                          setCurrentPage(1);
                          setLoader(true);
                        }}
                      />
                      <span className="search-icon-box">
                        <img src={Index.Svg.search} className="search-icon" />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box>
                  <FileExcel apiData={excel} fileName={fileName} />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dash-box">
              <Index.Box className="page-table-main action-column fiat-wallet-table-main">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table
                    stickyHeader
                    aria-label="simple table"
                    className="table"
                  >
                    <Index.TableHead className="table-head cus-table-head">
                      <Index.TableRow className="table-row">
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          S.No.
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Username
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Email
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Currency
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Balance
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          align="center"
                        >
                          Status
                        </Index.TableCell>
                        {(rolePermission &&
                          rolePermission?.roleType?.rolePermission?.includes(
                            "ZeroFiatWalletManagement_view"
                          )) ||
                        rolePermission?.roleType?.rolePermission?.includes(
                          "ZeroFiatWalletManagement_edit"
                        ) ||
                        (rolePermission && rolePermission?.isAdmin === true) ? (
                          <>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Action
                            </Index.TableCell>
                          </>
                        ) : (
                          ""
                        )}
                      </Index.TableRow>
                    </Index.TableHead>

                    <Index.TableBody className="table-body">
                      {!loader ? (
                        fiatWalletList?.length > 0 ? (
                          fiatWalletList?.map((row, index) => (
                            <Index.TableRow key={row?._id}>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {fiatWalletList?.length
                                  ? (currentPage - 1) * 10 + index + 1
                                  : ""}
                                {/* {(1 - 1) * 10 + index + 1} */}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td "
                                align="center"
                              >
                                <span
                                  className="cus-user-name-navigate"
                                  onClick={() => {
                                    navigate("/dashboard/user-view", {
                                      state: {
                                        data: row?.userId?._id,
                                        pathName: params.pathname,
                                      },
                                    });
                                  }}
                                >
                                  {row?.userId?.userName
                                    ? row?.userId?.userName
                                    : "-"}
                                </span>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.userId?.email ? row?.userId?.email : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.currency?.coins
                                  ? row?.currency?.coins
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.fiatBalance
                                  ? Number.isInteger(row.fiatBalance)
                                    ? numeral(row.fiatBalance).format("0")
                                    : numeral(row.fiatBalance).format("0.000a")
                                  : 0}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                {row?.isActive ? "Active" : "Deactive"}
                              </Index.TableCell>
                              {(rolePermission &&
                                rolePermission?.roleType?.rolePermission?.includes(
                                  "ZeroFiatWalletManagement_view"
                                )) ||
                              rolePermission?.roleType?.rolePermission?.includes(
                                "ZeroFiatWalletManagement_edit"
                              ) ||
                              (rolePermission &&
                                rolePermission?.isAdmin === true) ? (
                                <>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    <Index.Box className="userdata-btn-flex wallet-btn">
                                      {(rolePermission &&
                                        rolePermission?.roleType?.rolePermission?.includes(
                                          "ZeroFiatWalletManagement_view"
                                        )) ||
                                      (rolePermission &&
                                        rolePermission?.isAdmin === true) ? (
                                        <>
                                          <PageIndex.LightTooltip title="View">
                                            <Index.IconButton
                                              color="primary"
                                              aria-label="upload picture"
                                              component="label"
                                              onClick={() =>
                                                navigate(
                                                  "/dashboard/view-zero-fiat-wallet",
                                                  {
                                                    state: {
                                                      data: row?.userId?._id,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              <Index.RemoveRedEyeIcon className="view-eye-Icon" />
                                            </Index.IconButton>
                                          </PageIndex.LightTooltip>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Index.Box>
                                  </Index.TableCell>
                                </>
                              ) : (
                                ""
                              )}
                            </Index.TableRow>
                          ))
                        ) : (
                          <PageIndex.RecordNotFound colSpan={7} />
                        )
                      ) : (
                        <PageIndex.TableLoader colSpan={7} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
            {fiatWalletList?.length ? (
              <Index.Box className="pagination-main">
                <Index.TablePagination
                  rowsPerPageOptions={[
                    { label: "All", value: -1 },
                    10,
                    25,
                    50,
                    75,
                    100,
                  ]}
                  count={pageCount}
                  rowsPerPage={rowsPerPage}
                  page={currentPage - 1}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  variant="outlined"
                  shape="rounded"
                  className="pagination"
                />
              </Index.Box>
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </div>
    </div>
  );
};
export default ZeroBalanceUserFiatWalletList;
