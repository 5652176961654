import axios from "axios";
import PageIndex from "../container/PageIndex";

// const API_ENDPOINT = "https://supreme-potato-ggqv6j7r5q93v55q-3036.app.github.dev/";

// live

const API_ENDPOINT = "https://social-media.appworkdemo.com/api/";
// const API_ENDPOINT = "https://super-guide-xjwgx7vpvr5cx69-3036.app.github.dev/";
// const API_ENDPOINT = "http://192.168.29.71:3036/";

export const imageUrl = "https://social-media-vh.s3.ap-south-1.amazonaws.com/";
export const tokenLogo = "https://social-media-vh.s3.ap-south-1.amazonaws.com/";

const DataService = axios.create({
  baseURL: API_ENDPOINT,
});

export default DataService;

//Get Call
export const doGet = async (url) => {
  try {
    const response = await DataService.get(url);
    if (response?.data?.status == 200) {
      return response;
    }
  } catch (error) {
    PageIndex.toast.error(error?.response?.data?.message, { autoClose: 2000 });
    return error;
  }
};

//Get Queary Call
export const doGetByQueary = async (
  url,
  queary,
  controller = null,
  isShow = true
) => {
  try {
    const response = await DataService.get(url, {
      ...queary,
      signal: controller?.signal,
    });
    if (response?.data?.status === 201 || 200) {
      return response;
    }
  } catch (error) {
    if (isShow) {
      PageIndex.toast.error(error?.response?.data?.message, {
        autoClose: 2000,
      });
    }
  }
};

//Post Call
export const doPost = async (url, data, isShow = true) => {
  try {
    const response = await DataService.post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    if (response?.data?.status === 201 || 200) {
      if (isShow) {
        PageIndex.toast.success(response?.data?.message, { autoClose: 2000 });
      }
      return response;
    }
  } catch (error) {
    PageIndex.toast.error(error?.response?.data?.message, { autoClose: 2000 });
  }
}; 

//Post FormData Call
export const doPostFormData = async (url, data) => {
  try {
    const response = await DataService.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.data.status === 201 || 200) {
      PageIndex.toast.success(response.data.message, { autoClose: 2000 });
      return response;
    }
  } catch (error) {
    PageIndex.toast.error(error.response.data.message, { autoClose: 2000 });
  }
};
export const doPostGetParams = async (url, data, id) => {
  try {
    delete data?.id;
    const response = await DataService.post(`${url}?id=${id}`, data);
    if (response?.data?.status == 200) {
      PageIndex.toast.success(response.data.message, { autoClose: 2000 });
      return response;
    }
  } catch (error) {
    PageIndex.toast.error(error?.response?.data?.message, { autoClose: 2000 });
  }
};

export const doGetParams = async (url, id) => {
  try {
    const response = await DataService.get(`${url}/${id}`);
    if (response?.data?.status == 200) {
      return response;
    }
  } catch (error) {
    PageIndex.toast.error(error?.response?.data?.message, { autoClose: 2000 });
  }
};

export const doPostData = async (url, data) => {
  try {
    const response = await DataService.post(url, data);
    if (response.data.status === 201 || 200) {
      PageIndex.toast.success(response.data.message, { autoClose: 2000 });
      return response;
    }
  } catch (error) {
    PageIndex.toast.error(error.response.data.message, { autoClose: 2000 });
  }
};
