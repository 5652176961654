import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import CommonTable from "../../../../component/common/CommonTable";
import { debounce } from "lodash";
import {
  DepositeMoneyIntoWallet,
  getAssestsList,
  getCryptoQR,
  getCurrencyListAction,
  getSingleUserCryptoWalletDataForAdmin,
  getUserTransactionList,
  sendAmountAction,
} from "../../../../redux/slices/adminService";
import dayjs from "dayjs";
import numeral from "numeral";

const typeList = [
  { id: 1, value: "All" },
  { id: 2, value: "Success" },
  { id: 3, value: "Pending" },
  { id: 4, value: "Failed" },
];

const columns = [
  "S.No",
  "Date",
  "Transaction Id",
  "Transaction Type",
  "Token Name",
  "Token Amount",
  "No. of Token",
  "Status",
  "Actions",
];

const columnMap = {
  "S.No": "sNo",
  Date: "Date",
  "Transaction Id": "transactionId",
  "Transaction Type": "type",
  "Token Name": "cryptoCoinType.tokenName",
  "Token Amount": "amount",
  "No. of Token": "noOfCoins",
  Status: "paymentStatus",
  Actions: "actions",
};

const sortableColumns = ["Date", "Transaction Type", "Coin"];
const ViewCryptoWallet = () => {
  const params = PageIndex.useLocation();

  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const userId = params?.state?.data;
  const TypeOfWalletCurrency = params?.state?.currencyData
    ? params?.state?.currencyData
    : params?.state?.TypeOfCurrency;

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [userWalletDetails, setUserWalletDetails] = useState("");
  const [assestsList, setAssestsList] = useState([]);
  const [cryptoQR, setCryptoQR] = useState("");
  const [transactionList, setTransactionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [qrLoader, setQrLoader] = useState(true);
  const [loader, setLoader] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [type, setType] = useState("All");
  const [open, setOpen] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [singleTransactionData, setSingleTransaction] = useState("");
  const [openAddMoney, setOpenAddMoney] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const initialValues = {
    userId: userId,
    amount: "",
    currencyType: TypeOfWalletCurrency,
    cryptoCoinType: "",
  };

  const handleOpenAddMoney = (initialValues, isViewCome = false) => {
    // setSingleCrypto(data);
    // setIsView(isViewCome);
    setOpenAddMoney(true);
  };
  const handleCloseAddMoney = () => {
    setOpenAddMoney(false);
    // setIsView(false);
  };

  const getcurrencyType = (data) => {
    getCurrencyListAction(data).then((res) => {
      setCurrencyList(res?.data);
    });
  };

  useEffect(() => {
    getcurrencyType(TypeOfWalletCurrency);
  }, [TypeOfWalletCurrency]);
  const handleOpen = (data) => {
    setOpen(true);
    setSingleTransaction(data);
  };

  const handleClose = () => setOpen(false);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const fetchUserTransactionList = (page) => {
    setLoader(true);
    let obj = {};
    if (selectedFromDate || selectedToDate) {
      obj = {
        startDate: selectedFromDate
          ? dayjs(selectedFromDate).format("DD/MM/YYYY")
          : "",
        endDate: selectedToDate
          ? dayjs(selectedToDate).format("DD/MM/YYYY")
          : "",
      };
    }
    let queryPage = page || currentPage || 1;
    let previousPage = queryPage;
    getUserTransactionList({
      obj,
      userId,
      page: currentPage,
      limit: rowsPerPage,
      search,
      type: "crypto",
      status: type,
    }).then((data) => {
      if (data?.status == 200) {
        if (data?.data.length === 0) {
          setCurrentPage(previousPage);
          setTransactionList([]);
        } else {
          const formattedData = data?.data.map((item) => ({
            ...item,
            Date: Index.moment(item.createdAt).format("DD/MM/YYYY hh:mm A"),
          }));
          setTransactionList(formattedData);
          if (data?.currentPage) {
            setCurrentPage(data?.currentPage || 1); // Set currentPage to 1 if data.currentPage is falsy
            setPageCount(data?.totalPages);
          }
        }
        setLoader(false);
      } else {
        setUserWalletDetails([]);
        setLoader(false);
      }
    });
  };
  const fetchUserWalletDetails = (page) => {
    setLoading(true);
    getSingleUserCryptoWalletDataForAdmin(userId).then((data) => {
      if (data?.status == 200) {
        setUserWalletDetails(data?.data);
        setLoading(false);
      } else {
        setUserWalletDetails([]);
        setLoading(false);
      }
    });
  };
  const fetchAssestList = (page) => {
    setLoading(true);

    getAssestsList(userId).then((data) => {
      if (data?.status == 200) {
        setAssestsList(data?.data);
        setFilterData(data?.data);
        setLoading(false);
      } else {
        setAssestsList([]);
        setLoading(false);
      }
    });
  };

  const fetchQR = (page) => {
    setQrLoader(true);

    getCryptoQR(userId).then((data) => {
      if (data?.status == 200) {
        setCryptoQR(data?.data?.qrImageUrls);
        setQrLoader(false);
      } else {
        setCryptoQR("");
        setQrLoader(false);
      }
    });
  };

  const transferAmountToAdmin = async (amount, tokenId) => {
    setLoading(true);
    try {
      const data = {
        walletAddress: "0x512573F48c1Af8279F9EC8fdACB262EB5549980d",
        userId: userId,
        sendAmount: amount,
        tokenId: tokenId,
      };
      const res = await sendAmountAction(data).then((res) => {
        if (res?.status == 200) {
          setLoading(false);
          fetchAssestList();
        }
      });
    } catch (error) {
      setLoading(false);
      console.error("Error transferring amount to admin:", error);
      throw error;
    }
  };

  const handleType = (e) => {
    setType(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchUserWalletDetails();
    fetchAssestList();
    fetchQR();
  }, []);

  useEffect(() => {
    const debouncedFetch = debounce(fetchUserTransactionList, 300);

    if (search) {
      debouncedFetch();
    } else {
      fetchUserTransactionList();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [search, type]);

  useEffect(() => {
    const debouncedFetch = debounce(fetchUserTransactionList, 300);
    if (currentPage !== undefined) {
      debouncedFetch();
    }
  }, [currentPage]);

  useEffect(() => {
    const debouncedFetch = debounce(fetchUserTransactionList, 300);

    if (selectedFromDate || selectedToDate) {
      debouncedFetch();
    } else if (selectedFromDate == null || selectedToDate == null) {
      fetchUserTransactionList();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [selectedFromDate, selectedToDate]);

  const handleClearFromDate = () => {
    setSelectedFromDate(null);
  };
  const handleClearToDate = () => {
    setSelectedToDate(null);
  };

  const handleDepositeMoney = async (values) => {
    setLoader(true);

    const InitialData = {
      userId: userId,
      amount: values?.amount,
      currencyType: TypeOfWalletCurrency,
      cryptoCoinType: values?.cryptoCoinType,
    };

    DepositeMoneyIntoWallet(InitialData).then((data) => {
      if (data?.status == 200) {
        handleCloseAddMoney();
        fetchUserTransactionList();
        fetchUserWalletDetails();
        fetchAssestList();
        setLoader(false);
      } else {
        handleCloseAddMoney();
        fetchUserTransactionList();
        fetchUserWalletDetails();
        fetchAssestList();
      }
    });
  };

  return (
    <>
      {!loading ? (
        <Index.Box className="dashboard-content">
          <PageIndex.BackButton
            onClick={() =>
              navigate(
                params?.state?.pathName
                  ? params?.state?.pathName
                  : "/dashboard/wallet-management",
                {
                  state: {
                    postKey: 1,
                    data: params?.state?.data,
                    pathName: params?.state?.pathName,
                  },
                }
              )
            }
          />
          <Index.Box className="barge-common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  User Basic Details
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box user-details-dash-box ">
                      <Index.Box
                        sx={{ width: 1 }}
                        className="grid-main common-card"
                      >
                        <Index.Box
                          display="grid"
                          className="display-row"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  Name
                                </Index.Typography>
                                <Index.Typography className="user-details-data">
                                  {userWalletDetails?.userId?.name
                                    ? userWalletDetails?.userId?.name
                                    : "-"}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  Username
                                </Index.Typography>
                                <Index.Typography className="user-details-data">
                                  <span
                                    className=" cus-user-name-navigate"
                                    onClick={() => {
                                      navigate("/dashboard/user-view", {
                                        state: {
                                          data: userId,
                                          pathName: params.pathname,
                                        },
                                      });
                                    }}
                                  >
                                    {userWalletDetails?.userId?.userName
                                      ? userWalletDetails?.userId?.userName
                                      : "-"}
                                  </span>
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  Status
                                </Index.Typography>
                                <Index.Typography className="user-details-data">
                                  {userWalletDetails?.isActive
                                    ? "Active"
                                    : "Deactive"}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  Email
                                </Index.Typography>
                                <Index.Typography className="user-details-data">
                                  {userWalletDetails?.userId?.email
                                    ? userWalletDetails?.userId?.email
                                    : "-"}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  Wallet Address
                                </Index.Typography>
                                <Index.Typography className="user-details-data">
                                  {userWalletDetails?.walletAddress
                                    ? userWalletDetails?.walletAddress
                                    : "-"}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  Balance
                                </Index.Typography>
                                <Index.Typography className="user-details-data">
                                  {userWalletDetails?.cryptoBalance
                                    ? parseFloat(
                                        userWalletDetails?.cryptoBalance?.toFixed(
                                          4
                                        )
                                      )
                                    : 0}
                                  {/* {userWalletDetails?.cryptoBalance
                                    ? Number.isInteger(userWalletDetails?.cryptoBalance)
                                      ? numeral(userWalletDetails?.cryptoBalance).format('0')
                                      : numeral(userWalletDetails?.cryptoBalance).format('0.000a')
                                    : 0} */}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main view-crypto-wallet-assets">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  Assests
                                </Index.Typography>
                                <Index.Box className="admin-dash-box">
                                  <Index.Box className="page-table-main viewcrypto-table-main">
                                    <Index.TableContainer
                                      component={Index.Paper}
                                      className="table-container"
                                    >
                                      <Index.Table
                                        stickyHeader
                                        aria-label="simple table"
                                        className="table"
                                      >
                                        <Index.TableHead className="table-head cus-table-head">
                                          <Index.TableRow className="table-row">
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                              align="center"
                                            >
                                              S.No.
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                              align="center"
                                            >
                                              Crypto Currency
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                              align="center"
                                            >
                                              Coins
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                              align="center"
                                            >
                                              Actual Coins
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="th"
                                              variant="th"
                                              className="table-th"
                                              align="center"
                                            >
                                              Action
                                            </Index.TableCell>
                                          </Index.TableRow>
                                        </Index.TableHead>

                                        <Index.TableBody className="table-body">
                                          {!loading ? (
                                            pageData?.length > 0 ? (
                                              pageData?.map((row, index) => (
                                                <Index.TableRow key={row?._id}>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    scope="row"
                                                    className="table-td"
                                                    align="center"
                                                  >
                                                    {index + 1}
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    scope="row"
                                                    className="table-td"
                                                    align="center"
                                                  >
                                                    {row?.tokenName?.tokenName
                                                      ? row?.tokenName
                                                          ?.tokenName
                                                      : "-"}
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    scope="row"
                                                    className="table-td"
                                                    align="center"
                                                  >
                                                    {row?.totalAmount
                                                      ? parseFloat(
                                                          row?.totalAmount?.toFixed(
                                                            4
                                                          )
                                                        )
                                                      : 0}
                                                    {/* {row?.totalAmount
                                                      ? Number.isInteger(row.totalAmount)
                                                        ? numeral(row.totalAmount).format('0')
                                                        : numeral(row.totalAmount).format('0.000a')
                                                      : 0} */}
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    scope="row"
                                                    className="table-td"
                                                    align="center"
                                                  >
                                                    {row?.actualCoin !== 0
                                                      ? row?.actualCoin
                                                      : "0"}
                                                  </Index.TableCell>
                                                  <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    scope="row"
                                                    className="table-td"
                                                    align="center"
                                                  >
                                                    <Index.Box className="userdata-btn-flex wallet-btn">
                                                      <PageIndex.LightTooltip title="Send">
                                                        <Index.IconButton
                                                          color="primary"
                                                          aria-label="upload picture"
                                                          component="label"
                                                          onClick={() =>
                                                            transferAmountToAdmin(
                                                              row?.actualCoin,
                                                              row?.tokenName
                                                                ?._id
                                                            )
                                                          }
                                                          disabled={
                                                            row?.actualCoin == 0
                                                          }
                                                        >
                                                          <Index.SendIcon className="view-eye-Icon" />
                                                        </Index.IconButton>
                                                      </PageIndex.LightTooltip>
                                                    </Index.Box>
                                                  </Index.TableCell>
                                                </Index.TableRow>
                                              ))
                                            ) : (
                                              <PageIndex.RecordNotFound
                                                colSpan={6}
                                                className="crypto-norecord-found"
                                              />
                                            )
                                          ) : (
                                            <PageIndex.TableLoader
                                              colSpan={4}
                                            />
                                          )}
                                        </Index.TableBody>
                                      </Index.Table>
                                    </Index.TableContainer>
                                  </Index.Box>

                                  <PageIndex.Pagination
                                    fetchData={filterData}
                                    setPageData={setPageData}
                                    pageData={pageData}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                              xl: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="user-details-main">
                              <Index.Box className="user-details-flex">
                                <Index.Typography className="user-details-lable">
                                  QR Code
                                </Index.Typography>
                                <Index.Typography className="user-details-data">
                                  <Index.Box className="fiat-qr-box">
                                    <Index.Box className="qr-main-box">
                                      <Index.Typography className="qr-title ">
                                        {cryptoQR?.BTC?.externalQrImageUrlBTC
                                          ? "BTC"
                                          : "-"}
                                      </Index.Typography>
                                      <Index.Box className="qrscanner-box-main other-qr">
                                        {cryptoQR ? (
                                          <img
                                            src={
                                              cryptoQR?.BTC
                                                ?.externalQrImageUrlBTC
                                            }
                                            // src={PageIndex.Png.QRScanner}
                                            className="qrscanner-img"
                                          />
                                        ) : (
                                          "-"
                                        )}
                                      </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="qr-main-box">
                                      <Index.Typography className="qr-title ">
                                        {cryptoQR?.Other
                                          ?.externalQrImageUrlOther
                                          ? "Other"
                                          : "-"}
                                      </Index.Typography>
                                      <Index.Box className="qrscanner-box-main">
                                        {cryptoQR ? (
                                          <img
                                            src={
                                              cryptoQR?.Other
                                                ?.externalQrImageUrlOther
                                            }
                                            className="qrscanner-img"
                                          />
                                        ) : (
                                          "-"
                                        )}
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="campaigns-section-main">
              <Index.Box className="user-list-flex">
                <Index.Box className="admin-page-title-main">
                  <Index.Typography
                    className="admin-page-title"
                    component="h2"
                    variant="h2"
                  >
                    Transaction History
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="contest-search-list user-list-flex">
                {/* <Index.Box className="userlist-btn-flex"> */}
                <Index.Box className="contest-search-list-1">
                  {" "}
                  <Index.Box className="admin-text-field-main from-input mb-0">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.Box className="form-group transaction-fieldset">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <Index.InputLabel
                            className="form-lable"
                            id="demo-simple-label"
                          >
                            From
                          </Index.InputLabel>
                          <Index.Stack className="date-picker-mui cus-datepicker">
                            {/* From Date */}
                            <Index.MobileDatePicker
                              className="form-control datepicker"
                              format="DD-MM-YYYY"
                              value={selectedFromDate}
                              maxDate={selectedToDate}
                              disableFuture
                              onChange={(newValue) => {
                                setSelectedFromDate(newValue);
                              }}
                            />
                            {selectedFromDate ? (
                              <Index.IconButton
                                className="clear-icon"
                                onClick={handleClearFromDate}
                              >
                                <Index.ClearIcon />
                              </Index.IconButton>
                            ) : (
                              ""
                            )}
                          </Index.Stack>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-text-field-main from-input mb-0">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.Box className="form-group transaction-fieldset">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <Index.InputLabel
                            className="form-lable"
                            id="demo-simple-label"
                          >
                            To
                          </Index.InputLabel>
                          <Index.Stack className=" date-picker-mui cus-datepicker">
                            {/* To Date */}
                            <Index.MobileDatePicker
                              className="form-control datepicker"
                              format="DD-MM-YYYY"
                              value={selectedToDate}
                              disableFuture
                              minDate={selectedFromDate}
                              onChange={(newValue) =>
                                setSelectedToDate(newValue)
                              }
                            />
                            {selectedToDate ? (
                              <Index.IconButton
                                className="clear-icon"
                                onClick={handleClearToDate}
                              >
                                <Index.ClearIcon />
                              </Index.IconButton>
                            ) : (
                              ""
                            )}
                          </Index.Stack>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="contest-search-list-2">
                  <Index.Box className="user-search-main">
                    <Index.Box className="user-search-box">
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Search"
                          name="search"
                          value={search}
                          onChange={(e) => {
                            const newValue = e.target.value
                              .replace(/^\s+/, "")
                              .replace(/\s\s+/g, " ");
                            setCurrentPage(1);
                            setSearch(newValue);
                          }}
                        />
                        <span className="search-icon-box">
                          <img src={Index.Svg.search} className="search-icon" />
                        </span>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box add-user-input transaction-input-box">
                    <Index.Box className="form-group">
                      <Index.FormControl className="request-dropdown">
                        <Index.Select
                          className="form-control select-drop-list request-drop-list"
                          name="type"
                          value={type}
                          // defaultValue={type ? type : "ALL"}
                          onChange={handleType}
                          displayEmpty
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                        >
                          {typeList &&
                            typeList?.map((val) => (
                              <Index.MenuItem
                                value={val?.value}
                                key={val?.id}
                                className="menuitem"
                              >
                                {val?.value}
                              </Index.MenuItem>
                            ))}
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                {/* </Index.Box> */}
              </Index.Box>
              <Index.Box className="adduser-btn-main btn-main-primary add-money-popup">
                {/* {(rolePermission &&
                    rolePermission?.roleType?.rolePermission?.includes(
                      "CryptoList_add"
                    )) ||
                   (rolePermission && rolePermission?.isAdmin === true) ? ( */}
                <>
                  <Index.Button
                    className="adduser-btn btn-primary"
                    onClick={handleOpenAddMoney}
                  >
                    <img
                      src={Index.Svg.plus}
                      className="plus-icon"
                      alt="plus icon"
                    />
                    Add Money
                  </Index.Button>
                </>
                {/* ) : (
                    ""
                   )} */}
              </Index.Box>
            </Index.Box>
            <CommonTable
              columnMap={columnMap}
              columns={columns}
              loader={loader}
              setLoader={setLoader}
              data={transactionList}
              pageCount={pageCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setPageCount={setPageCount}
              sortableColumns={sortableColumns}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handlePageChange={handlePageChange}
              handleOpen={handleOpen}
              className={"crypto-wallet-table-main"}
            />

            <PageIndex.ViewTransactionDetailsModel
              open={open}
              handleClose={handleClose}
              singleTransactionData={singleTransactionData}
            />
            <PageIndex.DepositCurrencyModel
              open={openAddMoney}
              initialValues={initialValues}
              handleSubmit={handleDepositeMoney}
              handleClose={handleCloseAddMoney}
              assestsList={assestsList}
              currencyList={currencyList}
            />
          </Index.Box>
        </Index.Box>
      ) : (
        <PageIndex.Loading />
      )}
    </>
  );
};

export default ViewCryptoWallet;
