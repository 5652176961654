import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as Routess,
} from "react-router-dom";
import { createBrowserHistory } from "history";

//#region user

//#endregion

//#region Admin

// import UserPrivateRoutes from "./AdminPrivateRoutes";

import Home from "../container/pages/admin/home/Home";
import Dashboard from "../component/admin/dashboard/Dashboard";
import EditProfile from "../container/pages/admin/editprofile/EditProfile";
import AddUser from "../container/pages/admin/adduser/AddUser";

import Login from "../container/auth/login/Login";
import Otp from "../container/auth/otp/Otp";
import UserList from "../container/pages/admin/adduser/UserList";

import PrivateRoutes from "./AdminPrivateRoutes";
import ForgotPassword from "../container/auth/forgotPassword/ForgotPassword";
import ResetPassword from "../container/auth/resetPassword/ResetPassword";
import UserPost from "../container/pages/admin/userpost/UserPost";
// import ViewUserPost from "../container/pages/admin/userpost/ViewUserPost";

import UserReels from "../container/pages/admin/UserReels/UserReels";
import UserReelsView from "../container/pages/admin/UserReels/UserReelsView";

import RoleMaster from "../container/pages/admin/roleMaster/RoleMaster";
import RoleMasterAdd from "../container/pages/admin/roleMaster/RoleMasterAdd";
import RoleMasterView from "../container/pages/admin/roleMaster/RoleMasterView";
import UserView from "../container/pages/admin/adduser/UserView";
import AdminMasterList from "../container/pages/admin/adminMaster/AdminMasterList";
import AddSubAdmin from "../container/pages/admin/adminMaster/AddSubAdmin";
import ViewSubAdmin from "../container/pages/admin/adminMaster/ViewSubAdmin";
import EditUser from "../container/pages/admin/adduser/EditUser";
import AboutUs from "../container/pages/admin/cms/AboutUs";
import ContactUs from "../container/pages/admin/cms/ContactUs";
import PrivayPolicy from "../container/pages/admin/cms/PrivacyPolicy";
import TermsCondition from "../container/pages/admin/cms/Terms&Condition";
import ContactUsList from "../container/pages/admin/contactUs/ContactUsList";
import ViewUserPost from "../container/pages/admin/userpost/ViewUserPost";
import UserReportList from "../container/pages/admin/userReport/UserRepoprtList";
import ViewPostReport from "../container/pages/admin/userReport/ViewPostReport";
import ViewCommentReport from "../container/pages/admin/userReport/ViewCommentReport";
import ViewProfileReport from "../container/pages/admin/userReport/ViewProfileReport";
import UsersFeedbackList from "../container/pages/admin/usersFeedbackList/UsersFeedbackList";
import SingleUserFeedbackList from "../container/pages/admin/usersFeedbackList/SingleUserFeedbackList";
import NotificationsList from "../container/pages/admin/notifications/NotificationsList";
import AddNotification from "../container/pages/admin/notifications/AddNotification";

import { AddDiamondTickPrice } from "../container/pages/admin/AddDiamondTickPrice/AddDiamondTickPrice";
import ContestList from "../container/pages/admin/contest/ContestList";
import ContestAnalyticsView from "../container/pages/admin/contest/ContestAnalyticsView";
import ContestAdd from "../container/pages/admin/contest/ContestAdd";
import ReopenContest from "../container/pages/admin/contest/ReopenContest";

import Contestview from "../container/pages/admin/contestWinner/Contestview";
import ContestDetailView from "../container/pages/admin/contest/ContestDetailsView";
import ForgotPrivateRoutes from "./AdminForgot";
import ResetPrivateRoutes from "./AdminResetPrivateRoutes";
import OTPPrivateRoutes from "./AdminOTP";
import CryptoList from "../container/pages/admin/crypto/CryptoList";
import AddPlatformFee from "../container/pages/admin/platformfee/AddPlatformFee";
import WithdrawalAndConversionRequest from "../container/pages/admin/withdrawalAndConversionRequest/WithdrawalAndConversionRequest";
import WalletManagement from "../container/pages/admin/walletManagement/WalletManagement";
import ViewFiatWallet from "../container/pages/admin/walletManagement/ViewFiatWallet";
import ViewCryptoWallet from "../container/pages/admin/walletManagement/ViewCryptoWallet";
import StreakList from "../container/pages/admin/streakManagement/StreakList";
import StreakDetails from "../container/pages/admin/streakManagement/StreakDetails";
import StreakHistory from "../container/pages/admin/streakManagement/StreakHistory";
import LoadingSpinner from "../container/pages/admin/paymentloader/LoadingSpinner";
import SuccessLoader from "../container/pages/admin/paymentloader/SuccessLoader";
import FailedLoader from "../container/pages/admin/paymentloader/FailedLoader";
import TransactionsList from "../container/pages/admin/transactionsList/TransactionsList";
import DiamondTickUserList from "../container/pages/admin/diamondTickUserList/DiamondTickUserList";
import StickersList from "../container/pages/admin/stickers/StickersList";
import HelpCenter from "../container/pages/admin/cms/HelpCenter";
import ContestAddNew from "../container/pages/admin/contest/ContestAddNew";
import ViewSubContest from "../container/pages/admin/contest/ViewSubContest";
import ContestWinnerList from "../container/pages/admin/contestWinner/ContestWinnerList";
import ViewReelReport from "../container/pages/admin/userReport/ViewReelReport";
import PageNotFound from "../component/common/PageNotFound";
import AdminSupport from "../container/pages/admin/cms/AdminSupport";
import TrendingList from "../container/pages/admin/trendingPost/TrendingList";
import TrendingPostDetails from "../container/pages/admin/trendingPost/trendingPostDetails/TrendingPostDetails";
import StreakChat from "../container/pages/admin/streakManagement/StreakChat";
import Users from "../container/pages/admin/adduser/Users";
import ZeroBalanceWalletManagement from "../container/pages/admin/zeroBalanceList/ZeroBalanceManagement";
import ViewZeroBalanceFiatWallet from "../container/pages/admin/zeroBalanceList/ZeroBalanceViewFiatWallet";
import ViewZeroBalanceCryptoWallet from "../container/pages/admin/zeroBalanceList/ZeroBalanceViewCryptoWallet";
import UserDepositeManagement from "../container/pages/admin/DepositCurrencyList/UserDepositeManagement";
import FollowersList from "../container/pages/admin/adduser/UserActivity/FollowersList";
import UserFollowingList from "../container/pages/admin/adduser/UserActivity/FollowingUserList";

//#endregion

const history = createBrowserHistory();

export default function Routes() {
  return (
    <BrowserRouter history={history}>
      <Routess>
        <Route path="*" element={<PageNotFound />} />

        <Route path="/" element={<Login />} />

        <Route element={<ForgotPrivateRoutes />}>
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route element={<ResetPrivateRoutes />}>
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
        <Route element={<OTPPrivateRoutes />}>
          <Route path="/otp" element={<Otp />} />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route path="dashboard" element={<Dashboard />}>
            <Route path="" element={<Home />} />
            <Route path="add-user" element={<AddUser />} />
            <Route path="edit-user" element={<EditUser />} />
            <Route path="user-list" element={<Users />} />
            <Route path="user-view" element={<UserView />} />
            <Route path="user-post" element={<UserPost />} />
            <Route path="user-reels" element={<UserReels />} />
            <Route path="user-reels-view" element={<UserReelsView />} />
            <Route path="role-master" element={<RoleMaster />} />
            <Route path="role-master-add" element={<RoleMasterAdd />} />
            <Route path="role-master-view" element={<RoleMasterView />} />
            <Route path="admin-master-list" element={<AdminMasterList />} />
            <Route path="user-followers-list" element={<FollowersList  />} />
            <Route path="user-followings-list" element={<UserFollowingList  />} />
            <Route path="admin-master-add" element={<AddSubAdmin />} />
            <Route path="admin-master-view" element={<ViewSubAdmin />} />
            <Route path="contact-us-list" element={<ContactUsList />} />
            <Route path="users-feedback-list" element={<UsersFeedbackList />} />
            <Route
              path="single-user-feedback-list"
              element={<SingleUserFeedbackList />}
            />
            <Route path="notifications-list" element={<NotificationsList />} />
            <Route path="view-notification" element={<AddNotification />} />
            <Route path="add-notification" element={<AddNotification />} />
            <Route path="edit-notification" element={<AddNotification />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="user-report" element={<UserReportList />} />
            <Route path="view-post-report" element={<ViewPostReport />} />
            <Route path="view-comment-report" element={<ViewCommentReport />} />
            <Route path="view-profile-report" element={<ViewProfileReport />} />
            <Route path="view-reel-report" element={<ViewReelReport />} />
            <Route
              path="contest-Participants-list"
              element={<ContestWinnerList />}
            />
            <Route path="contest-list" element={<ContestList />} />
            <Route path="sub-contest-list" element={<ViewSubContest />} />
            <Route path="add-contest" element={<ContestAddNew />} />
            <Route
              path="view-contest-analysis"
              element={<ContestAnalyticsView />}
            />

            <Route path="user-post-view" element={<ViewUserPost />} />
            <Route path="edit-profile" element={<EditProfile />} />
            <Route
              path="/dashboard/terms-condition"
              element={<TermsCondition />}
            />
            <Route
              path="/dashboard/privacy-policy"
              element={<PrivayPolicy />}
            />
              <Route
              path="/dashboard/help-center"
              element={<HelpCenter />}
            />
            <Route path="/dashboard/contact-us" element={<ContactUs />} />
            <Route
              path="/dashboard/add-diamond-price"
              element={<AddDiamondTickPrice />}
            />
            <Route
              path="/dashboard/reopen-contest"
              element={<ReopenContest />}
            />

            <Route
              path="/dashboard/view-finished-contest"
              element={<Contestview />}
            />
            <Route
              path="/dashboard/view-contest-details"
              element={<ContestDetailView />}
            />
            <Route path="/dashboard/crypto-list" element={<CryptoList />} />
            <Route
              path="/dashboard/add-platform-fee"
              element={<AddPlatformFee />}
            />
            <Route
              path="/dashboard/withdrawal-conversion-request-list"
              element={<WithdrawalAndConversionRequest />}
            />
            <Route
              path="/dashboard/wallet-management"
              element={<WalletManagement />}
            />
            <Route
              path="/dashboard/zero-wallet-management"
              element={<ZeroBalanceWalletManagement />}
            />
            <Route
              path="/dashboard/view-zero-crypto-wallet"
              element={<ViewZeroBalanceCryptoWallet />}
            />
            <Route
              path="/dashboard/view-zero-fiat-wallet"
              element={<ViewZeroBalanceFiatWallet />}
            />
            <Route
              path="/dashboard/user-deposite-list"
              element={<UserDepositeManagement />}
            />

            <Route
              path="/dashboard/view-user-fiat-wallet"
              element={<ViewFiatWallet />}
            />
            <Route
              path="/dashboard/view-user-crypto-wallet"
              element={<ViewCryptoWallet />}
            />
            <Route path="/dashboard/streak-list" element={<StreakList />} />
            <Route
              path="/dashboard/streak-details"
              element={<StreakDetails />}
            />
            <Route
              path="/dashboard/streak-history"
              element={<StreakHistory />}
            />
              <Route
              path="/dashboard/transactions-list"
              element={<TransactionsList />}
            />
             <Route
              path="/dashboard/diamondtick-user-list"
              element={<DiamondTickUserList />}
            />
             <Route
              path="/dashboard/stickers-list"
              element={<StickersList />}
            />
             <Route
              path="/dashboard/sub-admin-support"
              element={<AdminSupport/>}
            />
             <Route
              path="/dashboard/trending-list"
              element={<TrendingList/>}
            />
             <Route
              path="/dashboard/trending-post-details"
              element={<TrendingPostDetails/>}
            />
             <Route
              path="/dashboard/streak-chat"
              element={<StreakChat/>}
            />
          </Route>
        </Route>

{/* payment pages for user web view */}
        <Route path="/payment-pending/:id" element={<LoadingSpinner />} />
        <Route path="/payment-success" element={<SuccessLoader />} />
        <Route path="/payment-failed" element={<FailedLoader />} />

      </Routess>
    </BrowserRouter>
  );
}
